import React from 'react';
import './content-item.scss';
import Config from '../../../config/config';

import {ReactComponent as PlaySvg} from '../../../css/imgs/icon-play.svg';
import { Link } from 'react-router-dom';

const ContentItem = ({uid,imageUid,header,desc,time,difficulty,tags,onClickHandle,}) => {

  let tagsExploded = [];
  if(tags){
    tagsExploded = tags.split(",");
  }

  return (
    <div className={"content-item"}>
      <div className='content-item-sub'>
        <div className='content-item-image'>
          <div className='content-item-image-container'>
            <img src={Config.Common.ApiBaseUrl+"/f/"+imageUid} alt={"item"} />
          </div>
        </div>
        <div className='content-item-details'>
          <div className='content-item-header'>
            <div className='left'>
              <div className='content-item-difficulty'>{difficulty}</div>
              {}
              {tagsExploded.map((tag,key) => {
                return (
                  <div className='content-item-tag' key={key}>
                    {tag}
                  </div>
                )
              })}
            </div>
            <div className='right'>
              <div className='content-item-time'>{time}</div>
            </div>
          </div>
          <div className='content-item-body'>
            <div className='content-item-title'>{header}</div>
            <div className='content-item-desc'>{desc}</div>
          </div>
          <div className='content-item-footer hlo'>
            <div className='left'> 
              <div className='icon-button' onClick={onClickHandle}>
                <div className='hover-text'>
                  <span>Watch Introduction</span>
                </div>
                <div className='icon'><PlaySvg /></div>
              </div>
            </div>
            <div className='right'>
              <Link className='button' to={"/course/"+uid}>View Course</Link>
              </div>
          </div>
        </div>
      </div>
    </div>
  );

};

export default ContentItem;