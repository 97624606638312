import React,{useEffect, useState} from 'react';
import './pricing.scss';
import { Helmet } from "react-helmet";
import Seo from '../../../data/seo';

import Config from '../../../config/config';
import FAQ from '../faq/faq';
import Button from '../../elements/button/button';
import Modal from '../../common/modal/modal';
import BillingRate from './billing-rate';
import TopUpType from './top-up-type';
import Stripe from '../../common/payment/stripe';
import Paypal from '../../common/payment/paypal';
import Dawipay from '../../common/payment/dawipay';
import AccountStatusEnum from '../../../enums/account-status';
import PlanEnum from '../../../enums/plan';
import PlatformEnum from '../../../enums/platform';
import useUserState from '../../../store/user';
import {clone} from '../../../utils/clone';
import { Link, Redirect } from 'react-router-dom';
import {request,unSubRequest} from '../../../utils/request';
import {toast} from 'react-toastify';
import Loading from '../../common/loading/loading';
import * as NumberUtil from '../../../utils/number';
import { useHistory } from 'react-router-dom';
import useScript from '../../../utils/use-script';

import MythBlenderWhiteLogo from '../../../css/imgs/logo/mb-t-white.png';
import MythBlenderBlueLogo from '../../../css/imgs/logo/mb-t-blue.png';

import {ReactComponent as LoginSvg} from '../../../css/imgs/icon-login.svg';
import {ReactComponent as CreateAccountSvg} from '../../../css/imgs/icon-person-add.svg';

import CarouselHeader from '../../common/carousel/carousel-header';
import Carousel from '../../common/carousel/carousel';

const SlideEnum = {
  Plans: {Key: 1, Path: "plans", Display: "Plans"},
  TopUps: {Key: 2, Path: "top-ups", Display: "Top Ups"},
}

let googleLink = "";
let billingRates = [
  {month:1,save:0},
  {month:12,save:20},
];
let topUpTypes = [
  {id:"text",display:"Text"},
  {id:"image",display:"Image"},
];
let pricingTiers = [
  {
    id: PlanEnum.Basic,
    name:"Free",
    price: 0,
    banner: "Free Forever",
    features:[
      <><span className="bold">24</span> Characters Per World</>,
      <><span className="bold">6</span> Images Per Object</>,
      <><span className="bold">150</span> Monthly AI Text Generation Tokens</>,
      <><span className="bold">4</span> Monthly AI Image Generation Tokens</>,
      <>Public Worlds</>,
    ],
    free:true,
    actionButton: googleLink,
  },
  {
    id: PlanEnum.Lite,
    name:"Lite",
    price: 299,
    features:[
      <><span className="bold">Unlimited</span> Characters Per World</>,
      <><span className="bold">Unlimited</span> Images Per Object</>,
      <><span className="bold">150</span> Monthly AI Text Generation Tokens</>,
      <><span className="bold">4</span> Monthly AI Image Generation Tokens</>,
      <>Private Worlds</>,
    ],
  },
  {
    id:PlanEnum.Premium,
    name:"Premium",
    price: 799,
    banner: "Most Popular",
    features:[
      <>All Lite Features</>,
      <><span className="bold">2000</span> Monthly AI Text Generation Tokens</>,
      <><span className="bold">50</span> Monthly AI Image Generation Tokens</>,
    ]
  },
  {
    id: PlanEnum.Pro,
    name:"Pro",
    price: 1499,
    banner: "Best Value",
    features:[
      <>All Lite Features</>,
      <><span className="bold">5000</span> Monthly AI Text Generation Tokens</>,
      <><span className="bold">120</span> Monthly AI Image Generation Tokens</>,
    ]
  },
];


let baseTextTokens = 3000;
let baseImageTokens = 75;
let addPercentageMultiplier = 1.05;
let pricingTopUps = [
  {id: "price1",price: 499,baseTextTokens:baseTextTokens,baseImageTokens:baseImageTokens,
    multipliers:[],
  },
  {id: "price2",price: 999,baseTextTokens:baseTextTokens,baseImageTokens:baseImageTokens,
    multipliers:[2],
  },
  {id: "price3",price: 1999,baseTextTokens:baseTextTokens,baseImageTokens:baseImageTokens,
    multipliers:[2,2],
  },
  {id: "price4",price: 4999,baseTextTokens:baseTextTokens,baseImageTokens:baseImageTokens,
    multipliers:[2,2,2.5],
    banner: "Most Popular",
  },
  {id: "price5",price: 9999,baseTextTokens:baseTextTokens,baseImageTokens:baseImageTokens,
    multipliers:[2,2,2.5,2],
  },
  {id: "price6",price: 19999,baseTextTokens:baseTextTokens,baseImageTokens:baseImageTokens,
    multipliers:[2,2,2.5,2,2],
    banner: "Best Value",
  },
];

for (let i = 0; i < pricingTopUps.length; i++) {
  const pricingTopUp = pricingTopUps[i];

  let baseTextTokens = pricingTopUp.baseTextTokens;
  let baseImageTokens = pricingTopUp.baseImageTokens;

  let textTokens = pricingTopUp.baseTextTokens;
  let imageTokens = pricingTopUp.baseImageTokens;

  for (let j = 0; j < pricingTopUp.multipliers.length; j++) {
    const multiplier = pricingTopUp.multipliers[j];
    baseTextTokens = (baseTextTokens * multiplier);
    baseImageTokens = (baseImageTokens * multiplier);

    textTokens = (textTokens * multiplier) * addPercentageMultiplier;
    imageTokens = (imageTokens * multiplier) * addPercentageMultiplier;
  }
  baseTextTokens = Math.round(baseTextTokens);
  baseImageTokens = Math.round(baseImageTokens);
  textTokens = Math.round(textTokens);
  imageTokens = Math.round(imageTokens);

  pricingTopUp.baseTextTokens = baseTextTokens;
  pricingTopUp.baseImageTokens = baseImageTokens;
  pricingTopUp.textTokens = textTokens;
  pricingTopUp.imageTokens = imageTokens;
}
// console.log("pricingTopUps",pricingTopUps);

const Pricing = () => {

  const history = useHistory();
  const {user,setUser} = useUserState();

  const [loading,setLoading] = useState(false);

  let newSlide = SlideEnum.Plans.Key;
  let newTopUpType = topUpTypes[0];
  let currrentPathname = window.location.pathname.replace(/\/history/g,"").replace("/","");
  if(currrentPathname !== ""){
    for (let key in SlideEnum) {
      if(currrentPathname.includes(SlideEnum[key].Path)){
        newSlide = SlideEnum[key].Key; 
        if(currrentPathname.includes("image")){newTopUpType = topUpTypes[1];}
        break;
      }
    }
  }
  const [slide,setSlide] = useState(newSlide);
  const [billingRate,setBillingRate] = useState(billingRates[0]);
  const [topUpType,setTopUpType] = useState(newTopUpType);
  const [pricingTier,setPricingTier] = useState(pricingTiers[2]);
  const [pricingTopUpTier,setPricingTopUpTier] = useState(pricingTopUps[3]);
  const [paymentOption,setPaymentOption] = useState(PlatformEnum.Stripe);
  const [stripePriceUid,setStripePriceUid] = useState();
  const [paypalPriceUid,setPaypalPriceUid] = useState();
  const [dawipayPriceUid,setDawipayPriceUid] = useState();
  const [newAccountStatus,setNewAccountStat] = useState();
  const [stripeTopUpPriceUid,setStripeTopUpPriceUid] = useState();
  const [paypalTopUpPriceUid,setPaypalTopUpPriceUid] = useState();
  const [dawipayTopUpPriceUid,setDawipayTopUpPriceUid] = useState();

  const [show,setShow] = useState(false);
  const [showTopUpModal,setShowTopUpModal] = useState(false);
  const [showOTP,setShowOTP] = useState(false);
  const [redirect,setRedirect] = useState(false);

  const [updateStatus,setUpdateStatus] = useState(false);
  const [newStatus,setNewStatus] = useState();

  const [accountResults,setAccountResults] = useState({});
  const [hasActiveSub,setHasActiveSub] = useState(false);
  const [initMountSub,setInitMountSub] = useState(false);

  let confettiLink = "https://cdn.jsdelivr.net/npm/canvas-confetti@1.5.1/dist/confetti.browser.min.js";
  const [loaded,error] = useScript(confettiLink); 


  useEffect(() => {
    refreshSubStatus();
    return () => {
      unSubRequest("subscription-status");
    }
  },[]);

  const updateUrl = (headerPath) => {
    let newPath = headerPath;
    window.history.pushState(null, headerPath, newPath)
  }

  useEffect(() => {
    history.listen((location) => {
      let newSlide = SlideEnum.Plans.Key;
      let newTopUpType = topUpTypes[0];
      let currrentPathname = location.pathname.replace(/\/ai-story-generator-pricing /g,"").replace("/","");
      if(currrentPathname !== ""){
        for (let key in SlideEnum) {
          if(currrentPathname.includes(SlideEnum[key].Path)){
            newSlide = SlideEnum[key].Key; 
            if(currrentPathname.includes("image")){ newTopUpType = topUpTypes[1]; }
            break;
          }
        }
      }
      setTopUpType(newTopUpType);
      setSlide(newSlide);
    });
  },[history,slide,])


  useEffect(() => {
    if(updateStatus && newStatus !== undefined){
      let cloneUser = clone(user);
      cloneUser.status = newStatus;    
      setUser(cloneUser);
      setUpdateStatus(false);
    }
  },[user,setUser,updateStatus,newStatus])

  useEffect(() => {
    let newStripeUid = Config.PriceUids.Stripe.Premium.Month1;
    let newPaypalUid = Config.PriceUids.Paypal.Premium.Month1;
    let newDawipayUid = Config.PriceUids.Dawipay.Premium.Month1;
    let newerAccountStatus = AccountStatusEnum.Premium;

    if(billingRate.month === 1){
      if(pricingTier.id === "premium"){
        // Default
      }else if(pricingTier.id === "pro"){
        newStripeUid = Config.PriceUids.Stripe.Pro.Month1;
        newPaypalUid = Config.PriceUids.Paypal.Pro.Month1;
        newDawipayUid = Config.PriceUids.Dawipay.Pro.Month1;
        newerAccountStatus = AccountStatusEnum.Pro;
      }else if(pricingTier.id === "lite"){
        newStripeUid = Config.PriceUids.Stripe.Lite.Month1;
        newPaypalUid = Config.PriceUids.Paypal.Lite.Month1;
        newDawipayUid = Config.PriceUids.Dawipay.Lite.Month1;
        newerAccountStatus = AccountStatusEnum.Lite;
      }
    }else if(billingRate.month === 12){
      if(pricingTier.id === "premium"){
        newStripeUid = Config.PriceUids.Stripe.Premium.Year1;
        newPaypalUid = Config.PriceUids.Paypal.Premium.Year1;
        newDawipayUid = Config.PriceUids.Dawipay.Premium.Year1;
        newerAccountStatus = AccountStatusEnum.Premium;
      }else if(pricingTier.id === "pro"){
        newStripeUid = Config.PriceUids.Stripe.Pro.Year1;
        newPaypalUid = Config.PriceUids.Paypal.Pro.Year1;
        newDawipayUid = Config.PriceUids.Dawipay.Pro.Year1;
        newerAccountStatus = AccountStatusEnum.Pro;
      }else if(pricingTier.id === "lite"){
        newStripeUid = Config.PriceUids.Stripe.Lite.Year1;
        newPaypalUid = Config.PriceUids.Paypal.Lite.Year1;
        newDawipayUid = Config.PriceUids.Dawipay.Lite.Year1;
        newerAccountStatus = AccountStatusEnum.Lite;
      }
    }

    // console.log("billingRate",billingRate);
    // console.log("pricingTier",pricingTier);

    // console.log("newStripeUid",newStripeUid);
    // console.log("newPaypalUid",newPaypalUid);
    // console.log("newDawipayUid",newDawipayUid);
    // console.log("newerAccountStatus",newerAccountStatus);

    setStripePriceUid(newStripeUid);
    setPaypalPriceUid(newPaypalUid);
    setDawipayPriceUid(newDawipayUid);
    setNewAccountStat(newerAccountStatus);
  },[billingRate,pricingTier]);

  useEffect(() => {
    let newTopUpStripeUid = Config.PriceUids.Stripe.TopUps.Price1;
    let newTopUpPaypalUid = Config.PriceUids.Paypal.TopUps.Price1;
    let newTopUpDawipayUid = Config.PriceUids.Dawipay.TopUps.Price1;

    if(pricingTopUpTier.id === "price1"){
      // Default
    }else if(pricingTopUpTier.id === "price2"){
      newTopUpStripeUid = Config.PriceUids.Stripe.TopUps.Price2;
      newTopUpPaypalUid = Config.PriceUids.Paypal.TopUps.Price2;
      newTopUpDawipayUid = Config.PriceUids.Dawipay.TopUps.Price2;
    }else if(pricingTopUpTier.id === "price3"){
      newTopUpStripeUid = Config.PriceUids.Stripe.TopUps.Price3;
      newTopUpPaypalUid = Config.PriceUids.Paypal.TopUps.Price3;
      newTopUpDawipayUid = Config.PriceUids.Dawipay.TopUps.Price3;
    }else if(pricingTopUpTier.id === "price4"){
      newTopUpStripeUid = Config.PriceUids.Stripe.TopUps.Price4;
      newTopUpPaypalUid = Config.PriceUids.Paypal.TopUps.Price4;
      newTopUpDawipayUid = Config.PriceUids.Dawipay.TopUps.Price4;
    }else if(pricingTopUpTier.id === "price5"){
      newTopUpStripeUid = Config.PriceUids.Stripe.TopUps.Price5;
      newTopUpPaypalUid = Config.PriceUids.Paypal.TopUps.Price5;
      newTopUpDawipayUid = Config.PriceUids.Dawipay.TopUps.Price5;
    }else if(pricingTopUpTier.id === "price6"){
      newTopUpStripeUid = Config.PriceUids.Stripe.TopUps.Price6;
      newTopUpPaypalUid = Config.PriceUids.Paypal.TopUps.Price6;
      newTopUpDawipayUid = Config.PriceUids.Dawipay.TopUps.Price6;
    }

    // console.log("pricingTier",pricingTier);
    // console.log("newStripeUid",newStripeUid);
    // console.log("newPaypalUid",newPaypalUid);
    // console.log("newDawipayUid",newDawipayUid);

    setStripeTopUpPriceUid(newTopUpStripeUid);
    setPaypalTopUpPriceUid(newTopUpPaypalUid);
    setDawipayTopUpPriceUid(newTopUpDawipayUid);
  },[pricingTopUpTier]);

  const formatUSD = (amount) => {
    amount = amount/100;
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(amount);
  }

  let monthlyCost = pricingTier.price;
  let totalCost = monthlyCost * billingRate.month;
  if(billingRate.save){
    monthlyCost = monthlyCost * (1 - (billingRate.save/100))
    totalCost = totalCost * (1 - (billingRate.save/100))
  } 

  let monthlyCostStr = formatUSD(monthlyCost);
  let totalCostStr = formatUSD(totalCost);

  const successCallback = () => {
    let cloneUser = clone(user);
    cloneUser.status = newAccountStatus;
    setUser(cloneUser);
    setRedirect("/subscribed/"+newAccountStatus);
  }

  const successTokenCallback = () => {
    // toast.info("successTokenCallback");
    setShowTopUpModal(false);
    setShowOTP(true);
    if(loaded){
      triggerConfetti();
    }
  }

  const triggerConfetti = () => {
    window.confetti({
      particleCount: 200,
      spread: 85,
      origin: {y: .6},
    })
  };

  const refreshSubStatus = () => {
    if(!user){ return;}
    setLoading(true);
    request("subscription-status","/subscription-status?force","GET", {}, {
      then: function(res){
        let results = {};
        let newStatus = -1;
        let newUpdateStatus = false;
        let newSubAutoRenew = false;
        if(res.data.res){
          results = res.data.res;
          if(results.autoRenew){
            newSubAutoRenew = true;
          }
        }
        if(results.user && results.user.status){
          newStatus = results.user.status;
          newUpdateStatus = true;
        }
        setAccountResults(results);
        setHasActiveSub(results.hasActiveSub?true:false);
        setUpdateStatus(newUpdateStatus);
        setNewStatus(newStatus);
      },
      catch: function(err){ toast.error(err.message);},
      finally: function(){setLoading(false);}
    });
  };


  useEffect(() => {
    let newInitMountSub = initMountSub;
    let newPricingTier = pricingTier;
    if(hasActiveSub && initMountSub === false){
      newInitMountSub = true;
      // newPricingTier
      let currentPlan = PlanEnum.Basic;
      if(hasActiveSub && accountResults.plan){
        for(let key in PlanEnum){
          if(PlanEnum[key] === accountResults.plan){ currentPlan = PlanEnum[key]; break; }
        }
      }
      for (let i = 0; i < pricingTiers.length; i++) {
        const item = pricingTiers[i];
        if(item.id === currentPlan){
          newPricingTier = item;
          break;
        }
      }
    }
    setPricingTier(newPricingTier);
    setInitMountSub(newInitMountSub);
  },[hasActiveSub,initMountSub,pricingTier]);

  let currentPlan = PlanEnum.Basic;
  if(hasActiveSub && accountResults.plan){
    for(let key in PlanEnum){
      if(PlanEnum[key] === accountResults.plan){ currentPlan = PlanEnum[key]; break; }
    }
  }

  if(redirect){
    return(<Redirect to={redirect}/>)
  }

  return (
    <div className="pricing-comp">
      <Helmet>
        <title>{Seo.Pricing.Title}</title>
        <meta name="description" content={Seo.Pricing.Desc} />
        <meta name="keywords" content={Seo.Pricing.Keywords} />
      </Helmet>

      <h1>Pricing</h1>

      <CarouselHeader slideTo={slide}>
        <div onClick={() => {updateUrl("/ai-story-generator-pricing/"+SlideEnum.Plans.Path);setSlide(1)}}>Plans</div>
        <div onClick={() => {updateUrl("/ai-story-generator-pricing/"+SlideEnum.TopUps.Path+"/"+topUpType.id);setSlide(2)}}>Token Top Ups</div>
      </CarouselHeader>
      <Carousel slideTo={slide}>
        <div className='plan-comp'>
          <BillingRate billingRate={billingRate} setBillingRate={setBillingRate} billingRates={billingRates}/>

          <div className='pricing-options hlo'>
            {pricingTiers.map((v,i) => {
              let activeClass = v.id === pricingTier.id?"active":"";
              let currentPlanClass = v.id === currentPlan?"current-plan":""

              let price = v.price;
              if(billingRate.save !== 0){
                price = price * (1 - (billingRate.save/100))
              }
              let dollar = Math.floor(price / 100);
              let cent = Math.round(price % 100);
              let centString = cent.toString().padStart(2, '0');

              return(
                <div className={"pricing-option "+activeClass+" "+currentPlanClass} key={i} onClick={() => {
                  if(loading){toast.info("Loading Sub info, one moment please.");return;}
                  if(v.id === pricingTier.id && !v.free){setShow(true);} 
                  setPricingTier(v);
                }}>
                  {v.banner?
                    <div className='banner'><span>{v.banner}</span></div>
                  :null}
                  <div className='title'>{v.name}</div>
                  <div className='features'>
                    {v.features.map((v2,i2) => {
                      return(
                        <div className='feature' key={i2}>{v2}</div>
                      )
                    })}
                  </div>

                  <div className='price'>
                    <div className='price-container'>
                      <div className='symbol'>$</div>
                      <div className='dollar'>{dollar}</div>
                      <div className='cents'>{centString}</div>
                    </div>
                    <div className='per-month'>per month</div>
                  </div>
                  <div className='buttons'>
                    <Button className="" status={loading?"loading":"forward"} onClick={() => {
                      setPricingTier(v);
                      setShow(v.free?false:true);
                    }}>{v.free?"Get Started":"Upgrade"}</Button>
                  </div>
                  {(v.id === currentPlan)?
                    <div className='current-plan-div'>
                      <div className='current-plan-div-inner'></div>
                      <div className='text'>
                        {loading?
                        <Loading show={loading} size={24} style={"white"} />
                        :"Current Plan"}
                      </div>
                      
                    </div>
                  :null}
                </div>
              )
            })}
          </div>
        </div>
        <div className='pricing-top-up-slide'>
          <TopUpType topUpType={topUpType} setTopUpType={(newVal) => {
            updateUrl("/pricing/"+SlideEnum.TopUps.Path+"/"+newVal.id);
            setTopUpType(newVal)
          }} topUpTypes={topUpTypes}/>
          <div className='pricing-top-up-options hlo'>

            {pricingTopUps.map((v,i) => {
              let activeClass = v.id === pricingTopUpTier.id?"active":"";
              let currentPlanClass = v.id === currentPlan?"current-plan":""

              let price = v.price;
              let dollar = Math.floor(price / 100);
              let cent = Math.round(price % 100);
              let centString = cent.toString().padStart(2, '0');

              let baseTokens = topUpType.id === topUpTypes[0].id?v.baseTextTokens:v.baseImageTokens
              let tokens = topUpType.id === topUpTypes[0].id?v.textTokens:v.imageTokens;

              let pricePerToken = price/tokens;
              pricePerToken = Math.round(pricePerToken*100);
              pricePerToken = pricePerToken/10000;

              return(
                <div className={"pricing-top-up-option "+activeClass+" "+currentPlanClass} key={i} onClick={() => {
                  if(loading){toast.info("Loading Sub info, one moment please.");return;}
                  if(v.id === pricingTopUpTier.id && !v.free){setShowTopUpModal(true);} 
                  setPricingTopUpTier(v);
                }}>
                  {v.banner?
                    <div className='banner'><span>{v.banner}</span></div>
                  :null}
                  <div className={'title slash '+((baseTokens === tokens)?"hide-opacity":"")}>{NumberUtil.numberWithCommas(baseTokens)}</div>
                  <div className='title'>{NumberUtil.numberWithCommas(tokens)}</div>
                  <div className='tokens'>
                    {topUpType.id === topUpTypes[0].id
                    ?"AI Text Tokens"
                    :"AI Image Tokens"}
                  </div>

                  <div className='price'>
                    <div className='price-container'>
                      <div className='symbol'>$</div>
                      <div className='dollar'>{dollar}</div>
                      <div className='cents'>{centString}</div>
                    </div>
                    <span className="cost-per-token"><span>{pricePerToken}</span> Per Token</span>
                  </div>
                </div>
              )
            })}
            <Button parentClassName={"top-up-bottom-button"} status={"forward"}
              onClick={() =>{
                setShowTopUpModal(true);
              }}>
              Continue
            </Button>
          </div>
        </div>
      </Carousel>
      
      <div className="faq">
        <FAQ />
      </div>

      <Modal show={show} handleClose={() => {setShow(false)}} className="checkout-modal">
        <div className='checkout-modal-inner'>
          <div className='checkout-modal-header'>
            <h2>Your Plan</h2>
            <div className='checkout-modal-billing-rate-container'>
              <BillingRate billingRate={billingRate} setBillingRate={setBillingRate} billingRates={billingRates}/>
            </div>
          </div>
          <div className='items'>
            <div className='item'>
              <div className='left'>
                <div className='icon'>
                  <img src={MythBlenderWhiteLogo} />
                </div>
                <div className='item-text'>
                  <div className='product-name'><span className='logo-name'>{Config.Common.AppName}</span> {pricingTier.name}</div>
                  <div className='billing-rate-text'>{billingRate.month === 1?"Billed Monthly":"Billed Every "+billingRate.month+" Months"}</div>
                  <div className='easy-cancel'>Easily cancel at anytime</div>
                </div>
              </div>
              <div className='right'>
                {monthlyCostStr}/month
              </div>
            </div>
            <div className='total'>
              <div className='left'>Total</div>
              <div className='right'>{totalCostStr} USD</div>
            </div>
          </div>
          {(user)?currentPlan !== PlanEnum.Basic?
          <>
            <div className='change-plan'>
              <h2>Already Subscribed to a Plan</h2>
              <p>We're working on implementing a plan switch feature.</p>
              <p>If you'd like to switch your plan, please wait for it to expire or open a ticket via our help center. </p>
            </div>
          </>
          :
          <>
            <div>
              <h4>Payment Method</h4>
            </div>
            <div className='payment-options hlo'>
              <div className={"payment-option "+((paymentOption === PlatformEnum.Stripe?"active":""))} onClick={() => {setPaymentOption(PlatformEnum.Stripe)}}>Credit Card</div>
              <div className={"payment-option "+((paymentOption === PlatformEnum.Paypal?"active":""))} onClick={() => {setPaymentOption(PlatformEnum.Paypal)}}>PayPal</div>
              {/* <div className={"payment-option "+((paymentOption === PlatformEnum.Dawipay?"active":""))} onClick={() => {setPaymentOption(PlatformEnum.Dawipay)}}>Crypto</div> */}
            </div>
            <div className='payment-div'>
              <div className={(paymentOption === PlatformEnum.Stripe)?"":"hide"}>
                <Stripe newAccountStatus={newAccountStatus} priceUid={stripePriceUid} 
                  callback={() => {
                    successCallback();
                  }}/>
              </div>
              <div className={(paymentOption === PlatformEnum.Paypal)?"":"hide"}>
              {paymentOption === PlatformEnum.Paypal && slide === SlideEnum.Plans.Key?
                <Paypal newAccountStatus={newAccountStatus} priceUid={paypalPriceUid} 
                  callback={() => {
                    successCallback();
                  }}/>
              :null}
              </div>
              <div className={(paymentOption === PlatformEnum.Dawipay)?"":"hide"}>
                <Dawipay newAccountStatus={newAccountStatus} priceUid={dawipayPriceUid} 
                  callback={() => {
                    successCallback();
                  }}/>
              </div>
            </div>
          </>:
          <>
            <div className='sign-in-div'>
              <div className='header-text'>Create an account or Sign in to continue</div>
              <div className='buttons'>
                <Link to="create-account" className='button'>
                  <div className='icon'><CreateAccountSvg/></div>
                  <div className='text'>Create Account</div>
                </Link>
                <Link to="login" className='button'>
                  <div className='icon'><LoginSvg/></div>
                  <div className='text'>Login</div>
                </Link>
              </div>
            </div>
          </>}
        </div>
      </Modal>

      <Modal show={showTopUpModal} handleClose={() => {setShowTopUpModal(false)}} className="checkout-top-up-modal">
        <div className='checkout-top-up-modal-inner'>
          <div className='checkout-top-up-modal-header'>
            <h2>Top Up Checkout</h2>
            <div className='checkout-top-up-modal-billing-rate-container'>
              <TopUpType topUpType={topUpType} setTopUpType={(newVal) => {
                  updateUrl("/pricing/"+SlideEnum.TopUps.Path+"/"+newVal.id);
                  setTopUpType(newVal)
                }} topUpTypes={topUpTypes}/>
            </div>
          </div>
          <div className='items'>
            <div className='item'>
              <div className='left'>
                <div className='icon'>
                  <img src={MythBlenderWhiteLogo} />
                </div>
                <div className='item-text'>
                  <div className='product-name'><span className='logo-name'>{Config.Common.AppName}</span> Tokens</div>
                  <div className='billing-rate-text'>
                    {topUpType.id === topUpTypes[0].id?
                    NumberUtil.numberWithCommas(pricingTopUpTier.textTokens)+" Text Tokens":
                    NumberUtil.numberWithCommas(pricingTopUpTier.imageTokens)+" Image Tokens"}
                  </div>
                </div>
              </div>
              <div className='right'>
              {formatUSD(pricingTopUpTier.price)} USD
              </div>
            </div>
            <div className='total'>
              <div className='left'>Total</div>
              <div className='right'>{formatUSD(pricingTopUpTier.price)} USD</div>
            </div>
          </div>
          {(user)?
          <>
            <div>
              <h4>Payment Method</h4>
            </div>
            <div className='payment-options hlo'>
              <div className={"payment-option "+((paymentOption === PlatformEnum.Stripe?"active":""))} onClick={() => {setPaymentOption(PlatformEnum.Stripe)}}>Credit Card</div>
              <div className={"payment-option "+((paymentOption === PlatformEnum.Paypal?"active":""))} onClick={() => {setPaymentOption(PlatformEnum.Paypal)}}>PayPal</div>
              {/* <div className={"payment-option "+((paymentOption === PlatformEnum.Dawipay?"active":""))} onClick={() => {setPaymentOption(PlatformEnum.Dawipay)}}>Crypto</div> */}
            </div>
            <div className='payment-div'>
              <div className={(paymentOption === PlatformEnum.Stripe)?"":"hide"}>
                <Stripe oneTimePayment={true} priceUid={stripeTopUpPriceUid} 
                  oneTimePaymentType={topUpType.id}
                  callback={() => {
                    successTokenCallback();
                  }}/>
              </div>
              <div className={(paymentOption === PlatformEnum.Paypal)?"":"hide"}>
                {paymentOption === PlatformEnum.Paypal && slide === SlideEnum.TopUps.Key?
                  <Paypal oneTimePayment={true} price={pricingTopUpTier.price} 
                    oneTimePaymentType={topUpType.id}
                    callback={() => {
                      successTokenCallback();
                    }}/>
                :null}
              </div>
              <div className={(paymentOption === PlatformEnum.Dawipay)?"":"hide"}>
                <Dawipay oneTimePayment={true} priceUid={dawipayTopUpPriceUid} 
                  oneTimePaymentType={topUpType.id}
                  callback={() => {
                    successTokenCallback();
                  }}/>
              </div>
            </div>
          </>:
          <>
            <div className='sign-in-div'>
              <div className='header-text'>Create an account or Sign in to continue</div>
              <div className='buttons'>
                <Link to="create-account" className='button'>
                  <div className='icon'><CreateAccountSvg/></div>
                  <div className='text'>Create Account</div>
                </Link>
                <Link to="login" className='button'>
                  <div className='icon'><LoginSvg/></div>
                  <div className='text'>Login</div>
                </Link>
              </div>
            </div>
          </>}
        </div>
      </Modal>


      <Modal className={"one-time-payment-modal"} show={showOTP} handleClose={() => {setShowOTP(false)}}>
        <div>
          <h2>Payment Received</h2>
          <p>Thank you so much for your payment!</p>
          <p>
            {NumberUtil.numberWithCommas((topUpType.id === topUpTypes[0].id?pricingTopUpTier.textTokens:pricingTopUpTier.imageTokens))} {topUpType.display} Tokens
            have been added to your account!
          </p>
        </div>
      </Modal>

    </div>
  );

};

export default Pricing;