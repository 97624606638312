import React,{useState,useEffect} from 'react';
import './world-builder-right.scss';
import Table from '../../elements/table/table';
import Config from '../../../config/config';
import Carousel from '../../common/carousel/carousel';
import {worldOptions,advancedWorldOptions} from '../world-builder/world-options';
import { clone } from '../../../utils/clone';
import * as StringUtil from '../../../utils/string';
import {ReactComponent as ImageSvg} from '../../../css/imgs/icon-image.svg';
import {ReactComponent as PersonSvg} from '../../../css/imgs/icon-person.svg';
import Button from '../../elements/button/button';
import Textarea from 'react-textarea-autosize';
import { toast } from 'react-toastify';
import { request,unSubRequest } from '../../../utils/request';

let tableLimit = 100;
let tables = {
  characters:"characters",cultures:"cultures",conflicts:"conflicts",species:"species",
  locations:"locations",religions:"religions",religionGods:"religionGods",factions:"factions",
}

const WorldSettingsBuilder = ({obj,values,setValues}) => {
  // console.log("obj",obj);
  // console.log("values",values);
  return(
    <>
    {Object.keys(obj).map((key, i) => {
      let item = obj[key];
      item.sort();
      return(
        <div className="label-item-input" key={i}>
          <div className='input-box'>
            <label>{StringUtil.prettifyCamelCase(key)}</label>
            <div className='spacer'></div>
            <div className='inputs'>
              <select 
                value={values && values[key]?values[key]:""} 
                onChange={(e) => {
                  let newValues = clone(values);
                  newValues[key] = e.target.value;
                  setValues(newValues);
                }}
              >
                <option value="unspecified">Unspecified</option>
                <option disabled>---</option>
                {item.map((v2,i2) => {
                  return(
                    <option key={i2}>{v2}</option>
                  )
                })}
                <option disabled>---</option>
                <option value="custom">Custom</option>
              </select>
              {(values && values[key] && values[key] === "custom"?
                <div className='custom-input-box'>
                  <input className='custom-input' type="text" 
                    placeholder={"Custom "+StringUtil.prettifyCamelCase(key)+" Placeholder"}
                    value={values && values[key+"-custom"]?values[key+"-custom"]:""} 
                    onChange={(e) => {
                      let newValues = clone(values);
                      newValues[key+"-custom"] = e.target.value;
                      setValues(newValues);
                    }}/>
                </div>
              :null)}
            </div>
          </div>
        </div>
      )
    })}
    </>
  )
}

const WorldBuilderRight = ({uid,world,slide,values,setValues,newItemModalCallback,refreshTableInc,refreshTableName}) => {

  const [loading,setLoading] = useState(false);
  const [localValues,setLocalValues] = useState({});
  const [refreshIncObj,setRefreshIncObj] = useState({});

  useEffect(() => {
    let worldSettings = world.worldSettings ?? {};
    for(let key in worldSettings){
      let item = worldSettings[key];
      let index = null;
      if(key in worldOptions){
        index = worldOptions[key].indexOf(item);
      }else if(key in advancedWorldOptions){
        index = advancedWorldOptions[key].indexOf(item);
      }
      if(index === -1 && item !== "unspecified"){
        worldSettings[key+"-custom"] = clone(item);
        worldSettings[key] = "custom";
      }
    }
    setLocalValues(worldSettings);
  },[world]);

  useEffect(() => {
    let newRefreshIncObj = refreshIncObj;
    let tableInc = refreshTableName in refreshIncObj?refreshIncObj[refreshTableName]:0;
    if(refreshTableName in tables && tableInc !== refreshTableInc){
      newRefreshIncObj = clone(refreshIncObj);
      newRefreshIncObj[refreshTableName] = refreshTableInc
    }
    setRefreshIncObj(newRefreshIncObj);
  },[refreshIncObj,refreshTableInc,refreshTableName]);


  const charSideItems = [{display: "All", conditions:[{column:"worldUid", table:"characters", action: "=",value: uid,}]},];
  const charColumns = [
    {table: "characters",value: "uid", hide: true, },
    {table: "characters",value: "name", display:"Characters", index:true, defaultOrder:true, defaultOrderDir:"ASC",
      custom: ({v,cv}) => {
        let activeClass = tables.characters in values && v['charactersUid'] === values[tables.characters]['charactersUid']?"active":"";
        let imageUrl = v['charactersImageUrl'];
        if(imageUrl && imageUrl.includes("{{cdn}}")){
          imageUrl = imageUrl.replace("{{cdn}}",Config.Common.CdnUrl);
        }
        if(imageUrl && imageUrl.includes(".png")){
          imageUrl = imageUrl.replace(".png","");
        }
        return(
          <div className={"row-item "+activeClass}>
            <div className='icon'>
              {imageUrl === ""?<PersonSvg />:
                <img alt={"characters image "}
                  src={(imageUrl+"-small.png")} 
                  // srcSet={imageUrl+"-medium.png 800w, "+imageUrl+".png 1200w"} 
                  />
              }
            </div>
            <div className='text'>{v[cv]}</div>
          </div>
        )
      }, 
    },
    {table: "characters",value: "appearance",hide: true, },
    {table: "characters",value: "archetype",hide: true, },
    {table: "characters",value: "traits",hide: true, },
    {table: "characters",value: "backstory",hide: true, },
    {table: "characters",value: "imageUrl",hide: true, },
    {table: "characters",value: "lastPrompt",hide: true, },
    {table: "characters",value: "createdDate", hide:true,  type:"date", },
  ];
  const culturesSideItems = [{display: "All", conditions:[{column:"worldUid", table:"cultures", action: "=",value: uid,}]},];
  const culturesColumns = [
    {table: "cultures",value: "uid", hide: true, },
    {table: "cultures",value: "name", display:"Cultures", index:true, defaultOrder:true, defaultOrderDir:"ASC",
      custom: ({v,cv}) => {
        let activeClass = tables.cultures in values && v['culturesUid'] === values[tables.cultures]['culturesUid']?"active":"";
        let imageUrl = v['culturesImageUrl'];
        if(imageUrl && imageUrl.includes("{{cdn}}")){
          imageUrl = imageUrl.replace("{{cdn}}",Config.Common.CdnUrl);
        }
        if(imageUrl && imageUrl.includes(".png")){
          imageUrl = imageUrl.replace(".png","");
        }
        return(
          <div className={"row-item "+activeClass}>
            <div className='icon'>
              {imageUrl === ""?<ImageSvg />:
                <img alt={"cultures image "}
                  src={(imageUrl+"-small.png")} 
                  // srcSet={imageUrl+"-medium.png 800w, "+imageUrl+".png 1200w"} 
                  />
              }
            </div>
            <div className='text'>{v[cv]}</div>
          </div>
        )
      }, 
    },
    {table: "cultures",value: "beliefs",hide: true, },
    {table: "cultures",value: "traditions",hide: true, },
    {table: "cultures",value: "socialNorms",hide: true, },
    {table: "cultures",value: "backstory",hide: true, },
    {table: "cultures",value: "imageUrl",hide: true, },
    {table: "cultures",value: "lastPrompt",hide: true, },
    {table: "cultures",value: "createdDate", hide:true,  type:"date", },
  ];
  const speciesSideItems = [{display: "All", conditions:[{column:"worldUid", table:"species", action: "=",value: uid,}]},];
  const speciesColumns = [
    {table: "species",value: "uid", hide: true, },
    {table: "species",value: "name", display:"Species", index:true, defaultOrder:true, defaultOrderDir:"ASC",
      custom: ({v,cv}) => {
        let activeClass = tables.species in values && v['speciesUid'] === values[tables.species]['speciesUid']?"active":"";
        let imageUrl = v['speciesImageUrl'];
        if(imageUrl && imageUrl.includes("{{cdn}}")){
          imageUrl = imageUrl.replace("{{cdn}}",Config.Common.CdnUrl);
        }
        if(imageUrl && imageUrl.includes(".png")){
          imageUrl = imageUrl.replace(".png","");
        }
        return(
          <div className={"row-item "+activeClass}>
            <div className='icon'>
              {imageUrl === ""?<ImageSvg />:
                <img alt={"species image "}
                  src={(imageUrl+"-small.png")} 
                  // srcSet={imageUrl+"-medium.png 800w, "+imageUrl+".png 1200w"} 
                  />
              }
            </div>
            <div className='text'>{v[cv]}</div>
          </div>
        )
      }, 
    },
    {table: "species",value: "appearance",hide: true, },
    {table: "species",value: "lifespan",hide: true, },
    {table: "species",value: "habitat",hide: true, },
    {table: "species",value: "backstory",hide: true, },
    {table: "species",value: "imageUrl",hide: true, },
    {table: "species",value: "lastPrompt",hide: true, },
    {table: "species",value: "createdDate", hide:true,  type:"date", },
  ];
  const conflictsSideItems = [{display: "All", conditions:[{column:"worldUid", table:"conflicts", action: "=",value: uid,}]},];
  const conflictsColumns = [
    {table: "conflicts",value: "uid", hide: true, },
    {table: "conflicts",value: "name", display:"Conflict", index:true, defaultOrder:true, defaultOrderDir:"ASC", 
      custom: ({v,cv}) => {
        let activeClass = tables.conflicts in values && v['conflictsUid'] === values[tables.conflicts]['conflictsUid']?"active":"";
        let imageUrl = v['conflictsImageUrl'];
        if(imageUrl && imageUrl.includes("{{cdn}}")){
          imageUrl = imageUrl.replace("{{cdn}}",Config.Common.CdnUrl);
        }
        if(imageUrl && imageUrl.includes(".png")){
          imageUrl = imageUrl.replace(".png","");
        }
        return(
          <div className={"row-item "+activeClass}>
            <div className='icon'>
              {imageUrl === ""?<ImageSvg />:
                <img alt={"conflicts image "}
                  src={(imageUrl+"-small.png")} 
                  // srcSet={imageUrl+"-medium.png 800w, "+imageUrl+".png 1200w"} 
                  />
              }
            </div>
            <div className='text'>{v[cv]}</div>
          </div>
        )
      }, 
    },
    {table: "conflicts",value: "description",hide: true, },
    {table: "conflicts",value: "imageUrl",hide: true, },
    {table: "conflicts",value: "lastPrompt",hide: true, },
    {table: "conflicts",value: "createdDate", hide:true,  type:"date", },
  ];
  const locationsSideItems = [{display: "All", conditions:[{column:"worldUid", table:"locations", action: "=",value: uid,}]},];
  const locationsColumns = [
    {table: "locations",value: "uid", hide: true, },
    {table: "locations",value: "name", display:"Locations", index:true, defaultOrder:true, defaultOrderDir:"ASC",
      custom: ({v,cv}) => {
        let activeClass = tables.locations in values && v['locationsUid'] === values[tables.locations]['locationsUid']?"active":"";
        let imageUrl = v['locationsImageUrl'];
        if(imageUrl && imageUrl.includes("{{cdn}}")){
          imageUrl = imageUrl.replace("{{cdn}}",Config.Common.CdnUrl);
        }
        if(imageUrl && imageUrl.includes(".png")){
          imageUrl = imageUrl.replace(".png","");
        }
        return(
          <div className={"row-item "+activeClass}>
            <div className='icon'>
              {imageUrl === ""?<ImageSvg />:
              <img alt={"locations image "}
                src={(imageUrl+"-small.png")} 
                // srcSet={imageUrl+"-medium.png 800w, "+imageUrl+".png 1200w"} 
                />
              }
            </div>
            <div className='text'>{v[cv]}</div>
          </div>
        )
      }, 
    },
    {table: "locations",value: "features",hide: true, },
    {table: "locations",value: "plotInfluence",hide: true, },
    {table: "locations",value: "imageUrl",hide: true, },
    {table: "locations",value: "lastPrompt",hide: true, },
    {table: "locations",value: "createdDate", hide:true,  type:"date", },
  ];
  const religionsSideItems = [{display: "All", conditions:[{column:"worldUid", table:"religions", action: "=",value: uid,}]},];
  const religionsColumns = [
    {table: "religions",value: "uid", hide: true, },
    {table: "religions",value: "name", display:"Religions", index:true, defaultOrder:true, defaultOrderDir:"ASC",
      custom: ({v,cv}) => {
        let activeClass = tables.religions in values && v['religionsUid'] === values[tables.religions]['religionsUid']?"active":"";
        let imageUrl = v['religionsImageUrl'];
        if(imageUrl && imageUrl.includes("{{cdn}}")){
          imageUrl = imageUrl.replace("{{cdn}}",Config.Common.CdnUrl);
        }
        if(imageUrl && imageUrl.includes(".png")){
          imageUrl = imageUrl.replace(".png","");
        }
        return(
          <div className={"row-item "+activeClass}>
            <div className='icon'>
              {imageUrl === ""?<ImageSvg />:
              <img alt={"religions image "}
                src={(imageUrl+"-small.png")} 
                // srcSet={imageUrl+"-medium.png 800w, "+imageUrl+".png 1200w"} 
                />}
            </div>
            <div className='text'>{v[cv]}</div>
          </div>
        )
      },  
    },
    {table: "religions",value: "practices",hide: true, },
    {table: "religions",value: "influence",hide: true, },
    {table: "religions",value: "imageUrl",hide: true, },
    {table: "religions",value: "lastPrompt",hide: true, },
    {table: "religions",value: "createdDate", hide:true,  type:"date", },
  ];
  const religionGodsSideItems = [{display: "All", conditions:[{column:"worldUid", table:"religionGods", action: "=",value: uid,}]},];
  const religionGodsColumns = [
    {table: "religionGods",value: "uid", hide: true, },
    {table: "religionGods",value: "name", display:"Religion Dieties", index:true, defaultOrder:true, defaultOrderDir:"ASC",
      custom: ({v,cv}) => {
        let activeClass = tables.religionGods in values && v['religionGodsUid'] === values[tables.religionGods]['religionGodsUid']?"active":"";
        let imageUrl = v['religionGodsImageUrl'];
        if(imageUrl && imageUrl.includes("{{cdn}}")){
          imageUrl = imageUrl.replace("{{cdn}}",Config.Common.CdnUrl);
        }
        if(imageUrl && imageUrl.includes(".png")){
          imageUrl = imageUrl.replace(".png","");
        }
        return(
          <div className={"row-item "+activeClass}>
            <div className='icon'>
              {imageUrl === ""?<ImageSvg />:
              <img alt={"religionGods image "}
                src={(imageUrl+"-small.png")} 
                // srcSet={imageUrl+"-medium.png 800w, "+imageUrl+".png 1200w"} 
                />  
              }
            </div>
            <div className='text'>{v[cv]}</div>
          </div>
        )
      },  
    },
    {table: "religionGods",value: "appearance",hide: true, },
    {table: "religionGods",value: "traits",hide: true, },
    {table: "religionGods",value: "backstory",hide: true, },
    {table: "religionGods",value: "imageUrl",hide: true, },
    {table: "religionGods",value: "lastPrompt",hide: true, },
    {table: "religionGods",value: "createdDate", hide:true,  type:"date", },
  ];
  const factionsSideItems = [{display: "All", conditions:[{column:"worldUid", table:"factions", action: "=",value: uid,}]},];
  const factionsColumns = [
    {table: "factions",value: "uid", hide: true, },
    {table: "factions",value: "name", display:"Factions", index:true, defaultOrder:true, defaultOrderDir:"ASC",
      custom: ({v,cv}) => {
        let activeClass = tables.factions in values && v['factionsUid'] === values[tables.factions]['factionsUid']?"active":"";
        let imageUrl = v['factionsImageUrl'];
        if(imageUrl && imageUrl.includes("{{cdn}}")){
          imageUrl = imageUrl.replace("{{cdn}}",Config.Common.CdnUrl);
        }
        if(imageUrl && imageUrl.includes(".png")){
          imageUrl = imageUrl.replace(".png","");
        }
        return(
          <div className={"row-item "+activeClass}>
            <div className='icon'>
              {imageUrl === ""?<ImageSvg />:
              <img alt={"factions image "}
                src={(imageUrl+"-small.png")} 
                // srcSet={imageUrl+"-medium.png 800w, "+imageUrl+".png 1200w"} 
                />  
              }
            </div>
            <div className='text'>{v[cv]}</div>
          </div>
        )
      },  
    },
    {table: "factions",value: "backstory",hide: true, },
    {table: "factions",value: "imageUrl",hide: true, },
    {table: "factions",value: "lastPrompt",hide: true, },
    {table: "factions",value: "createdDate", hide:true,  type:"date", },
  ];
  const joinOptions = [
    // {joinType:"LEFT JOIN",leftTable:"characters",rightTable:"images",leftColumn:"uid",rightColumn:"linkedUid",},
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    let worldUid = world.uid ?? "";
    saveWorldSettings(worldUid,localValues);
  }

  const saveWorldSettings = async (uid,values) => {
    let passValues = clone(values);
    let fixValues = [];
    for(let key in passValues){
      if(key && key.includes("-custom")){fixValues.push(key);}
    }
    for (let i = 0; i < fixValues.length; i++) {
      const fixValue = fixValues[i];
      let newValue = fixValue.replace("-custom","");
      passValues[newValue] = clone(passValues[fixValue]);
      delete passValues[fixValue];
    }
    let body = {uid,
      values: passValues,
    }
    setLoading(true);
    request("save-world-settings","/world/settings","POST", body, {
      then: function(res){toast.success("Saved Wolrd Settings");},
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false);}
    });
  }

  return (
    <div className="world-builder-right-comp">
      <Carousel slideTo={slide} useAutoHeight={false}>
        <div className='slide'><div className='world-settings'>
          <form onSubmit={handleSubmit}>
            <WorldSettingsBuilder obj={worldOptions} values={localValues} setValues={setLocalValues}/>
            <WorldSettingsBuilder obj={advancedWorldOptions} values={localValues} setValues={setLocalValues}/>
            <div className='input-box'>
              <label>Additional Details</label>
              <Textarea value={localValues && localValues.additionalDetails?localValues.additionalDetails:""} onChange={(e) => {
                let newValues = clone(localValues);
                newValues["additionalDetails"] = e.target.value;
                setLocalValues(newValues);
              }}></Textarea>
            </div>
            <Button status={loading?"loading":"save"} type="submit" value="Save"/>
          </form>
        </div></div>
        <div className='slide'><div>
          <Table database={"Worlds"}
            newButtonText={"New Character"}
            newButtonOnClickFunc={() => {
              if(newItemModalCallback){newItemModalCallback("characters")};
            }}
            displayName="Characters" name="CharactersTable" limit={tableLimit}
            sideItems={charSideItems}joinOptions={joinOptions} columns={charColumns}
            rowOnClickFunc={(value) => {
              let newValues = clone(values);
              newValues[tables.characters] = value;
              setValues(newValues);
            }}
            returnResultsFunc={(results) => {
              let newActiveValue = !(tables.characters in values) && results.length >= 1?results[0]:null;
              let newValues = values;
              if(newActiveValue !== null){
                newValues = clone(values);
                newValues[tables.characters] = newActiveValue
              }
              setValues(newValues);
            }}
            refreshInc={tables.characters in refreshIncObj?refreshIncObj[tables.characters]:0}
          />
        </div></div>
        <div className='slide'><div>
          <Table database={"Worlds"}
            newButtonText={"New Location"}
            newButtonOnClickFunc={() => {
              if(newItemModalCallback){newItemModalCallback("locations")};
            }}
            displayName="Locations" name="LocationsTable" limit={tableLimit}
            sideItems={locationsSideItems}joinOptions={joinOptions} columns={locationsColumns}
            rowOnClickFunc={(value) => {
              let newValues = clone(values);
              newValues[tables.locations] = value;
              setValues(newValues);
            }}
            returnResultsFunc={(results) => {
              let newActiveValue = !(tables.locations in values) && results.length >= 1?results[0]:null;
              let newValues = values;
              if(newActiveValue !== null){
                newValues = clone(values);
                newValues[tables.locations] = newActiveValue
              }
              setValues(newValues);
            }}
            refreshInc={tables.locations in refreshIncObj?refreshIncObj[tables.locations]:0}
          />
        </div></div>
        <div className='slide'><div>
          <Table database={"Worlds"}
            newButtonText={"New Culture"}
            newButtonOnClickFunc={() => {
              if(newItemModalCallback){newItemModalCallback("cultures")};
            }}
            displayName="Cultures" name="CulturesTable" limit={tableLimit}
            sideItems={culturesSideItems}joinOptions={joinOptions} columns={culturesColumns}
            rowOnClickFunc={(value) => {
              let newValues = clone(values);
              newValues[tables.cultures] = value;
              setValues(newValues);
            }}
            returnResultsFunc={(results) => {
              let newActiveValue = !(tables.cultures in values) && results.length >= 1?results[0]:null;
              let newValues = values;
              if(newActiveValue !== null){
                newValues = clone(values);
                newValues[tables.cultures] = newActiveValue
              }
              setValues(newValues);
            }}
            refreshInc={tables.cultures in refreshIncObj?refreshIncObj[tables.cultures]:0}
          />
        </div></div>
        <div className='slide'><div>
          <Table database={"Worlds"}
            newButtonText={"New Species"}
            newButtonOnClickFunc={() => {
              if(newItemModalCallback){newItemModalCallback("species")};
            }}
            displayName="Species" name="SpeciesTable" limit={tableLimit}
            sideItems={speciesSideItems}joinOptions={joinOptions} columns={speciesColumns}
            rowOnClickFunc={(value) => {
              let newValues = clone(values);
              newValues[tables.species] = value;
              setValues(newValues);
            }}
            returnResultsFunc={(results) => {
              let newActiveValue = !(tables.species in values) && results.length >= 1?results[0]:null;
              let newValues = values;
              if(newActiveValue !== null){
                newValues = clone(values);
                newValues[tables.species] = newActiveValue
              }
              setValues(newValues);
            }}
            refreshInc={tables.species in refreshIncObj?refreshIncObj[tables.species]:0}
          />
        </div></div>
        <div className='slide'><div>
          <Table database={"Worlds"}
            newButtonText={"New Conflict"}
            newButtonOnClickFunc={() => {
              if(newItemModalCallback){newItemModalCallback("conflicts")};
            }}
            displayName="Conflict" name="ConflictTable" limit={tableLimit}
            sideItems={conflictsSideItems}joinOptions={joinOptions} columns={conflictsColumns}
            rowOnClickFunc={(value) => {
              let newValues = clone(values);
              newValues[tables.conflicts] = value;
              setValues(newValues);
            }}
            returnResultsFunc={(results) => {
              let newActiveValue = !(tables.conflicts in values) && results.length >= 1?results[0]:null;
              let newValues = values;
              if(newActiveValue !== null){
                newValues = clone(values);
                newValues[tables.conflicts] = newActiveValue
              }
              setValues(newValues);
            }}
            refreshInc={tables.conflicts in refreshIncObj?refreshIncObj[tables.conflicts]:0}
          />
        </div></div>
        <div className='slide'><div>
          <Table database={"Worlds"}
            newButtonText={"New Religion"}
            newButtonOnClickFunc={() => {
              if(newItemModalCallback){newItemModalCallback("religions")};
            }}
            displayName="Religions" name="ReligionsTable" limit={tableLimit}
            sideItems={religionsSideItems}joinOptions={joinOptions} columns={religionsColumns}
            rowOnClickFunc={(value) => {
              let newValues = clone(values);
              newValues[tables.religions] = value;
              setValues(newValues);
            }}
            returnResultsFunc={(results) => {
              let newActiveValue = !(tables.religions in values) && results.length >= 1?results[0]:null;
              let newValues = values;
              if(newActiveValue !== null){
                newValues = clone(values);
                newValues[tables.religions] = newActiveValue
              }
              setValues(newValues);
            }}
            refreshInc={tables.religions in refreshIncObj?refreshIncObj[tables.religions]:0}
          />
        </div></div>
        <div className='slide'><div>
          <Table database={"Worlds"}
            newButtonText={"New Religion Diety"}
            newButtonOnClickFunc={() => {
              if(newItemModalCallback){newItemModalCallback("religionGods")};
            }}
            displayName="ReligionGods" name="ReligionGodsTable" limit={tableLimit}
            sideItems={religionGodsSideItems}joinOptions={joinOptions} columns={religionGodsColumns}
            rowOnClickFunc={(value) => {
              let newValues = clone(values);
              newValues[tables.religionGods] = value;
              setValues(newValues);
            }}
            returnResultsFunc={(results) => {
              let newActiveValue = !(tables.religionGods in values) && results.length >= 1?results[0]:null;
              let newValues = values;
              if(newActiveValue !== null){
                newValues = clone(values);
                newValues[tables.religionGods] = newActiveValue
              }
              setValues(newValues);
            }}
            refreshInc={tables.religionGods in refreshIncObj?refreshIncObj[tables.religionGods]:0}
          />
        </div></div>
        <div className='slide'><div>
          <Table database={"Worlds"}
            newButtonText={"New Faction"}
            newButtonOnClickFunc={() => {
              if(newItemModalCallback){newItemModalCallback("factions")};
            }}
            displayName="Factions" name="FactionsTable" limit={tableLimit}
            sideItems={factionsSideItems}joinOptions={joinOptions} columns={factionsColumns}
            rowOnClickFunc={(value) => {
              let newValues = clone(values);
              newValues[tables.factions] = value;
              setValues(newValues);
            }}
            returnResultsFunc={(results) => {
              let newActiveValue = !(tables.factions in values) && results.length >= 1?results[0]:null;
              let newValues = values;
              if(newActiveValue !== null){
                newValues = clone(values);
                newValues[tables.factions] = newActiveValue
              }
              setValues(newValues);
            }}
            refreshInc={tables.factions in refreshIncObj?refreshIncObj[tables.factions]:0}
          />
        </div></div>
        <div className='slide'><div>Stories</div></div>
        <div className='slide'><div>Languages</div></div>
      </Carousel>
    </div>
  );

};

export default WorldBuilderRight;