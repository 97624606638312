
import {Environment,EnvironmentEnum, SandboxEnvArr} from './env';
import {SnsTypeTextEnum} from '../enums/sns-type';

export const ConfigVariables = {
  TestMode: false,
  // SnsAuthMethods: [SnsTypeTextEnum.Google,SnsTypeTextEnum.Facebook],
  SnsAuthMethods: [SnsTypeTextEnum.Google],
  MythBlender: true,
}

const Config = {
  Common:{
    ApiBaseUrl: "http://localhost:3035",
    ApiBaseSocket: "ws://localhost:3035",
    ApiSandboxBaseUrl: "http://localhost:3036",
    ApiSandboxBaseSocket: "ws://localhost:3036",
    AppName: "MythBlender",
    Company: "MythBlender",
    CdnUrl: "https://cdn.mythblender.com",
    CompanyLink: "https://mythblender.com/",
    FrontEndUrl: "http://localhost:3030",
    LiveFrontEndUrl: "http://localhost:3030",
    SandboxFrontEndUrl: "http://localhost:3031",
    ApiDocsUrl: "http://localhost:3032",
    SupportEmailName: "mail",
    SupportEmailDomain: "mythblender.com",
    PaypalBaseUrl: "https://www.paypal.com",
  },
  Keys:{
    PaypalPublicKey: "AQ8TkE2g9bVt4sV--Pc4Vo1bEqGuej_sRez6pKPzJOO1jsJzQwooLBxv9L0G9sDv8sdclR_w6_AFixd8",
    DawipayStoreUid: "store_7un2wmSS6kJd1PphYb8i8R",
    StripePublicKey: "pk_test_51OokGyKyLsV8n22FgvhqUgRRQjMmVWEAuBv5CrdOpokS5rSwEqGSzny2xQZfiH1pEbGynxX2p4aWtbrrUlcAzwui00o1rZh5rS",
    GoogleAnalytics: "",
    RecaptchaPubKey: "6Ld-yXYpAAAAAEM7_pBSm0LnIUdVk5lVt8eOhW6a",
  },
  PriceUids:{
    Stripe:{
      Lite:{
        Month1:"price_1Ookl7KyLsV8n22F7q8gHZCZ",
        Year1:"price_1OoknwKyLsV8n22F4zZp8uT2",
      },
      Premium:{
        Month1:"price_1Ookm4KyLsV8n22F5VL3Abi3",
        Year1:"price_1OokoBKyLsV8n22FEiaNO0me",
      },
      Pro:{
        Month1:"price_1OokmwKyLsV8n22FxYbKb5YQ",
        Year1:"price_1OokoPKyLsV8n22Fa7W6mN7K",
      },
      TopUps:{
        Price1:"price_1Ooyt0KyLsV8n22FLwpIlSg3",
        Price2:"price_1Ooz1LKyLsV8n22Fh4nVZNnc",
        Price3:"price_1Ooz1cKyLsV8n22FuQUaIYKU",
        Price4:"price_1Ooz31KyLsV8n22FNcmh1llw",
        Price5:"price_1Ooz39KyLsV8n22Fq7fj1JKo",
        Price6:"price_1Ooz71KyLsV8n22Fs40IBOhX",
      },
    },
    Paypal:{
      Lite:{
        Month1:"P-25D62041KF9292641MXP7D5Q",
        Year1:"P-5YE060822T013222GMXP7EOQ",
      },
      Premium:{
        Month1:"P-65633771PW3252541MXP7E3A",
        Year1:"P-4LH81502V46757222MXP7FEY",
      },
      Pro:{
        Month1:"P-5W4948263C400062PMXP7FPA",
        Year1:"P-6SF902653R0797134MXP7F4Q",
      },
      TopUps:{
        Price1:"",
        Price2:"",
        Price3:"",
        Price4:"",
        Price5:"",
        Price6:"",
      },
    },
    Dawipay:{
      Lite:{
        Month1:"",
        Year1:"",
      },
      Premium:{
        Month1:"",
        Year1:"",
      },
      Pro:{
        Month1:"",
        Year1:"",
      },
      TopUps:{
        Price1:"",
        Price2:"",
        Price3:"",
        Price4:"",
        Price5:"",
        Price6:"",
      },
    }
  },
  Sandbox: false,
};

if(ConfigVariables.MythBlender){
  Config.Common.AppName = "MythBlender";
  Config.Common.Company = "MythBlender";
  Config.Common.CompanyLink = "https://mythblender.com/";
  Config.Common.SupportEmailName = "mail";
  Config.Common.SupportEmailDomain = "mythblender.com";
}

if(SandboxEnvArr.indexOf(Environment) !== -1){
  Config.Sandbox = true;
}
if(Environment === EnvironmentEnum.Local2){
  console.log("Running Local2 Env");
  Config.Common.ApiBaseUrl = "http://localhost:3036";
  Config.Common.ApiBaseSocket = "ws://localhost:3036";
  Config.Common.FrontEndUrl = "http://localhost:3038";
  Config.Common.LiveFrontEndUrl = "http://localhost:3038";
}
if(Environment === EnvironmentEnum.Local3){
  console.log("Running Local3 Env");
  Config.Common.ApiBaseUrl = "http://localhost:3037";
  Config.Common.ApiBaseSocket = "ws://localhost:3037";
  Config.Common.FrontEndUrl = "http://localhost:3039";
  Config.Common.LiveFrontEndUrl = "http://localhost:3039";
}

if(Environment === EnvironmentEnum.LocalSandbox){
  Config.Common.ApiBaseUrl = Config.Common.ApiSandboxBaseUrl;
  Config.Common.ApiBaseSocket = Config.Common.ApiSandboxBaseSocket;
  Config.Common.FrontEndUrl = Config.Common.SandboxFrontEndUrl;
}


if(Environment === EnvironmentEnum.Release || Environment === EnvironmentEnum.ReleaseSandbox){
  Config.Common.ApiBaseUrl = "https://release-api.mythblender.com";
  Config.Common.FrontEndUrl = "https://release.mythblender.com";
  Config.Common.LiveFrontEndUrl = "https://release.mythblender.com";
  // Config.Common.ApiDocsUrl = "https://docs-base.mythblender.co";
  Config.Common.ApiBaseSocket = "wss://release-api.mythblender.com";
  Config.Keys.RecaptchaPubKey = "6Ld-yXYpAAAAAEM7_pBSm0LnIUdVk5lVt8eOhW6a";
}


if(Environment === EnvironmentEnum.Production || Environment === EnvironmentEnum.ProductionSandbox){
  Config.Common.ApiBaseUrl = "https://api.mythblender.com";
  Config.Common.FrontEndUrl = "https://mythblender.com";
  Config.Common.LiveFrontEndUrl = "https://mythblender.com";
  Config.Common.ApiDocsUrl = "https://docs.mythblender.com";
  Config.Common.ApiBaseSocket = "wss://api.mythblender.com";
  Config.Keys.RecaptchaPubKey = "6Ld-yXYpAAAAAEM7_pBSm0LnIUdVk5lVt8eOhW6a";
}

if(Environment === EnvironmentEnum.Production || Environment === EnvironmentEnum.Release){
  Config.Keys.StripePublicKey = "pk_live_51OokGyKyLsV8n22FgPO0t40G6qKMJiUGIAf6j5QrT7BRyxiEe87t5DeZ8niGHD7M5UHTY0rkmo4MsvvDUuB3UvLv00rlR9Q52v";
  Config.Keys.PaypalPublicKey = "AVWpxpAupoZv0n-N7F6mSWhPfz25f7eHVcK7bcOfB-uBAf4ZdrD02qeML-UOxjZMkcb9tGRpi2pqvzSJ";
  Config.Keys.DawipayStoreUid = "";


  Config.PriceUids.Stripe.Lite.Month1 = "price_1OokoXKyLsV8n22F2HZmIAxu";
  Config.PriceUids.Stripe.Lite.Year1 = "price_1OokoXKyLsV8n22FNDm98wjw";
  Config.PriceUids.Stripe.Premium.Month1 = "price_1OokodKyLsV8n22FWUyTbjG2";
  Config.PriceUids.Stripe.Premium.Year1 = "price_1OokodKyLsV8n22F7Q7COtZh";
  Config.PriceUids.Stripe.Pro.Month1 = "price_1OokohKyLsV8n22FhdH7sGcz";
  Config.PriceUids.Stripe.Pro.Year1 = "price_1OokohKyLsV8n22FXDD63vQO";

  Config.PriceUids.Paypal.Lite.Month1 = "P-92B236831G2901641MXP66AI";
  Config.PriceUids.Paypal.Lite.Year1 = "P-0W485802W66092351MXP66UA";
  Config.PriceUids.Paypal.Premium.Month1 = "P-1A180783M5849913WMXP7B4Y";
  Config.PriceUids.Paypal.Premium.Year1 = "P-2HT04405NC494012FMXP7CLA";
  Config.PriceUids.Paypal.Pro.Month1 = "P-2TJ06926R5292591LMXP7CWQ";
  Config.PriceUids.Paypal.Pro.Year1 = "P-48V68804N03614142MXP7DCY";

  Config.PriceUids.Dawipay.Lite.Month1 = "";
  Config.PriceUids.Dawipay.Lite.Year1 = "";
  Config.PriceUids.Dawipay.Premium.Month1 = "";
  Config.PriceUids.Dawipay.Premium.Year1 = "";
  Config.PriceUids.Dawipay.Pro.Month1 = "";
  Config.PriceUids.Dawipay.Pro.Year1 = "";


  Config.PriceUids.Stripe.TopUps.Price1 = "price_1Ooz7KKyLsV8n22FIy6JNrCB";
  Config.PriceUids.Stripe.TopUps.Price2 = "price_1Ooz7KKyLsV8n22FveBWvdhR";
  Config.PriceUids.Stripe.TopUps.Price3 = "price_1Ooz7KKyLsV8n22FFEHSm7MN";
  Config.PriceUids.Stripe.TopUps.Price4 = "price_1Ooz7KKyLsV8n22FY4A2W6gC";
  Config.PriceUids.Stripe.TopUps.Price5 = "price_1Ooz7KKyLsV8n22F55nmSroa";
  Config.PriceUids.Stripe.TopUps.Price6 = "price_1Ooz7KKyLsV8n22FMElL0sfd";

  Config.PriceUids.Paypal.TopUps.Price1 = "";
  Config.PriceUids.Paypal.TopUps.Price2 = "";
  Config.PriceUids.Paypal.TopUps.Price3 = "";
  Config.PriceUids.Paypal.TopUps.Price4 = "";
  Config.PriceUids.Paypal.TopUps.Price5 = "";
  Config.PriceUids.Paypal.TopUps.Price6 = "";

  Config.PriceUids.Dawipay.TopUps.Price1 = "";
  Config.PriceUids.Dawipay.TopUps.Price2 = "";
  Config.PriceUids.Dawipay.TopUps.Price3 = "";
  Config.PriceUids.Dawipay.TopUps.Price4 = "";
  Config.PriceUids.Dawipay.TopUps.Price5 = "";
  Config.PriceUids.Dawipay.TopUps.Price6 = "";
  
}



export default Config;
