import React from 'react';

import {ReactComponent as DashboardSvg} from '../../css/imgs/icon-dashboard.svg';
import {ReactComponent as ForumSvg} from '../../css/imgs/icon-forum.svg';
// import {ReactComponent as LibrarySvg} from '../../css/imgs/icon-library.svg';
import {ReactComponent as BlogSvg} from '../../css/imgs/icon-library.svg';
import {ReactComponent as SupportSvg} from '../../css/imgs/icon-support.svg';
import {ReactComponent as PriceSvg} from '../../css/imgs/icon-price.svg';
import {ReactComponent as InfoSvg} from '../../css/imgs/icon-info.svg';

import { t } from "../../translations/i18n";

import AccountStatusEnum from '../../enums/account-status';

const naviRoutes = [
  {
    "name": "dashboard-nav-item", 
    "display": t("navi.dashboard"), 
    "path": "/dashboard",
    "icon": <DashboardSvg/>,
    // "minStatus": AccountStatusEnum.Any,
  },
  {
    "name": "how-to-use-nav-item", 
    "display": t("navi.howToUse"),
    "path": "/how-to-use",
    "icon": <InfoSvg/>,
    // "minStatus": AccountStatusEnum.Any,
  },
  {
    "name": "pricing-nav-item", 
    "display": t("navi.pricing"),
    // "path": "/pricing",
    "path": "/ai-story-generator-pricing",
    "icon": <PriceSvg/>,
    // "minStatus": AccountStatusEnum.Any,
  },
  // {
  //   "name": "blog-nav-item", 
  //   "display": t("navi.blog"),
  //   "path": "/blog",
  //   "icon": <BlogSvg/>,
  //   "minStatus": AccountStatusEnum.Any,
  // },
  // {
  //   "name": "community-nav-item", 
  //   "display": t("navi.community"),
  //   "path": "/community",
  //   "icon": <ForumSvg/>,
  //   // "minStatus": AccountStatusEnum.Any,
  // },
  {
    "name": "help-nav-item", 
    "display": t("navi.help"),
    "path": "/help",
    "icon": <SupportSvg/>,
    // "minStatus": AccountStatusEnum.Any,
  },
  // {
  //   "name": "test", 
  //   "display": "Test",
  //   "path": "/test",
  //   "minStatus": AccountStatusEnum.Basic,
  // },
  // {
  //   "name": "test", 
  //   "display": "Test",
  //   "path": "/test-socket",
  //   // "minStatus": AccountStatusEnum.Basic,
  // },

];

export default naviRoutes;