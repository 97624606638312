import React,{useState,useEffect,useRef} from 'react';
import './world-builder.scss';
import * as StringUtil from '../../../utils/string';
import usePersistState from '../../../utils/use-persist-state';
import { clone } from '../../../utils/clone';
import { toast } from 'react-toastify';
import { request,unSubRequest } from '../../../utils/request';
import Loading from '../../common/loading/loading';
import Button from '../../elements/button/button';
import WorldBuilderRight from './world-builder-right';
import WorldBuilderCenter from './world-builder-center';
import WorldBuilderImages from './world-builder-images';
import WorldBuilderNewItemModal from './world-builder-new-item-modal';
import WorldBuilderNewImageModal from './world-builder-new-image-modal';
import WorldBuilderImageQueueModal from './world-builder-image-queue-modal';
import useWindowDimensions from '../../../utils/use-window-dimensions';

import SocketEnum from '../../../enums/socket';
import SocketEventEnum from '../../../enums/socket-event';
import useSocketState from '../../../store/socket';

import {ReactComponent as GlobeSvg} from '../../../css/imgs/icon-globe.svg';
import {ReactComponent as GroupsSvg} from '../../../css/imgs/icon-groups.svg';
import {ReactComponent as SwordsSvg} from '../../../css/imgs/icon-swords.svg';
import {ReactComponent as MapsSvg} from '../../../css/imgs/icon-maps.svg';
import {ReactComponent as ChurchSvg} from '../../../css/imgs/icon-church.svg';
import {ReactComponent as ZeusSvg} from '../../../css/imgs/icon-zeus.svg';
import {ReactComponent as BookSvg} from '../../../css/imgs/icon-book.svg';
import {ReactComponent as LanguageSvg} from '../../../css/imgs/icon-language.svg';
import {ReactComponent as CulturesSvg} from '../../../css/imgs/icon-cultures.svg';
import {ReactComponent as SpeciesSvg} from '../../../css/imgs/icon-species.svg';
import {ReactComponent as ShieldSvg} from '../../../css/imgs/icon-shield.svg';
import {ReactComponent as CarrotUpSvg} from '../../../css/imgs/icon-carrot-up.svg';
import {ReactComponent as CarrotDownSvg} from '../../../css/imgs/icon-carrot-down.svg';
import {ReactComponent as CarrotLeftSvg} from '../../../css/imgs/icon-carrot-left.svg';
import {ReactComponent as CarrotRightSvg} from '../../../css/imgs/icon-carrot-right.svg';


let rows = [
  {slide: 1, icon: (<GlobeSvg/>),display:"World",value:"worlds",},
  {slide: 2, icon: (<GroupsSvg/>),display:"Characters",value:"characters",},
  {slide: 3, icon: (<MapsSvg/>),display:"Locations",value:"locations",},
  {slide: 4, icon: (<CulturesSvg/>),display:"Cultures",value:"cultures", medIcon:true,},
  {slide: 5, icon: (<SpeciesSvg/>),display:"Species",value:"species", medIcon:true,},
  {slide: 6, icon: (<SwordsSvg/>),display:"Conflicts",value:"conflicts",},
  {slide: 7, icon: (<ChurchSvg/>),display:"Religions",value:"religions",},
  {slide: 8, icon: (<ZeusSvg/>),display:"Religion Dieties",value:"religionGods",bigIcon:true,},
  {slide: 9, icon: (<ShieldSvg/>),display:"Factions",value:"factions",},
  {slide: 10, icon: (<BookSvg/>),display:"Stories",value:"stories",hideOther:true,comingSoon:true,},
  // {slide: 9, icon: (<LanguageSvg/>),display:"Languages",value:"languages",hideOther:true},
];

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {width,height};
}

const WorldBuilder = ({uid,quickView}) => {

  const windowDimensions = getWindowDimensions();
  let initToggleState = windowDimensions.width > 800?false:true;

  let initSlide = quickView?1:0;

  const [row,setRow] = useState(rows[initSlide].value);
  const [currentRowSlide,setCurrentRowSlide] = useState(rows[initSlide]);
  const [slide,setSlide] = useState(initSlide+1);
  const [loading,setLoading] = useState(false);
  const [values,setValues] = useState({});
  const [world,setWorld] = useState({});
  const [show,setShow] = useState(false);
  const [imageQueueModalShow,setImageQueueModalShow] = useState(false);
  const [imageModalShow,setImageModalShow] = useState(false);
  const [table,setTable] = useState(rows[initSlide].value);
  const [expand,setExpand] = useState(false);
  const [images,setImages] = useState([{addButton:true}]);
  const [imageSlide,setImageSlide] = useState(1);

  const { height, width } = useWindowDimensions();
  const [imageAffix,setImageAffix] = useState("-small.png");

  useEffect(() => {
    let newAffix = "-small.png";
    if(width >= 800){
      newAffix = "-medium.png";
    }else if(width >= 1200){
      newAffix = ".png";
    }
    setImageAffix(newAffix);
  },[height,width])

  const [toggleLeft,setToggleLeft] = usePersistState(initToggleState,"worldBuilderToggleLeft","boolean");
  const [toggleRight,setToggleRight] = usePersistState(initToggleState,"worldBuilderToggleRight","boolean");
  const [toggleCenter,setToggleCenter] = usePersistState(initToggleState,"worldBuilderToggleCenter","boolean");

  const [refreshTableInc,setRefreshTableInc] = useState(1);
  const [refreshTableName,setRefreshTableName] = useState("");

  const [fetchImageTable,setFetchImageTable] = useState(rows[0].value);
  const [fetchImageTableUid,setFetchImageTableUid] = useState(uid);

  const {socketMessages,sendSocketMessage,} = useSocketState();
  const [clientIds,setClientIds] = useState([]);
  const [messages,setMessages] = useState([]);

  const fullHeightRef = useRef(null);

  useEffect(() => {
    setCurrentRowSlide(rows[slide-1])
  },[slide]);

  useEffect(() => {
    fetchWorld(uid);
  },[uid]);


  useEffect(() => {
    const updateVH = () => {
      if (expand) {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        document.body.style.overflow = "hidden";
      } else {
        document.documentElement.style.setProperty('--vh', `100vh`);
        document.body.style.overflow = "";
      }
    };
  
    if(!quickView){
      updateVH();
      window.addEventListener('resize', updateVH);
      window.addEventListener('orientationchange', updateVH);
    }

    return () => {
      if(!quickView){
        window.removeEventListener('resize', updateVH);
        window.removeEventListener('orientationchange', updateVH);
        document.body.style.overflow = "";
      }
    };
  }, [expand,quickView]); 


  useEffect(() => {
    const data = {
      event: SocketEventEnum.Subscribe,
      channels: {},
    }
    data.channels[SocketEnum.WorldBuilder] = "userUid$"+SocketEnum.WorldBuilder;
    sendSocketMessage(data);
    return () => {
      data.event = SocketEventEnum.Unsubscribe
      sendSocketMessage(data);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    let newClientIds = clone(clientIds);
    let newMessage = clone(messages);

    if(socketMessages){
      // console.log("socketMessages",socketMessages);
      if(SocketEnum.WorldBuilder in socketMessages){
        // console.log("world builder msg");
        for(let key in socketMessages[SocketEnum.WorldBuilder]){
          let item = socketMessages[SocketEnum.WorldBuilder][key];
          if(item.err.code === 0){
            let res = item.res;
            if(SocketEnum.WorldBuilder in res){
              let text = res[SocketEnum.WorldBuilder].text;
              // console.log("res[SocketEnum.WorldBuilder]",res[SocketEnum.WorldBuilder]);
              if("error" in res[SocketEnum.WorldBuilder]){
                toast.error(text);
              }else{
                toast.success(text);
              }
            }
          }
          break;
        }
      }

    }

    setClientIds(newClientIds);
    setMessages(newMessage)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[socketMessages,])


  useEffect(( )=> {
    fetchImagesByValueUid(table,values);
  },[table,values]);

  const fetchImagesByValueUid = (table,values) => {
    let passUid = "";
    if(table === "worlds"){
      passUid = uid;
    }else{
      if(table in values){
        for(let key in values[table]){
          let newKey = key.replace(table,"");
          newKey = StringUtil.pascalCaseToCamelCase(newKey);
          if(newKey === "uid"){
            passUid = values[table][key];
            break;
          }
        }
      }
    }
    setFetchImageTable(table);
    setFetchImageTableUid(passUid);
  }

  useEffect(() => {
    if(fetchImageTable !== null){
      fetchImages(fetchImageTable,fetchImageTableUid);
    }
  },[fetchImageTable,fetchImageTableUid])

  const fetchImages = (table,uid) => {
    unSubRequest("fetch-images");
    let body = {
      table,
      uid,
    }
    // let newImages = [];
    // newImages.push({addButton:true});
    // setImages(newImages);
    request("fetch-images","/world-item/fetch-images","POST", body, {
      then: function(res){
        let newImages = res.data.res;
        newImages.unshift({addButton:true});
        setImages(res.data.res);
        setImageSlide(newImages.length > 1?2:1);
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){}
    });
  }

  const fetchWorld = (uid) => {
    setLoading(true);
    request("fetch-world","/world/fetch/"+uid,"GET", {}, {
      then: function(res){
        // console.log("world res",res);
        let newWorld = res.data.res;
        setWorld(newWorld);
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false);}
    });
  }

  const saveEditWorld = (uid,data,) => {
    let body = {uid,data,}
    // console.log("body",body);
    setLoading(true);
    request("edit-world","/world/edit","POST", body, {
      then: function(res){toast.success("Saved");
        fetchWorld(uid);
        setLoading(false);
      },
      catch: function(err){toast.error(err.message);setLoading(false);},
      finally: function(){}
    });
  }


  return (
    <div ref={fullHeightRef} className={"world-builder-comp "+((expand)?"expand":"")}>
      
      <div className='world-container'>
        <div className={'loading-overlay '+((loading)?"show":"hide")}>
          <div className='loading-overlay-inner'>
            <div className='top'><Loading show={loading} /></div>
            <div className='bottom'><div>Loading World...</div></div>
          </div>
        </div>

        <div className='top-bar'>
          <div className='left'>
            <div className='text'>{currentRowSlide.display} - {(world && world.name)?world.name:""}</div>
          </div>
          <div className='right'>
            {quickView?null:
            <>
              <Button status={"image"} onClick={()=>{
                setImageQueueModalShow(true);
              }}></Button>
              <Button status={expand?"collapse":"expand"} onClick={()=>{
                setExpand(!expand);
              }}></Button>
            </>
            }
          </div>
        </div>
        <div className='mobile-toggles'>
          <div className='left-toggle' onClick={() => {
            setToggleLeft(!toggleLeft);
          }}>
            {(toggleLeft)?
              <>
                <div className='carrot'><CarrotRightSvg/></div>
                <div className='carrot'><CarrotRightSvg/></div>
                <div className='carrot'><CarrotRightSvg/></div>
              </>
            : <>
                <div className='carrot'><CarrotLeftSvg/></div>
                <div className='carrot'><CarrotLeftSvg/></div>
                <div className='carrot'><CarrotLeftSvg/></div>
              </>}
          </div>
          <div className='center-toggle' onClick={() => {
              setToggleCenter(!toggleCenter);
            }}>
            {(toggleCenter)?
              <>
                <div className='carrot'><CarrotUpSvg/></div>
                <div className='carrot'><CarrotUpSvg/></div>
                <div className='carrot'><CarrotUpSvg/></div>
              </>
            : <>
                <div className='carrot'><CarrotDownSvg/></div>
                <div className='carrot'><CarrotDownSvg/></div>
                <div className='carrot'><CarrotDownSvg/></div>
              </>}
          </div>
          <div className='right-toggle' onClick={() => {
              setToggleRight(!toggleRight);
            }}>
          {(toggleRight)?
              <>
                <div className='carrot'><CarrotLeftSvg/></div>
                <div className='carrot'><CarrotLeftSvg/></div>
                <div className='carrot'><CarrotLeftSvg/></div>
              </>
            : <>
                <div className='carrot'><CarrotRightSvg/></div>
                <div className='carrot'><CarrotRightSvg/></div>
                <div className='carrot'><CarrotRightSvg/></div>
              </>}
          </div>
        </div>
        <div className='world-main'>
          <div className={'left '+((toggleLeft && !quickView)?"toggled":"")}>
            {quickView?null:
            <div className='toggle' onClick={() => {
              setToggleLeft(!toggleLeft);
            }}>
              {(toggleLeft)?
                <>
                  <div className='carrot'><CarrotRightSvg/></div>
                  <div className='carrot'><CarrotRightSvg/></div>
                  <div className='carrot'><CarrotRightSvg/></div>
                </>
              : <>
                  <div className='carrot'><CarrotLeftSvg/></div>
                  <div className='carrot'><CarrotLeftSvg/></div>
                  <div className='carrot'><CarrotLeftSvg/></div>
                </>}
            </div>
            }
            <div className='rows'>
              {rows.map((v,i) => {
                if(v.hide) return null;
                if(quickView && i === 0) return null;
                if(quickView && v.comingSoon) return null;
                return(
                  <div className={'row '+((row === v.value)?"active":"")} onClick={() => {
                    setRow(v.value);
                    setSlide(i+1);
                    setTable(v.value);
                  }} key={i}>
                    <div className={'icon '+((v.bigIcon)?"big":"")+((v.medIcon)?"med":"")}>{v.icon}</div>
                    <div className='text'>{v.display}</div>
                  </div>
                )
              })}
            </div>
          </div>
          {quickView?null:
          <div className={'center '+((toggleCenter)?"toggled":"")}>
            <div className={'top '+((currentRowSlide.hideOther)?"hide-other":"")}>
              <WorldBuilderImages 
                imageAffix={imageAffix}
                slide={imageSlide}
                setSlide={setImageSlide}
                images={images}
                setShow={(show) => {
                  let newShow = show;
                  if(!(table in values) && table !== "worlds"){
                    newShow = false;
                    toast.info("Select an item from the right first");
                  }
                  setImageModalShow(newShow)
                }}     
                refreshCallback={() => {
                  setFetchImageTable(null);
                  fetchImagesByValueUid(table,values);
                }}
                />
            </div>
            <div className={'center-toggle '+((currentRowSlide.hideOther)?"hide":"")} onClick={() => {
              setToggleCenter(!toggleCenter);
            }}>
              {(toggleCenter)?
                <>
                  <div className='carrot'><CarrotUpSvg/></div>
                  <div className='carrot'><CarrotUpSvg/></div>
                  <div className='carrot'><CarrotUpSvg/></div>
                </>
              : <>
                  <div className='carrot'><CarrotDownSvg/></div>
                  <div className='carrot'><CarrotDownSvg/></div>
                  <div className='carrot'><CarrotDownSvg/></div>
                </>}
            </div>
            <div className='bottom'>
              <WorldBuilderCenter world={world} slide={slide} values={values} 
                table={table}
                saveEditWorld={saveEditWorld}
                refreshTableCallback={(table,refreshActive) => {
                  let newValues = values;
                  if(refreshActive && table in values){
                    newValues = clone(values);
                    delete newValues[table];
                  }
                  setValues(newValues);
                  setRefreshTableInc(refreshTableInc + 1);
                  setRefreshTableName(table);
                }}
              />
            </div>
          </div>
          }
          <div className={'right '+((toggleRight && !quickView)?"toggled":"")+" "+((currentRowSlide.hideOther)?"hide-other":"")}>
            {quickView?null:
            <div className='toggle' onClick={() => {
              setToggleRight(!toggleRight);
            }}>
              {(toggleRight)?
                <>
                  <div className='carrot'><CarrotLeftSvg/></div>
                  <div className='carrot'><CarrotLeftSvg/></div>
                  <div className='carrot'><CarrotLeftSvg/></div>
                </>
              : <>
                  <div className='carrot'><CarrotRightSvg/></div>
                  <div className='carrot'><CarrotRightSvg/></div>
                  <div className='carrot'><CarrotRightSvg/></div>
                </>}
            </div>
            }
            <WorldBuilderRight uid={uid} slide={slide} 
              world={world}
              values={values}
              setValues={setValues}
              newItemModalCallback={(table) => {
                setShow(true);
                setTable(table);
              }}
              refreshTableInc={refreshTableInc}
              refreshTableName={refreshTableName}
            />
          </div>
        </div>
      </div>


      <WorldBuilderImageQueueModal 
        imageAffix={imageAffix}
        show={imageQueueModalShow}
        handleClose={() => {setImageQueueModalShow(false)}}
        />

      <WorldBuilderNewImageModal 
        show={imageModalShow}
        setShow={setImageModalShow}
        world={world}
        values={values}
        table={table}
        />

      <WorldBuilderNewItemModal 
        worldUid={uid}
        show={show}
        setShow={setShow}
        table={table}
        refreshTableCallback={(table) => {
          setRefreshTableInc(refreshTableInc + 1);
          setRefreshTableName(table);
        }}
        />
    </div>
  );

};

export default WorldBuilder;