import React, { useEffect, useState } from 'react';
import './new-world.scss';

import Config from '../../../config/config';
import Carousel from '../../common/carousel/carousel';
import CarouselHeader from '../../common/carousel/carousel-header';
import Textarea from 'react-textarea-autosize';
import Button from '../../elements/button/button';
import CoinButton from '../../elements/button/coin-button';
import SwitchToggle from '../../elements/switch-toggle/switch-toggle';
import {clone} from '../../../utils/clone';
import * as StringUtil from '../../../utils/string';
import { toast } from 'react-toastify';
import { request,unSubRequest } from '../../../utils/request';
import Loading from '../../common/loading/loading';
import ProgressBar from '../../common/progress-bar/progress-bar';
import usePersistState from '../../../utils/use-persist-state';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import Table from '../../elements/table/table';
import {worldOptions,advancedWorldOptions} from '../world-builder/world-options';
import {ReactComponent as ImageSvg} from '../../../css/imgs/icon-image.svg';
import WorldBuilder from '../world-builder/world-builder';


// const WorldOptions = {
//   genre: [
//     "Fantasy", "Sci-Fi", "Mystery", "Romance", "Adventure", "Horror", 
//     "Historical Fiction", "Dystopian", "Thriller", "Comedy", "Urban Fantasy", 
//     "Epic Fantasy", "Space Opera", "Cyberpunk", "Steampunk", "Paranormal"
//   ],
//   timePeriod: [
//     "Ancient", "Medieval", "Renaissance", "Victorian", "Modern", "Future", 
//     "1920s", "1950s", "1980s", "Post-Apocalyptic", "Timeless"
//   ],
//   worldLength: ["Short World", "Novella", "Full-Length Novel","Epic Series"],
//   location: ["Earth", "Imaginary World", "Space Station", "Underwater City", "Alternate Universe"],
//   environment: [
//     "Urban Metropolis", "Rural Village", "Mystical Forest", "Desert Wasteland", 
//     "Mountainous Terrain", "Secluded Islands", "Frozen Tundra"
//   ],
//   theme: [
//     "Love", "Redemption", "Betrayal", "Survival", "Freedom", "Identity", 
//     "Courage and Heroism", "Corruption", "Power and Ambition", "Fate vs. Free Will", 
//     "Good vs. Evil", "Man vs. Nature", "Individual vs. Society"
//   ],
//   intendedAudience: ["Children", "Young Adult", "Adult", "All Ages"],
//   toneAndMood: [
//     "Light-hearted", "Dark", "Suspenseful", "Humorous", "Romantic", "Tragic", 
//     "Inspirational", "Nostalgic", "Melancholic", "Eerie", "Whimsical"
//   ],
// };

// const advancedWorldOptions = {
//   plotStructure: [
//     "Kishotenketsu",  // A traditional four-part East Asian narrative structure.
//     "Freytag’s Pyramid",  // Classic five-part dramatic structure.
//     "In Medias Res",  // Beginning the World in the middle of the action.
//     "Circular Narrative",  // The World ends where it began, creating a circle.
//     "Frame Narrative",  // A World within a World, within sometimes another World.
//     "Three Act Structure",  // Dividing the World into three parts: Setup, Confrontation, and Resolution.
//     "Hero’s Journey",  // A common template involving a hero who goes on an adventure, and in a decisive crisis wins a victory, and then comes home changed or transformed.
//     "Nonlinear Narrative",  // The events are portrayed out of chronological order or in other ways where the narrative does not follow the direct causality pattern.
//     "Episodic",  // Composed of a series of loosely connected parts or events.
//     "Parallel Plots",  // Several equally important stories that run alongside each other.
//     "Rashomon Effect",  // Providing different contradictory versions of the same event, where the truth is not clear.
//     "Stream of Consciousness",  // Narrative mode depicting the continuous flow of thoughts and senses in the human mind.
//     "Bildungsroman",  // A coming-of-age World focusing on the psychological and moral growth of the protagonist.
//     "Picaresque",  // A World following a roguish but appealing hero, often in a satirical or humorous tone.
//     "Reverse Chronology",  // The plot is revealed in reverse order, starting from the end.
//     "Allegory",  // A symbolic narrative in which the surface details imply a secondary meaning.
//     "Vignette",  // Short, impressionistic scenes that focus on one moment or give a particular insight into a character, idea, or setting.
//     "Quest", 
//     "Voyage and Return",
//   ],
//   conflictType: [
//     "Person vs Person", "Person vs Self", "Person vs Society", "Person vs Nature", 
//     "Person vs Technology", "Person vs Supernatural", "Person vs Fate/Destiny"
//   ],
//   narrativePOV: [
//     "First Person", "Third Person Omniscient", "Third Person Limited", "Second Person", 
//     "Multiple POVs", "Unreliable Narrator", "Stream of Consciousness"
//   ],
//   mainCharacterArchetype: [
//     "Hero", "Villain", "Mentor", "Sidekick", "Anti-hero", "Tragic Hero", "Fool"
//   ],
//   mainCharacterTrait: ["Brave", "Intelligent", "Mysterious", "Evil", "Compassionate", "Cunning", "Naive"],
//   magicOrTechLevel: [
//     "None", "Low", "Medium", "High", "Alchemy-Based", "Rune-Based", 
//     "Cybernetic Enhancements", "Advanced AI Integration"
//   ],
//   culturalInfluence: [
//       "Ancient Greek", "Medieval European", "Feudal Japanese", "Ancient Egyptian", 
//       "Viking/Norse", "Imperial Chinese", "Indigenous American", "Mesoamerican", 
//       "Renaissance Italian", "Victorian British", "Ancient Roman", "Ottoman Empire", 
//       "Mongol Empire", "Sub-Saharan African", "Ancient Indian", "Medieval Arabic", 
//       "Indigenous Australian", "Polynesian", "Southeast Asian", "Pre-Colonial African", 
//       "Brazilian", "Caribbean", "Russian", "French Revolutionary", "Persian", "Celtic"
//   ],
//   specificElement: [
//     "Dragons", "Spaceships", "Zombies", "Time Travel", "Vampires", "Aliens", 
//     "Robots", "Magic Artifacts", "Ancient Prophecies", "Parallel Worlds", "Superpowers"
//   ],
//   languageStyle: [
//     "Modern", "Period-Specific", "Formal", "Colloquial", "Old English", 
//     "Shakespearean", "Futuristic Slang", "Poetic", "Minimalist", "Descriptive"
//   ]
// }

const SlideEnum = {
  Template: {Key: 1, Path: "template", Display: "Template"},
  Generate: {Key: 2, Path: "generate", Display: "Generate"},
  Prompt: {Key: 3, Path: "prompt", Display: "Prompt"},
  Blank: {Key: 4, Path: "blank", Display: "Blank"},
}

const NewWorld = ({show}) => {

  const [loading,setLoading] = useState(false);
  const [slide,setSlide] = useState(1);
  const [toggle,setToggle] = useState(false);
  const [chatgptModel,setChatgptModel] = usePersistState("chatgpt-3.5","dashboardModel");
  const [waitTime,setWaitTime] = useState(20);
  const [tokenCost,setTokenCost] = useState("6");
  const [values,setValues] = useState({});
  const [showLoadingOverlay,setShowLoadingOverlay] = useState(false);
  const [worldUid,setWorldUid] = useState(null);
  const [worldName,setWorldName] = useState("");
  const [worldBackstory,setWorldBackstory] = useState("");
  const [openWorldUid,setOpenWorldUid] = useState("");
  const [prompt,setPrompt] = usePersistState("","newWorldPrompt");

  let sideItems = [
    // {display: "All", conditions:[]},
    {display: "Templates", conditions:[{
      column:"template", table:"worlds", action: "=",value: 1,
    }]},
  ];

  const columns = [
    {table: "worlds",value: "userUid", hide:true, },
    {table: "worlds",value: "name", index:true, 
      custom: ({v,cv}) => {
        let uid = v["worldsUid"];
        let active = uid === openWorldUid;
        let activeClass = active?"active":""
        let imageUrl = v["worldsImageUrl"] ?? "";
        if(imageUrl.includes("{{cdn}}")){
          imageUrl = imageUrl.replace("{{cdn}}",Config.Common.CdnUrl);
        }
        if(imageUrl.includes(".png")){
          imageUrl = imageUrl.replace(".png","-small.png");
        }
        return(
          <div className={'new-world-name-div '+activeClass}>
            <div className='top' onClick={() => {
              setOpenWorldUid( uid === openWorldUid?"":uid);
            }}>
              <div className='icon'>
                {(imageUrl === "")?
                <ImageSvg/>
                :<img src={imageUrl} alt={v[cv]}/>}
              </div>
              <div className='text'>{v[cv]}</div>
            </div>
            {active?<div className='center'>
              <WorldBuilder 
                uid={uid}
                quickView={true}
                />
            </div>:null}
            {active?
            <div className='bottom'>
              <div className='left'></div>  
              <div className='right'>
                <Button status={loading?"loading":"forward"} onClick={() => {
                  copyTemplateWorld(uid);
                }}>Use Template World</Button>
              </div>  
            </div>:null}
          </div>
        )
      },  
    },
    {table: "worlds",value: "uid", hide: true, index:true, },
    {table: "worlds",value: "updatedDate", display:"Date", index:true, type:"date", defaultOrder:"DESC", hide:true},
    {table: "worlds",value: "imageUrl", hide:true, },
    {table: "worlds",value: "createdDate", hide:true, index:true, type:"date", },
  ];
  let joinOptions = [];

  useEffect(() => {
    let newWaitTime = 20;
    let newTokenCost = "6";
    if(chatgptModel !== "chatgpt-3.5"){
      newWaitTime = 60;
      newTokenCost = "60";
    }
    setTokenCost(newTokenCost);
    setWaitTime(newWaitTime)
  },[chatgptModel]);

  const handleSubmit = (e) => {
    e.preventDefault();
    generateWorld();
  }

  const generateWorld = () => {
    let copyWorld = slide === SlideEnum.Template.Key?true:false;
    let generateWorld = slide === SlideEnum.Generate.Key?true:false;
    let promptWorld = slide === SlideEnum.Prompt.Key?true:false;
    let blankWorld = slide === SlideEnum.Blank.Key?true:false;
    let body = {};
    for(let key in worldOptions){
      body[key] = (key in values)?values[key]:"unspecified";
      if(body[key] === "random"){
        body[key] = worldOptions[key][Math.floor(Math.random() * worldOptions[key].length)];
      }
    }
    for(let key in advancedWorldOptions){
      body[key] = (key in values)?values[key]:"unspecified";
      if(body[key] === "random"){
        body[key] = advancedWorldOptions[key][Math.floor(Math.random() * advancedWorldOptions[key].length)];
      }
    }
    if(values["additionalDetails"]){
      body["additionalDetails"] = values["additionalDetails"];
    }
    let data = {
      generateWorld,
      copyWorld,
      blankWorld,
      promptWorld,
      worldName,
      worldBackstory,      
      values: body,
      model: chatgptModel,
      prompt: prompt,
    }
    // console.log("data",data);
    setLoading(true);
    setShowLoadingOverlay(true);
    request("generate-new-world","/world/generate","POST", data, {
      then: function(res){
        // console.log("res",res);
        let newWorldUid = res.data.res.worldUid;
        setWorldUid(newWorldUid);
      },
      catch: function(err){
        toast.error(err.message);
        setLoading(false);setShowLoadingOverlay(false);
      },
      finally: function(){}
    });
  }

  const copyTemplateWorld = (uid) => {
    let data = {uid}
    // console.log("data",data);
    setLoading(true);
    setShowLoadingOverlay(true);
    request("use-world-template","/world/use-world-template","POST", data, {
      then: function(res){
        // console.log("res",res);
        let newWorldUid = res.data.res.worldUid;
        setWorldUid(newWorldUid);
      },
      catch: function(err){
        toast.error(err.message);
        setLoading(false);setShowLoadingOverlay(false);
      },
      finally: function(){}
    });
  }

  if(worldUid){
    return <Redirect push to={"/world-builder/"+worldUid}/>
  }

  return (
    <div className="new-world-comp">
      <div className={'loading-generate-world-overlay '+((showLoadingOverlay)?"show":"hide")}>
        <div className='loading-generate-world-overlay-inner'>
          <div className='loading-container'>
            <Loading show={showLoadingOverlay} size={80}/>
          </div>
          <div className='loading-text-container'>
            <div className='main'>Generating World</div>
            <ProgressBar duration={waitTime} show={showLoadingOverlay}/>
            <div className='sub'>Typically takes <span className='bold'>~{waitTime}</span> seconds</div>
          </div>
        </div>
      </div>
      <CarouselHeader className={"new-world-header"} slideTo={slide} forceResize={show}>
        <div onClick={() => {setSlide(SlideEnum.Template.Key)}}>{SlideEnum.Template.Display}</div>
        <div onClick={() => {setSlide(SlideEnum.Generate.Key)}}>{SlideEnum.Generate.Display}</div>
        <div onClick={() => {setSlide(SlideEnum.Prompt.Key)}}>{SlideEnum.Prompt.Display}</div>
        <div onClick={() => {setSlide(SlideEnum.Blank.Key)}}>{SlideEnum.Blank.Display}</div>
      </CarouselHeader>
      <Carousel slideTo={slide}>
        <div className='new-world-template-slide'>
          <h4>World Templates</h4>
          <p className='note'>Select a world template to use for your world.</p>
          <Table 
            database={"Worlds"}
            displayName="WorldTemplates"
            name="WorldTemplatesTable"
            sideItems={sideItems}
            sideItemActiveClickFunc={(item) => {
              // console.log("item",item);
            }}
            joinOptions={joinOptions}
            // rowOnClickFunc={(value) => {
            //   setOpenWorldUid(value.worldsUid);
            // }}
            useHeaderRow={false}
            // refreshInc={refreshInc}
            columns={columns}
            limit={100}
            disableRowHighlight={true}
            useSearchBar={true}
            returnResultsFunc={(results,loading) => {
              // console.log("loading",loading);
              // console.log("results",results);
              // setInitLoading(loading);
              // setResults(results);
            }}
            />
        </div>
        <div className='new-world-slide'>
          <h4>Generate New World</h4>
          <p className='note'>Generate a new world from scratch. </p>
          <form onSubmit={handleSubmit}>
            {Object.keys(worldOptions).map((key, i) => {
              let item = worldOptions[key];
              item.sort();
              return(
                <div className="label-item-input" key={i}>
                  <div className='input-box'>
                    <label>{StringUtil.prettifyCamelCase(key)}</label>
                    <div className='spacer'></div>
                    <div className='inputs'>
                      <select 
                        value={values[key]?values[key]:""} 
                        onChange={(e) => {
                          let newValues = clone(values);
                          newValues[key] = e.target.value;
                          setValues(newValues);
                        }}
                      >
                        <option value="unspecified">Unspecified</option>
                        <option value="random">Random</option>
                        <option disabled>---</option>
                        {item.map((v2,i2) => {
                          return(
                            <option key={i2}>{v2}</option>
                          )
                        })}
                        <option disabled>---</option>
                        <option value="custom">Custom</option>
                      </select>
                      {(values[key] && values[key] === "custom"?
                        <div className='custom-input-box'>
                          <input className='custom-input' type="text" 
                            placeholder={"Custom "+StringUtil.prettifyCamelCase(key)+" Placeholder"}
                            value={values[key+"-custom"]?values[key+"-custom"]:""} 
                            onChange={(e) => {
                              let newValues = clone(values);
                              newValues[key+"-custom"] = e.target.value;
                              setValues(newValues);
                            }}/>
                        </div>
                      :null)}
                    </div>
                  </div>
                </div>
              )
            })}

            <SwitchToggle 
                isOn={toggle} onClickFunc={() => {setToggle(!toggle)}}
                textOn={"Use Advanced Settings On"}
                textOff={"Use Advanced Settings Off"}
                width={240}
                height={48}
                divisor={12}
                borderRadius={5}
                />


            {toggle === false?null:Object.keys(advancedWorldOptions).map((key, i) => {
              let item = advancedWorldOptions[key];
              item.sort();

              return(
                <div className="label-item-input" key={i}>
                  <div className='input-box'>
                    <label>{StringUtil.prettifyCamelCase(key)}</label>
                    <div className='spacer'></div>
                    <div className='inputs'>
                      <select 
                        value={values[key]?values[key]:""} 
                        onChange={(e) => {
                          let newValues = clone(values);
                          newValues[key] = e.target.value;
                          setValues(newValues);
                        }}
                      >
                        <option value="unspecified">Unspecified</option>
                        <option value="random">Random</option>
                        <option disabled>---</option>
                        {item.map((v2,i2) => {
                          return(
                            <option key={i2}>{v2}</option>
                          )
                        })}
                        <option disabled>---</option>
                        <option value="custom">Custom</option>
                      </select>
                      {(values[key] && values[key] === "custom"?
                        <div className='custom-input-box'>
                          <input className='custom-input' type="text" 
                            placeholder={"Custom "+StringUtil.prettifyCamelCase(key)+" Placeholder"}
                            value={values[key+"-custom"]?values[key+"-custom"]:""} 
                            onChange={(e) => {
                              let newValues = clone(values);
                              newValues[key+"-custom"] = e.target.value;
                              setValues(newValues);
                            }}/>
                        </div>
                      :null)}
                    </div>
                  </div>
                </div>
              )
            })}

          {toggle===false?null:
          <div>
            <label>Additional Details</label>
            <Textarea value={values["additionalDetails"]?values["additionalDetails"]:""}  onChange={(e) => {
              let newValues = clone(values);
              newValues["additionalDetails"] = e.target.value;
              setValues(newValues);
            }}></Textarea>
          </div>
          }
            <div className='buttons'>
              <div className='left'></div>
              <div className='right'>
                <div className='button-inputs'>
                  <select value={chatgptModel} onChange={(e) => {setChatgptModel(e.target.value)}}>
                    <option value="chatgpt-3.5">ChatGpt 3.5</option>
                    <option value="chatgpt-4.5">ChatGpt 4.5</option>
                  </select>
                  <CoinButton 
                    text="Generate World"
                    type="submit" 
                    loading={loading}
                    rightText={tokenCost}
                    tipText={("Uses "+tokenCost+" Text Tokens")}
                    />
                </div>
                <div className='note'>
                  <span>Typically takes <span className='bold'>~{waitTime}</span> seconds</span>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className='new-blank-slide'>
          <h4>Prompt Generate World</h4>
          <p className='note'>Write a prompt to generate a unique new world.</p>
          <form onSubmit={handleSubmit}>
            <div className='input-text-box-container'>
              <div className='input-text-box'>
                <label>Prompt</label>
                <div className='inputs'>
                  <Textarea value={prompt}  
                    placeholder='Prompt'
                    onChange={(e) => {
                    setPrompt(e.target.value);
                  }}></Textarea>
                </div>
              </div>
            </div>
            <div className='buttons'>
              <div className='left'></div>
              <div className='right'>
                <div className='button-inputs'>
                  <select value={chatgptModel} onChange={(e) => {setChatgptModel(e.target.value)}}>
                    <option value="chatgpt-3.5">ChatGpt 3.5</option>
                    <option value="chatgpt-4.5">ChatGpt 4.5</option>
                  </select>
                  <CoinButton 
                    text="Generate World"
                    type="submit" 
                    loading={loading}
                    rightText={tokenCost}
                    tipText={("Uses "+tokenCost+" Text Tokens")}
                    />
                </div>
                <div className='note'>
                  <span>Typically takes <span className='bold'>~{waitTime}</span> seconds</span>
                </div>
              </div>
            </div>
          </form>
        </div>
        
        <div className='new-blank-slide'>
          <h4>Generate Blank World</h4>
          <p className='note'>Generate a blank world and fill in the details yourself.</p>
          <form onSubmit={handleSubmit}>
            <div className='input-text-box-container'>
              <div className='input-text-box'>
                <label>World Name</label>
                <div className='inputs'>
                  <input placeholder='World Name' type="text" value={worldName} 
                    onChange={(e) => {setWorldName(e.target.value)}}
                    />
                </div>
              </div>
              {/* <div className='input-text-box'>
                <label>World Backstory</label>
                <div className='inputs'>
                  <Textarea type="text" value={worldBackstory} onChange={(e) => {setWorldBackstory(e.target.value)}}/>
                  <div className='input-buttons'>
                    <Button status={"undo"}></Button>
                    <Button status={"shuffle"}></Button>
                  </div>
                </div>
              </div> */}
            </div>
            <div className='buttons'>
              <div className='left'></div>
              <div className='right'>
                <CoinButton 
                  text="Generate Blank World"
                  type="submit" 
                  loading={loading}
                  rightText={"0"}
                  tipText="This will not use tokens"
                  />
              </div>
            </div>
          </form>
        </div>
      </Carousel>
    </div>
  );

};

export default NewWorld;