
const isObject = function (o) {
  return o === Object(o) && !isArray(o) && typeof o !== 'function';
};

const isArray = function (a) {
  return Array.isArray(a);
};

const toCamelCase = (s) => {
  return s.replace(/([-_][a-z])/ig, ($1) => {
    return $1.toUpperCase()
      .replace('-', '')
      .replace('_', '');
  });
};

const toSnakeCase = (s) => {  
  return s.split(/(?=[A-Z])/).join('_').toLowerCase();
};

export const toCamelCaseKeys = function (o) {
  if (isObject(o)) {
    const n = {};

    Object.keys(o)
      .forEach((k) => {
        n[toCamelCase(k)] = toCamelCaseKeys(o[k]);
      });

    return n;
  } else if (isArray(o)) {
    return o.map((i) => {
      return toCamelCaseKeys(i);
    });
  }

  return o;
};

export const toSnakeCaseKeys = function (o) {
  if (isObject(o)) {
    const n = {};

    Object.keys(o)
      .forEach((k) => {
        n[toSnakeCase(k)] = toSnakeCaseKeys(o[k]);
      });

    return n;
  } else if (isArray(o)) {
    return o.map((i) => {
      return toSnakeCaseKeys(i);
    });
  }

  return o;
};

export const prettifyTime = (obj) => {
  if(obj === undefined){return "";}
  let d = new Date(obj);
  const hour = new Intl.DateTimeFormat('en', { hour: 'numeric', minute: 'numeric', second: 'numeric', }).format(d)
  return `${hour}`;
}

export const reverseObject = (object) => {
  var newObject = {};
  var keys = [];

  for (var key in object) {
      keys.push(key);
  }

  for (var i = keys.length - 1; i >= 0; i--) {
    var value = object[keys[i]];
    newObject[keys[i]]= value;
  }       

  return newObject;
}

/* Randomize array in-place using Durstenfeld shuffle algorithm */
export const shuffleArray = (array) => {
  for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
  }
}

