import React,{useEffect, useState,} from 'react';
import './youtube-video-viewer.scss'
import { toast } from 'react-toastify';
import { request } from '../../../utils/request';

const YoutubeVideoViewer = ({uid,autoPlay,videoRef,onTimeUpdate}) => {

  const [loading,setLoading] = useState(false);
  const [textVal,setTextVal] = useState("");

  useEffect(() => {
    if(uid){
      fetchVideo(uid);
    }else{
      setTextVal("");
    }
  },[uid])

  const fetchVideo = (uid,) => {
    setLoading(true);
    request("video-viewer","/youtube-video-viewer/"+uid,"GET", {}, {
      then: function(res){
        // console.log("res",res);
        let textVal = "";
        if(res.data.res.textVal){
          textVal = res.data.res.textVal;
        }
        setTextVal(textVal);
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false);}
    });
  }

  // console.log("YoutubeVideoViewer uid",uid)
  return (
    <div className={'hlo youtube-video-viewer'}>
      {(loading)?
        <div className='loading-item loading-item-flow'></div>
      :((textVal !== "")?
        <div className='video-viewer-sub'>
          <div className='text-val'>
          {/* width="560" height="315"  */}
            <iframe 
              src={textVal} 
              title="YouTube video player" 
              frameBorder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
              allowFullScreen></iframe>
          </div>
        </div>
      :<div className='no-video'><span>No Video</span></div>)}
    </div>
  )
}

export default YoutubeVideoViewer;