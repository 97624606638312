import React from 'react';
import './content-quiz.scss';
import Button from '../../elements/button/button';
import {clone} from '../../../utils/clone';
import {toast} from 'react-toastify'
import SwitchToggle from '../../elements/switch-toggle/switch-toggle';

const ContentQuiz = ({i,i2,content, sections, setSections}) => {

  return (
    <div className='content-quiz-comp'>
      <div className='quiz-header'>
        <h4>Questions</h4>
        <div>
          <Button type="button" status="add" tabIndex="-1" onClick={() => {
            let cloneSections = clone(sections);
            if(!cloneSections[i].contents[i2].questions){
              cloneSections[i].contents[i2].questions = [];
            }
            cloneSections[i].update = true;
            cloneSections[i].contents[i2].update = true;
            cloneSections[i].contents[i2].questions.push({
              update:true,
              questionText: "",
              showAllOfAbove: false,
              allOfAboveCorrect: false,
              showNoneOfAbove: false,
              noneOfAboveCorrect: false,
              answers:[],
            })
            setSections(cloneSections);
          }}>New Question</Button>
        </div>
      </div>
      

      <div className='questions'>
      {content && content.questions && content.questions.length?
        content.questions.map((v3,i3) => {
          let question = v3;
          if(question.delete) return null;
          return(
            <div className='question' key={i3}>
              <div className='left'>
                <Button status="up" disabled={i3 === 0} tabIndex="-1" onClick={() => {
                  let cloneSections = clone(sections);
                  question.update = true;
                  cloneSections[i].update = true;
                  cloneSections[i].contents[i2].update = true;
                  if(cloneSections[i].contents[i2].questions[i3-1] !== undefined){ cloneSections[i].contents[i2].questions[i3-1].update = true; }
                  cloneSections[i].contents[i2].questions.splice(i3, 1);
                  cloneSections[i].contents[i2].questions.splice(i3-1, 0, question);
                  setSections(cloneSections);
                }}>up</Button>
                <div className='num'>{i3+1}</div>
                <Button status="down" disabled={i3 === sections[i].contents[i2].questions.length-1} tabIndex="-1" onClick={() => {
                  let cloneSections = clone(sections);
                  question.update = true;
                  cloneSections[i].update = true;
                  cloneSections[i].contents[i2].update = true;
                  if(cloneSections[i].contents[i2].questions[i3+1] !== undefined){ cloneSections[i].contents[i2].questions[i3+1].update = true; }
                  cloneSections[i].contents[i2].questions.splice(i3, 1);
                  cloneSections[i].contents[i2].questions.splice(i3+1, 0, question);
                  setSections(cloneSections);
                }}>down</Button>
              </div>
              <div className='center'>
                <input type="text" placeholder='Question' value={v3.questionText} onChange={(e) => {
                  let cloneSections = clone(sections);
                  cloneSections[i].update = true;
                  cloneSections[i].contents[i2].update = true;
                  cloneSections[i].contents[i2].questions[i3].update = true;
                  cloneSections[i].contents[i2].questions[i3].questionText = e.target.value;
                  setSections(cloneSections);
                }}/>
                <div className='answers'>
                  {v3.answers.map((v4,i4) => {
                  if(v4.delete){ return null;}
                  return(
                    <div className='answer' key={i4}>
                      <Button parentClassName={"checkmark "+((v4.correct)?"":"invert")} status='check' tabIndex="-1" onClick={() => {
                        let cloneSections = clone(sections);
                        cloneSections[i].update = true;
                        cloneSections[i].contents[i2].update = true;
                        cloneSections[i].contents[i2].questions[i3].update = true;
                        cloneSections[i].contents[i2].questions[i3].answers[i4].update = true;
                        cloneSections[i].contents[i2].questions[i3].answers[i4].correct = true;
                        setSections(cloneSections);
                      }}>Correct</Button>
                      <Button parentClassName={"wrongmark "+((v4.correct)?"invert":"")} status='close' tabIndex="-1" onClick={() => {
                        let cloneSections = clone(sections);
                        cloneSections[i].update = true;
                        cloneSections[i].contents[i2].update = true;
                        cloneSections[i].contents[i2].questions[i3].update = true;
                        cloneSections[i].contents[i2].questions[i3].answers[i4].update = true;
                        cloneSections[i].contents[i2].questions[i3].answers[i4].correct = false;
                        setSections(cloneSections);
                      }}>Incorrect</Button>
                      <input type="text" value={v4.text} onChange={(e) => {
                        let cloneSections = clone(sections);
                        cloneSections[i].update = true;
                        cloneSections[i].contents[i2].update = true;
                        cloneSections[i].contents[i2].questions[i3].update = true;
                        cloneSections[i].contents[i2].questions[i3].answers[i4].update = true;
                        cloneSections[i].contents[i2].questions[i3].answers[i4].text = e.target.value;
                        setSections(cloneSections);
                      }} placeholder='Answer' />
                      <Button parentClassName={"delete"} status='delete' tabIndex="-1" onClick={() => {
                        let cloneSections = clone(sections);
                        if(cloneSections[i].contents[i2].questions[i3].answers[i4].uid !== undefined){
                          cloneSections[i].update = true;
                          cloneSections[i].contents[i2].update = true;
                          cloneSections[i].contents[i2].questions[i3].update = true;
                          cloneSections[i].contents[i2].questions[i3].answers[i4].update = true;
                          cloneSections[i].contents[i2].questions[i3].answers[i4].delete = true;
                          toast.info("Marked Answer for Deletion");
                        }else{
                          cloneSections[i].contents[i2].questions[i3].answers.splice(i4, 1);
                          toast.info("Deleted Answer");
                        }
                        setSections(cloneSections);
                      }}>Delete</Button>
                    </div>
                  )
                })}
                </div>
                <div className='buttons'>
                  <div className='left'>
                    <div>
                      <Button parentClassName={"checkmark "+((question.allOfAboveCorrect)?"":"invert")} status='check' 
                        tabIndex="-1"
                        disabled={!question.allOfAbove}
                        onClick={() => {
                          let cloneSections = clone(sections);
                          cloneSections[i].update = true;
                          cloneSections[i].contents[i2].update = true;
                          cloneSections[i].contents[i2].questions[i3].update = true;
                          cloneSections[i].contents[i2].questions[i3].allOfAboveCorrect = true;
                          setSections(cloneSections);
                      }}>Correct</Button>
                      <Button parentClassName={"wrongmark "+((question.allOfAboveCorrect)?"invert":"")} status='close' 
                        tabIndex="-1"
                        disabled={!question.allOfAbove}
                        onClick={() => {
                          let cloneSections = clone(sections);
                          cloneSections[i].update = true;
                          cloneSections[i].contents[i2].update = true;
                          cloneSections[i].contents[i2].questions[i3].update = true;
                          cloneSections[i].contents[i2].questions[i3].allOfAboveCorrect = false;
                          setSections(cloneSections);
                      }}>Incorrect</Button>
                      <div className='static-answer'>
                        <label>All of the Above</label>
                        <SwitchToggle isOn={question.allOfAbove} onClickFunc={() => {
                          let cloneSections = clone(sections);
                          cloneSections[i].update = true;
                          cloneSections[i].contents[i2].update = true;
                          cloneSections[i].contents[i2].questions[i3].update = true;
                          cloneSections[i].contents[i2].questions[i3].allOfAbove = !question.allOfAbove;
                          setSections(cloneSections);
                        }}/>
                      </div>
                    </div>
                    <div>
                      <Button parentClassName={"checkmark "+((question.noneOfAboveCorrect)?"":"invert")} status='check' 
                        tabIndex="-1"
                        disabled={!question.noneOfAbove}
                        onClick={() => {
                          let cloneSections = clone(sections);
                          cloneSections[i].update = true;
                          cloneSections[i].contents[i2].update = true;
                          cloneSections[i].contents[i2].questions[i3].update = true;
                          cloneSections[i].contents[i2].questions[i3].noneOfAboveCorrect = true;
                          setSections(cloneSections);
                      }}>Correct</Button>
                      <Button parentClassName={"wrongmark "+((question.noneOfAboveCorrect)?"invert":"")} status='close'
                        tabIndex="-1"
                        disabled={!question.noneOfAbove}
                        onClick={() => {
                          let cloneSections = clone(sections);
                          cloneSections[i].update = true;
                          cloneSections[i].contents[i2].update = true;
                          cloneSections[i].contents[i2].questions[i3].update = true;
                          cloneSections[i].contents[i2].questions[i3].noneOfAboveCorrect = false;
                          setSections(cloneSections);
                      }}>Incorrect</Button>
                      <div className='static-answer'>
                        <label>None of the Above</label>
                        <SwitchToggle isOn={question.noneOfAbove} onClickFunc={() => {
                          let cloneSections = clone(sections);
                          cloneSections[i].update = true;
                          cloneSections[i].contents[i2].update = true;
                          cloneSections[i].contents[i2].questions[i3].update = true;
                          cloneSections[i].contents[i2].questions[i3].noneOfAbove = !question.noneOfAbove;
                          setSections(cloneSections);
                        }}/>
                      </div>
                    </div>
                  </div>
                  <div className='right'>
                    <Button type="button" status="add" tabIndex="-1" onClick={() => {
                      let cloneSections = clone(sections);
                      cloneSections[i].contents[i2].questions[i3].answers.push({
                        text:"",
                        correct: false,
                      });
                      setSections(cloneSections);
                    }}>Add Answer</Button>
                  </div>
                  
                </div>
                
              </div>
              <div className='right'>

                {/* <Button parentClassName={question.edit?"check":"edit"} status={question.edit?"edit":"check"} tabIndex="-1" onClick={() => {
                  let cloneSections = clone(sections);
                  cloneSections[i].contents[i2].questions[i3].edit = !question.edit; 
                  setSections(cloneSections);
                }}>{(question.edit)?"edit":"check"}</Button> */}

                <Button parentClassName={"delete"} status='delete' onClick={() => {
                  let cloneSections = clone(sections);
                  if(cloneSections[i].contents[i2].questions[i3].uid !== undefined){
                    cloneSections[i].update = true;
                    cloneSections[i].contents[i2].update = true;
                    cloneSections[i].contents[i2].questions[i3].delete = true;
                    toast.info("Marked Question for Deletion");
                  }else{
                    cloneSections[i].contents[i2].questions.splice(i3, 1);
                    toast.info("Deleted Question");
                  }
                  setSections(cloneSections);
                }}>Delete</Button>
              </div>
            </div>
          )
        })
      :null}
      </div>
      
      
    </div>
  );

};

export default ContentQuiz;