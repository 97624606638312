import React from 'react';
import './course-section.scss';
import ReactTextareaAutosize from 'react-textarea-autosize';
import Button from '../../elements/button/button';
import {clone} from '../../../utils/clone';
import {toast} from 'react-toastify'
import { ContentTypeEnum } from '../../../enums/content-type';
import CourseContent from './course-content';

const CourseSection = ({uid,v,i,sections,setSections,loading,edit}) => {

  return (
    <div className={"course-section-comp"}>
      <div className='left'>
        <Button status={(loading)?"loading":"up"} disabled={i === 0 || loading || !edit} onClick={() => {
          let cloneSections = clone(sections);
          v.update = true;
          if(cloneSections[i-1] !== undefined){ cloneSections[i-1].update = true; }
          cloneSections.splice(i, 1);
          cloneSections.splice(i - 1, 0, v);
          setSections(cloneSections);
        }}>up</Button>
        <div className='num'>{i+1}</div>
        <Button status={(loading)?"loading":"down"} disabled={(i === sections.length-1) || loading || !edit} onClick={() => {
          let cloneSections = clone(sections);
          v.update = true;
          if(cloneSections[i+1] !== undefined){ cloneSections[i+1].update = true; }
          cloneSections.splice(i, 1);
          cloneSections.splice(i + 1, 0, v);
          setSections(cloneSections);
        }}>down</Button>
      </div>

      <div className={'center '+((v.edit)?"editting":"not-editting")}>
        <div>
          <input type="text" placeholder='Section Title' value={v.title} disabled={!v.edit || loading} onChange={(e) => {
            let cloneSections = clone(sections);
            cloneSections[i].title = e.target.value;
            cloneSections[i].update = true;
            setSections(cloneSections);
          }} />
        </div>
        <div>
          <ReactTextareaAutosize placeholder='Section Description'  value={v.desc} disabled={!v.edit || loading} onChange={(e) => {
            let cloneSections = clone(sections);
            cloneSections[i].desc = e.target.value;
            cloneSections[i].update = true;
            setSections(cloneSections);
          }} ></ReactTextareaAutosize>
        </div>
        <div className='section-content'>
          {(v.edit)?
          <div className='section-content-header'>
            <div className='left'>
              <h4>Section Content</h4>
              <p className='note'>Videos, Quizzes, Challenges</p>
            </div>
            <div className='right'><Button status="add" tabIndex="-1" onClick={() => {
              let cloneSections = clone(sections);
              cloneSections[i].contents.push({
                type: ContentTypeEnum.Video,
                edit: true,
                update: true,
              })
              setSections(cloneSections);
            }}>New Content</Button></div>
          </div>
          :null}
          
          <div className='section-content-list'>
          {sections[i].contents.map((v2,i2) => {
            if(v2.delete) return null;
            return(
              <CourseContent uid={uid} key={i2} v={v} i={i} i2={i2} sections={sections} setSections={setSections} loading={loading} />
            )})}
          </div>
        </div>
      </div>

      <div className='right'>
        {(!v.edit)?<Button status={(loading)?"loading":"edit"} disabled={!edit} onClick={() => {
          let cloneSections = clone(sections);
          cloneSections[i].edit = true;
          setSections(cloneSections);
        }}>edit</Button>:null}
        {(v.edit)?<Button status={(loading)?"loading":"check"} disabled={!edit} onClick={() => {
          let cloneSections = clone(sections);
          cloneSections[i].edit = false;
          setSections(cloneSections);
        }}>done</Button>:null}
        <Button parentClassName="delete" status={(loading)?"loading":"delete"} disabled={!edit} onClick={() => {
          let cloneSections = clone(sections);
          if(cloneSections[i].uid !== undefined){
            cloneSections[i].update = true;
            cloneSections[i].delete = true;
            toast.info("Marked Section for Deletion");
          }else{
            cloneSections.splice(i, 1);
            toast.info("Section Deleted");
          }
          setSections(cloneSections);
        }}>delete</Button>
      </div>
    </div>
  );

};

export default CourseSection;