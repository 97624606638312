import React, {useState} from 'react';
import './content-challenge.scss';
import ReactTextareaAutosize from 'react-textarea-autosize';
import {clone} from '../../../utils/clone';
import CodeEditor from '@uiw/react-textarea-code-editor';

const defaultCodeTemplate = 
`() => {
  const sum = (a,b) => { 
    return a+b;
  }

  return {
    sum,
  }
}`;
const defaultOutput = 
`let d = userInputObject; 
let pass = false;
let errorMsg = "Did not pass";
try{
  if(d.sum !== undefined && typeof d.sum === 'function'){
    if(
      d.sum(1,1) === 2 &&
      d.sum(1,2) === 3 &&
      d.sum(3,1) === 4 &&
      d.sum(3,2) === 5 
    ){ 
      pass = true; 
      errorMsg = "No problems";
    }else{
      errorMsg = "Make sure your function is returning the result and that it's correct";
    }
  }else{
    errorMsg = 'Make sure your function is named "sum".';
  }
}catch(e){
  errorMsg = 'Make sure your function is named "sum".';
}`;

const ContentChallenge = ({i,i2,sections,setSections}) => {
  let content = sections[i] && sections[i].contents[i2]?sections[i].contents[i2]:null;

  const [codeTemplate,setCodeTemplate] = useState(content && content.codeTemplate?content.codeTemplate:defaultCodeTemplate);
  const [expectedResult,setExpectedResult] = useState(content && content.expectedResult?content.expectedResult:defaultOutput);

  return (
    <div className="content-challenge-comp">
      <div>
        <label>Challenge Instructions</label>
        <p className='note'>Describe what you'd like the student to do</p>
        <ReactTextareaAutosize value={content && content.instructions?content.instructions:""} onChange={(e) => {
          let cloneSections = clone(sections);
          if(cloneSections && cloneSections[i] && cloneSections[i].contents && cloneSections[i].contents[i2]){
            cloneSections[i].update = true;
            cloneSections[i].contents[i2].update = true;
            cloneSections[i].contents[i2].instructions = e.target.value;
          }
          setSections(cloneSections);
        }}></ReactTextareaAutosize>
      </div>
      <div>
        <label>Code Template</label>
        <p className='note'>Give the student a base template of code to start off with</p>
        <CodeEditor
          value={codeTemplate}
          language="js"
          placeholder="Please enter JS code."
          onChange={(evn) => {
            let newCode = evn.target.value;
            let cloneSections = clone(sections);
            if(cloneSections && cloneSections[i] && cloneSections[i].contents && cloneSections[i].contents[i2]){
              cloneSections[i].contents[i2].codeTemplate = newCode;
              cloneSections[i].update = true;
              cloneSections[i].contents[i2].update = true;
            }
            setSections(cloneSections);
            setCodeTemplate(newCode);
          }}
          padding={12}
          style={{
            fontSize: 12,
            backgroundColor: "#f5f5f5",
            fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
          }}
        />
      </div>
      <div>
        <label>Expected Result</label>
        <p className='note'>Set "pass" to true if they pass & use "errorMsg" to let the user know why something failed. </p>
        <CodeEditor
          value={expectedResult}
          language="js"
          placeholder="Please enter JS code."
          onChange={(evn) => {
            let newCode = evn.target.value;
            let cloneSections = clone(sections);
            if(cloneSections && cloneSections[i] && cloneSections[i].contents && cloneSections[i].contents[i2]){
              cloneSections[i].contents[i2].expectedResult = newCode;
              cloneSections[i].update = true;
              cloneSections[i].contents[i2].update = true;
            }
            setSections(cloneSections);
            setExpectedResult(newCode);
          }}
          padding={12}
          style={{
            fontSize: 12,
            backgroundColor: "#f5f5f5",
            fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
          }}
        />
      </div>

    </div>
  );

};

export default ContentChallenge;