import React,{useState,useEffect} from 'react';
import './world-builder-new-image-modal.scss';
import Carousel from '../../common/carousel/carousel';
import * as StringUtil from '../../../utils/string';
import Textarea from 'react-textarea-autosize';
import Button from '../../elements/button/button';
import CoinButton from '../../elements/button/coin-button';
import Modal from '../../common/modal/modal';
import { clone } from '../../../utils/clone';
import CarouselHeader from '../../common/carousel/carousel-header';
import { toast } from 'react-toastify';
import { request,unSubRequest } from '../../../utils/request';
import SwitchToggle from '../../elements/switch-toggle/switch-toggle';
// import * as ShortUuid from 'short-uuid';

let textareaVals = [
  "appearance","backstory","beliefs","traditions","socialNorms",
  "features","plot influence","description","traits",
  "system","function","limitations","society impact",
  "lifespan","habitat","practices","influence","plotInfluence",
  "imageScene","description",
];

let showVals = [
  "name","appearance","backstory","practices","influence","features",
  "plotInfluence","beliefs","traditions","socialNorms","imageScene",
  "description","habitat",
];

// let tables = {
//   characters:{
//     name:"Character",table:"characters",
//     columns:[`name`,`appearance`,`archetype`,`traits`,`backstory`],
//   },
//   locations:{
//     name:"Location",table:"locations",
//     columns:[`name`,`features`,`plotInfluence`], 
//   },
//   cultures:{
//     name:"Culture",table:"cultures",
//     columns:[`name`,`beliefs`,`traditions`,`socialNorms`,`backstory`], 
//   },
//   species:{
//     name:"Species",table:"species",
//     columns:[`name`,`appearance`,`lifespan`,`habitat`,`backstory`], 
//   },
//   conflicts:{
//     name:"Conflict",table:"conflicts",
//     columns:[`type`,`description`], 
//   },
//   religions:{
//     name:"Religion",table:"religions",
//     columns:[`name`,`practices`,`influence`], 
//   },
//   religionGods:{
//     name:"Relgion Diety",table:"religion_gods",
//     columns:[`name`,`appearance`,`traits`,`backstory`], 
//   },
// }

const WorldBuilderNewImageModal = ({show,setShow,table,values,world,}) => {

  const [loading,setLoading] = useState(false);
  const [modalSlide,setModalSlide] = useState(1);
  const [localValues,setLocalValues] = useState([]);
  const [textPrompt,setTextPrompt] = useState("");
  const [prompt,setPrompt] = useState("");
  const [url,setUrl] = useState("");
  const [imageNum,setImageNum] = useState("1");
  const [tokenCost,setTokenCost] = useState(1);
  const [imageScene,setImageScene] = useState("");
  const [useImageScene,setUseImageScene] = useState(false);

  useEffect(() => {
    let newValues = clone(values);
    setLocalValues(newValues);
  },[values]);

  useEffect(() => {
    let newTokenCost = 1;
    if(imageNum === "4"){
      newTokenCost = 2;
    }
    setTokenCost(newTokenCost)
  },[imageNum]);

  useEffect(() => {
    let newPrompt = "";
    if(table === "worlds"){
      try{
        newPrompt = world.lastPrompt;
      }catch(e){console.log("e",e);}
    }else if(table in values){
      try{
        let foo = values[table][table+"LastPrompt"];
        newPrompt = foo;
      }catch(e){console.log("e",e);}
    }
    setPrompt(newPrompt);
  },[table,values,world,]);

  
  const handleGenPromptSubmit = (e) => {
    e.preventDefault();
    generateImagePrompt();
  }

  const generateImagePrompt = () => {
    let body = {prompt: textPrompt,}
    setLoading(true);
    request("generate-image-prompt","/world-item/generate-image-prompt","POST", body, {
      then: function(res){
        let message = res.data.res.message;
        setPrompt(message);
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false);}
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    let passValues = localValues[table];
    if(table === "worlds"){
      passValues = world;
    }
    generateItems(modalSlide,table,passValues,prompt);
  }

  const generateItems = (modalSlide,table,values,prompt) => {

    // const genItemUid = ShortUuid.generate();
    const genItemUid = "holdUid";
    const customPrompt = modalSlide === 2?true:false;
    const customUrl = modalSlide === 3?true:false;

    values = clone(values);

    if(table === "worlds"){
      if("worldSettings" in values){
        let worldSettings = clone(values.worldSettings);
        let fixValues = [];
        for(let key in worldSettings){
          if(key && key.includes("-custom")){fixValues.push(key);}
        }
        for (let i = 0; i < fixValues.length; i++) {
          const fixValue = fixValues[i];
          let newValue = fixValue.replace("-custom","");
          worldSettings[newValue] = clone(worldSettings[fixValue]);
          delete worldSettings[fixValue];
        }
        values["worldSettings"] = worldSettings;
      }
    }else{
      let deleteKeys = [];
      for(let key in values){
        let newKey = key.replace(table,"");
        newKey = StringUtil.pascalCaseToCamelCase(newKey);
        deleteKeys.push(key);
        // if(newKey === "createdDate" || newKey.includes("image")){continue;}
        if(showVals.indexOf(newKey) === -1 && newKey !== "uid"){continue;}
        values[newKey] = values[key];
      }
      for (let i = 0; i < deleteKeys.length; i++) {
        const deleteKey = deleteKeys[i];
        delete values[deleteKey];
      }
    }

    if(useImageScene){
      values.imageScene = imageScene;
    }

    let name = "name null";
    if("name" in values){
      name = values['name'];
    }else if("type" in values){
      name = values['type'];
    }
    

    let body = {
      imageNum,
      name,
      table,
      values,
      prompt,
      url,
      customPrompt,
      customUrl,
      genItemUid,
    }
    
    setLoading(true);
    request("generate-images-"+genItemUid,"/world-item/generate-images","POST", body, {
      then: function(res){
        if(modalSlide === 3){
          toast.success("Image Uploading");
        }else{
          toast.success("Image Gen added to Queue");
        }
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){
        setLoading(false)
      }
    });
  }

  return (
    <Modal className={"world-builder-new-image-modal"} show={show} handleClose={() => {setShow(false)}}>
      <div>
        <CarouselHeader slideTo={modalSlide} forceResize={show}>
          <div onClick={() => {setModalSlide(1)}}>Details</div>
          <div onClick={() => {setModalSlide(2)}}>Custom Prompt</div>
          <div onClick={() => {setModalSlide(3)}}>Direct Link</div>
        </CarouselHeader>
        <Carousel slideTo={modalSlide}>
          <div className='world-builder-image-modal-content'>
            <form onSubmit={handleSubmit}>
              <p>Generate an image based off of the following details</p>
              {table === "worlds"?
                <>
                  <div>
                    <label>Name</label>
                    <Textarea value={world && world.name?world.name:""} disabled={true}></Textarea>
                  </div>
                  <div>
                    <label>Backstory</label>
                    <Textarea value={world && world.backstory?world.backstory:""} disabled={true}></Textarea>
                  </div>
                </>
                
              :null}
              {table !== "worlds" && localValues && localValues[table]?
              Object.keys(localValues[table]).map((keyName, i) => {
                let label = keyName.replace(table,"");
                let key = StringUtil.pascalCaseToCamelCase(label);
                // console.log("key",key);
                // if(label.includes("Uid") || label.includes("Date") || label.includes("Image") || label.includes("Prompt")){return null;}
                if(showVals.indexOf(key) === -1){return null;}
                label = StringUtil.prettifyCamelCase(label).trim();
                let val = localValues[table][keyName]
                return(
                  <div key={i}>
                    <label>{label}</label>
                    {(textareaVals.indexOf(key) !== -1)?
                      <Textarea key={table+"-"+i} value={val} disabled={loading} onChange={(e) => {
                        let newLocalValues = clone(localValues);
                        newLocalValues[table][keyName] = e.target.value;
                        setLocalValues(newLocalValues);
                      }}></Textarea>
                    :
                      <input key={table+"-"+i} type="text" disabled={loading} value={val} onChange={(e) => {
                        let newLocalValues = clone(localValues);
                        newLocalValues[table][keyName] = e.target.value;
                        setLocalValues(newLocalValues);
                      }}/>
                    }
                  </div>
                )
              }):null}
              <div className='image-scene-box'>
                <div className='label-header'>
                  <label>Image Scene</label>
                  <SwitchToggle isOn={useImageScene} onClickFunc={() => {
                      setUseImageScene(!useImageScene)
                    }}
                    />
                </div>
                {useImageScene?
                <Textarea value={imageScene} 
                  placeholder='Image Scene i.e. Extra details about the background and setting of the image.'
                  disabled={loading} onChange={(e) => {
                  setImageScene(e.target.value);
                }}></Textarea>:null}
              </div>

              <div className='buttons'>
                <div className='left'></div>
                <div className='right'>
                  <select value={imageNum} onChange={(e) => {setImageNum(e.target.value)}}>
                    <option value="1">1 Image (Uses 1 Image Token)</option>
                    <option value="4">4 Images (Uses 2 Image Tokens)</option>
                  </select>
                  <CoinButton 
                    text="Generate Image"
                    type="submit" 
                    loading={loading}
                    rightText={tokenCost}
                    tipText={"Uses "+tokenCost+" Image Tokens"}
                    />
                </div>
              </div>
            </form>
          </div>
          <div className='world-builder-image-modal-content'>
            <form onSubmit={handleGenPromptSubmit}>
              <label>Generate Image Prompt</label>
              <Textarea value={textPrompt} onChange={(e) => {setTextPrompt(e.target.value)}}></Textarea>
              <div className='buttons'>
                <div className='left'></div>
                <div className='right'>
                  <CoinButton 
                    text="Generate Image Prompt"
                    type="submit" 
                    loading={loading}
                    rightText={1}
                    tipText={"Uses 1 Text Token"}
                    />
                </div>
              </div>
            </form>
            <form onSubmit={handleSubmit}>
              <label>Custom Image Prompt</label>
              <Textarea value={prompt} onChange={(e) => {setPrompt(e.target.value)}}></Textarea>
              <div className='buttons'>
                <div className='left'></div>
                <div className='right'>
                  <select value={imageNum} onChange={(e) => {setImageNum(e.target.value)}}>
                    <option value="1">1 Image (Uses 1 Image Token)</option>
                    <option value="4">4 Images (Uses 2 Image Tokens)</option>
                  </select>
                  <CoinButton 
                    text="Generate Image"
                    type="submit" 
                    loading={loading}
                    rightText={tokenCost}
                    tipText={"Uses "+tokenCost+" Image Tokens"}
                    />
                </div>
              </div>
            </form>
          </div>
          <div className='world-builder-image-modal-content'>
            <form onSubmit={handleSubmit}>
              <label>Image Url</label>
              <input type="text" value={url} onChange={(e) => {setUrl(e.target.value)}} />
              <div className='buttons'>
                <div className='left'></div>
                <div className='right'>
                  <Button status={loading?"loading":"save"} type="submit" value="Save" />
                </div>
              </div>
            </form>
          </div>
        </Carousel>
      </div>
    </Modal>
  );
};

export default WorldBuilderNewImageModal;