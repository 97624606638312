import React from 'react';
import './course-content.scss';
import Button from '../../elements/button/button';
import {clone} from '../../../utils/clone';
import * as StringUtil from '../../../utils/string';
import {toast} from 'react-toastify'
import { ContentTypeEnum } from '../../../enums/content-type';
import ContentVideo from './content-video';
import ContentQuiz from './content-quiz';
import ContentChallenge from './content-challenge';

const CourseContent = ({uid,v,i,i2,sections,setSections,loading}) => {
  let content = sections[i].contents[i2];

  if(!v.edit){
    return(
      <div>
        • {StringUtil.capitalize(content.type)} - {content.title}
      </div>
    )
  }

  return (
    <div className={"course-content-comp "+((content.edit?"edit":""))}>
      <div className='left'>
        <div className='up-down-block'>
          <Button status={(loading)?"loading":"up"} disabled={(i2 === 0)} tabIndex="-1" onClick={() => {
            let cloneSections = clone(sections);
            cloneSections[i].update = true; 
            content.update = true;
            if(cloneSections[i].contents[i2-1] !== undefined){ cloneSections[i].contents[i2-1].update = true; }
            cloneSections[i].contents.splice(i2, 1);
            cloneSections[i].contents.splice(i2-1, 0, content);
            setSections(cloneSections);
          }}>up</Button>
          <div className='num'>{i2+1}</div>
          <Button status={(loading)?"loading":"down"} disabled={(i2 === sections[i].contents.length-1)} tabIndex="-1" onClick={() => {
            let cloneSections = clone(sections);
            cloneSections[i].update = true; 
            content.update = true;
            if(cloneSections[i].contents[i2+1] !== undefined){ cloneSections[i].contents[i2+1].update = true; }
            cloneSections[i].contents.splice(i2, 1);
            cloneSections[i].contents.splice(i2+1, 0, content);
            setSections(cloneSections);
          }}>down</Button>
        </div>
        
      </div>
      <div className='center'>
        <div className={'content-header '+((content.edit)?"edit":"")}>
          <input disabled={loading || !content.edit} placeholder='Content Title' type="text" value={content && content.title?content.title:""} onChange={(e) => {
            let cloneSections = clone(sections);
            cloneSections[i].update = true;
            cloneSections[i].contents[i2].title = e.target.value;
            cloneSections[i].contents[i2].update = true;
            setSections(cloneSections);
          }} />
          <select disabled={loading || content.uid !== undefined || !content.edit} value={content && content.type?content.type:""} onChange={(e) => {
              let cloneSections = clone(sections);
              cloneSections[i].update = true;
              cloneSections[i].contents[i2].type = e.target.value;
              cloneSections[i].contents[i2].update = true;
              setSections(cloneSections);
            }}>
            {Object.keys(ContentTypeEnum).map((key, i3) => (
              <option key={i3} value={key}>{StringUtil.capitalize(key)}</option>
            ))}
          </select>
        </div>
        
        {(content.edit)?
        <div className='content-container'>
          {(content.type === ContentTypeEnum.Video)?
            <ContentVideo uid={uid} content={content} i={i} i2={i2} sections={sections} setSections={setSections} loading={loading}/>
          :null}

          {(content.type === ContentTypeEnum.Quiz)?
            <ContentQuiz content={content} i={i} i2={i2} sections={sections} setSections={setSections} loading={loading}/>
          :null}

          {(content.type === ContentTypeEnum.Challenge)?
            <ContentChallenge content={content} i={i} i2={i2} sections={sections} setSections={setSections} loading={loading}/>
          :null}
        </div>
        :null}
      </div>
      <div className='right'>
        <Button parentClassName={content.edit?"check":"edit"} status={(loading)?"loading":(content.edit)?"check":"edit"} tabIndex="-1" onClick={() => {
          let cloneSections = clone(sections);
          cloneSections[i].contents[i2].edit = !content.edit;
          setSections(cloneSections);
        }}>{(content.edit)?"edit":"check"}</Button>

        <Button parentClassName={"delete"} status={(loading)?"loading":"delete"} tabIndex="-1" onClick={() => {
          let cloneSections = clone(sections);
          if(cloneSections[i].contents[i2].uid !== undefined){
            cloneSections[i].update = true;
            cloneSections[i].contents[i2].delete = true;
            cloneSections[i].contents[i2].update = true;
            toast.info("Marked Content for Deletion");
          }else{
            cloneSections[i].contents.splice(i2, 1);
            toast.info("Content Deleted");
          }
          setSections(cloneSections);
        }}>delete</Button>
      </div>
    </div>
  );

};

export default CourseContent;