import React, {useState,useEffect} from 'react';
import './course-studio.scss';
import Config from '../../../config/config';
import CenterDiv from '../../common/center-div/center-div';
import ReactTextareaAutosize from 'react-textarea-autosize';
import Button from '../../elements/button/button';
import {clone} from '../../../utils/clone';
// import * as StringUtil from '../../../utils/string';
// import SwitchToggle from '../../elements/switch-toggle/switch-toggle';
import {Redirect} from 'react-router-dom';
import { toast } from 'react-toastify';
import { request } from '../../../utils/request';
import Loading from '../../common/loading/loading';
import ConfirmModal from '../../common/modal/confirm-modal';
import VideoUpload from '../../common/video-upload/video-upload';
import VideoViewer from '../../common/video-viewer/video-viewer';
import ImageUpload from '../../common/image-upload/image-upload';
import { ContentTypeEnum } from '../../../enums/content-type';
import { CourseStatusEnum,CourseStatusIdEnum } from '../../../enums/course-status';

import CourseSection from './course-section';

const CourseStudio = ({uid}) => {

  const [redirectUrl,setRedirectUrl] = useState(null);

  const [edit,setEdit] = useState(false);
  const [course,setCourse] = useState({});
  const [sections,setSections] = useState([]);

  const [loading,setLoading] = useState(false);
  const [showConfirmModal,setShowConfirmModal] = useState(false);
  const [showEditConfirmModal,setShowEditConfirmModal] = useState(false);

  useEffect(() => {
    if(!uid){
      createCourse()
    }else{
      fetchCourse(uid);
    }
  },[uid])

  useEffect(() => {
    let newEdit = false;
    if(course && course.status === 0){
      newEdit = true;
    }
    setEdit(newEdit);
  },[course])

  const createCourse = () => {
    setLoading(true);
    request("create-new-course","/create-new-course","POST", {}, {
      then: function(res){
        setRedirectUrl("/course-studio/"+res.data.res.uid);
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false);}
    });
  }

  const deleteCourse = (uid) => {
    setLoading(true);
    request("delete-course","/delete-course/"+uid,"DELETE", {}, {
      then: function(res){
        toast.success("Course Deleted");
        setRedirectUrl("/");
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false);}
    });
  }

  const fetchCourse = (uid) => {
    setLoading(true);
    request("fetch-course","/fetch-course/"+uid,"GET", {}, {
      then: function(res){
        // console.log("res",res.data.res);
        setCourse(res.data.res.course);
        setSections(res.data.res.sections);
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false);}
    });
  }

  const listCourse = (uid) => {
    setLoading(true);
    request("list-course","/course/"+uid+"/list","POST", {}, {
      then: function(res){
        toast.success("Course Listed");
        fetchCourse(uid);
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false);}
    });
  }

  const requestReview = (uid) => {
    setLoading(true);
    request("request-review","/course/"+uid+"/request-review","POST", {}, {
      then: function(res){
        toast.success("Review Requested");
        fetchCourse(uid);
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false);}
    });
  }

  const editCourse = (uid) => {
    setLoading(true);
    request("edit-course","/course/"+uid+"/edit","POST", {}, {
      then: function(res){
        toast.success("Course status updated to Draft ");
        fetchCourse(uid);
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false);}
    });
  }

  const saveCourse = () => {
    // console.log("sections",sections);
    let saveSections = [];
    for (let i = 0; i < sections.length; i++) {
      let deleteSectionCount = 0;
      if(sections[i].update){ 
        if(sections[i].delete){ deleteSectionCount ++; }
        const section = clone(sections[i]);
        let contents = [];
        let deleteSectionContentsCount = 0;
        for (let n = 0; n < section.contents.length; n++) {
          if(section.contents[n].update){
            if(section.contents[n].delete){ deleteSectionContentsCount ++; }

            let content = clone(section.contents[n]);
            if(content.type === ContentTypeEnum.Quiz && content.questions){
              let questions = [];
              let deleteQuestionsCount = 0;
              for (let k = 0; k < content.questions.length; k++) {
                if(content.questions[k].update){
                  const question = clone(content.questions[k]);
                  if(question.delete){ deleteQuestionsCount++ ;}
                  let answers = [];
                  for (let x = 0; x < question.answers.length; x++) {
                    if(question.answers[x].update){
                      const answer = clone(question.answers[x]);
                      delete answer.update;
                      if(answer.edit){delete answer.edit;}
                      answers.push(answer);
                    }
                  }
                  question.order = k + 1 - deleteQuestionsCount;
                  question.answers = answers;
                  delete question.update;
                  if(question.edit){delete question.edit;}
                  questions.push(question);
                }
              }
              content.questions = questions;
            }
            content.order = n + 1 - deleteSectionContentsCount;
            delete content.update;
            if(content.edit){delete content.edit;}
            contents.push(content);
          }
        }
        section.order = i + 1 - deleteSectionCount;
        section.contents = contents;
        delete section.update;
        if(section.edit){delete section.edit;}
        saveSections.push(section);
      }
    }

    let body = {
      courseDetailsEditted: course.update?true:false,
      saveSections,
    }
    
    if(course.update){
      body.course = course;
    }

    console.log("body",body);

    setLoading(true);
    request("save-course","/save-course/"+uid,"POST", body, {
      then: function(res){
        toast.success("Saved");
        // console.log("res",res.data.res);
        // setCourse(res.data.res.course);
        fetchCourse(uid);
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false);}
    });
  }


  if(redirectUrl){
    return <Redirect to={redirectUrl }/>
  }

  if(!uid){
    return(
      <div className="creating-course-studio-comp">
        <CenterDiv>
          <div className='main-creating-course-studio'>
            <div>Creating new course. Please wait one moment. </div>
            <div><Loading show={loading} /></div>
          </div>
        </CenterDiv>
      </div>
    )
  }

  const ActionButtons = ({course}) => {
    let status = course && course.status?course.status:0
    return(
      <div className='action-buttons'>
        <div className='left'>
          <Button disabled={status !== CourseStatusEnum.Draft} type={"button"} parentClassName="delete" status={(loading)?"loading":"delete"} onClick={() => {
            setShowConfirmModal(true);
          }}>Delete Course</Button>
        </div>
        <div className='right'>
          {/* <Button type={"button"} onClick={() =>{
            console.log("sections",sections)
          }}>console log sections</Button> */}
          {(status === CourseStatusEnum.InReview || status === CourseStatusEnum.Unlisted || status === CourseStatusEnum.Listed)?
          <Button type={"button"} parentClassName="save-all" status={(loading)?"loading":"edit"} onClick={() =>{
            setShowEditConfirmModal(true);
          }}>Edit Course</Button>:null}
          {(status === CourseStatusEnum.Draft)?
          <Button type={"button"} parentClassName="save-all" status={(loading)?"loading":"save"} onClick={() =>{
            saveCourse(uid);
          }}>Save All</Button>:null}
          {(status === CourseStatusEnum.Draft || status === CourseStatusEnum.InReview)?
          <Button type={"button"} parentClassName="forward" status={(loading)?"loading":"forward"} 
            disabled={status === CourseStatusEnum.InReview}
            onClick={() =>{
            requestReview(uid);
          }}>{status === CourseStatusEnum.InReview?"In Review":"Submit for Review"}</Button>:null}
          {(status === CourseStatusEnum.Unlisted)?
          <Button type={"button"} parentClassName="forward" status={(loading)?"loading":"forward"} onClick={() =>{
            listCourse(uid);
          }}>List Course</Button>:null}
          <Button type={"button"} parentClassName="preview" status={"preview"} onClick={() =>{
            let url = "/course/"+uid;
            let win = window.open(url, '_blank');
            win.focus();
          }}>Preview Course</Button>
        </div>
      </div>
    )
  }

  return (
    <div className="course-studio-comp">
      <ActionButtons course={course}/>
      <div className='course-studio-main'>
        <div className='title-block'>
          <h3>Course Studio</h3>
          <div className='status'>
            <div>Status: {course && course.status in CourseStatusIdEnum?CourseStatusIdEnum[course.status]:"---"}</div>
          </div>
        </div>
        
        <form>
          <div className='header-block'>
            <div className='left'>
              <div>
                <label>Title</label>
                <input type="text" placeholder='How to blockchain' value={course && course.title?course.title:""} disabled={loading || !edit} onChange={(e) => {
                  let newCourse = clone(course);
                  newCourse.update = true;
                  newCourse.title = e.target.value;
                  setCourse(newCourse);
                }} />
              </div>
              <div>
                <label>Tags</label>
                <p className='note'>Separate each tag by comma</p>
                <input type="text" placeholder='blockchain, pow' value={course && course.tags?course.tags:""} disabled={loading || !edit} onChange={(e) => {
                  let newCourse = clone(course);
                  newCourse.update = true;
                  newCourse.tags = e.target.value;
                  setCourse(newCourse);
                }} />
              </div>
              <div>
                <label>Description</label>
                <p className='note'>Tell the user a bit about this course</p>
                <ReactTextareaAutosize placeholder='Course Description' value={course && course.desc?course.desc:""} disabled={loading || !edit} onChange={(e) => {
                  let newCourse = clone(course);
                  newCourse.update = true;
                  newCourse.desc = e.target.value;
                  setCourse(newCourse);
                }} ></ReactTextareaAutosize>
              </div>
              <div>
                <label>What you'll learn</label>
                <p className='note'>Bullet point list of what the user will learn from this course</p>
                <p className='note'>Separate each line by comma</p>
                <ReactTextareaAutosize placeholder='line 1, line 2, line 3' value={course && course.bulletPointList?course.bulletPointList:""} disabled={loading || !edit} onChange={(e) => {
                  let newCourse = clone(course);
                  newCourse.update = true;
                  newCourse.bulletPointList = e.target.value;
                  setCourse(newCourse);
                }} ></ReactTextareaAutosize>
              </div>
              <div>
                <label>Course Difficulty</label>
                <div className='difficulty hlo'>
                  <div className={(course && course.difficulty && course.difficulty === "Beginner")?"active":""} onClick={() => {
                    if(!edit) return false;
                    let newCourse = clone(course);
                    newCourse.update = true;
                    newCourse.difficulty = "Beginner";
                    setCourse(newCourse);
                  }}>
                    <label>Beginner</label>
                    <p>As long as you know how to turn your computer on you should be able to follow this course. </p>
                  </div>
                  <div className={(course && course.difficulty && course.difficulty === "Intermediate")?"active":""} onClick={() => {
                    if(!edit) return false;
                    let newCourse = clone(course);
                    newCourse.update = true;
                    newCourse.difficulty = "Intermediate";
                    setCourse(newCourse);
                  }}>
                    <label>Intermediate</label>
                    <p>Parts may be a little complicated but with a little effort most should be able to follow. </p>
                  </div>
                  <div className={(course && course.difficulty && course.difficulty === "Advanced")?"active":""} onClick={() => {
                    if(!edit) return false;
                    let newCourse = clone(course);
                    newCourse.update = true;
                    newCourse.difficulty = "Advanced";
                    setCourse(newCourse);
                  }}>
                    <label>Advanced</label>
                    <p>PHD recommended. </p>
                  </div>
                </div>
              </div>
            </div>
            <div className='right'>
              <div className='course-thumbnail'>
                <label>Thumbnail Image</label>
                <div className='thumbnail-image'>
                  {(course.thumbnailUid)?<img src={Config.Common.ApiBaseUrl+"/f/"+course.thumbnailUid} alt="thumbnail"/>:<div className='none'>No Image</div>}
                </div>
                <ImageUpload 
                  type="course-thumbnail"
                  typeUid={course.uid}
                  aspect={12/10}
                  loading={loading}
                  disabled={!edit} 
                  callback={(uid) => {
                    let newCourse = clone(course);
                    newCourse.update = true;
                    newCourse.thumbnailUid = uid;
                    setCourse(newCourse);
                  }}
                  />
              </div>
              <div className='course-trailer'>
                <label>Introduction Video</label>
                <div className='trailer-video'>
                  <VideoViewer uid={course && course.introductionUid?course.introductionUid:""} />
                </div>
                <VideoUpload 
                  loading={loading} 
                  disabled={!edit} 
                  className="button upload" 
                  text="Upload Video" 
                  type="course-introduction" 
                  typeUid={uid?uid:""} 
                  callback={(uid) => {
                    let newCourse = clone(course);
                    newCourse.update = true;
                    newCourse.introductionUid = uid;
                    setCourse(newCourse);
                  }}/>
                {/* <button className="button upload">Upload Transcripts</button> */}
              </div>
            </div>
          </div>

          <div className='sections'>
            <div className='sections-header'>
              <div className='left'>
                <h4>Sections</h4>
                <p className='note'>Split your content up into sections to make it easier to disgest for users. </p>
              </div>
              <div className='right'>
                <Button status={(loading)?"loading":"add"} disabled={!edit} onClick={() => {
                  let cloneSections = clone(sections);
                  cloneSections.push({
                    title:"",
                    desc:"",
                    contents:[],
                    edit:true,
                    update:true,
                  })
                  setSections(cloneSections);
                }}>New Section</Button>
              </div>
            </div>
            <div className='sections-list'>
              {sections.map((v,i) => {
                if(v.delete) return null;
                return(
                  <CourseSection edit={edit} uid={uid} key={i} v={v} i={i} sections={sections} setSections={setSections} loading={loading} />
                )
              })}
            </div>

          </div>
        </form>
      </div>
      <ActionButtons course={course}/>

      <ConfirmModal title="DELETE Course Confirmation" show={showConfirmModal} handleClose={(confirmed) => {
        if(confirmed){ deleteCourse(uid); }
        setShowConfirmModal(false);
      }}>
        <div>Are you sure you want to delete this course? This action is not reversible. </div>
      </ConfirmModal>

      <ConfirmModal title="EDIT Course Confirmation" show={showEditConfirmModal} handleClose={(confirmed) => {
        if(confirmed){ editCourse(uid); }
        setShowEditConfirmModal(false);
      }}>
        <div>Are you sure you want to edit this course? This will change a courses status from "In Review","Unlisted" or "Listed" to draft and you will need to be reapproved before you can list. </div>
      </ConfirmModal>
    </div>
  );

};

export default CourseStudio;