import React, { useEffect, useState } from 'react';
import './home.scss';
import { Helmet } from "react-helmet";
import Seo from '../../../data/seo';
import {Link} from 'react-router-dom';
import Config from '../../../config/config';
import {ReactComponent as ConstructionSvg} from '../../../css/imgs/icon-construction.svg';
import {ReactComponent as FactCheckSvg} from '../../../css/imgs/icon-fact-check.svg';
import {ReactComponent as PublishSvg} from '../../../css/imgs/icon-publish.svg';
import useWindowDimensions from '../../../utils/use-window-dimensions';

import Carousel from '../../common/carousel/carousel';

let images = [
  "{{cdn}}/site-imgs/char-2",
  "{{cdn}}/site-imgs/char-1",
  "{{cdn}}/site-imgs/char-3",
  "{{cdn}}/site-imgs/char-4",
  "{{cdn}}/site-imgs/loc-1",
  "{{cdn}}/site-imgs/loc-2",
  "{{cdn}}/site-imgs/loc-3",
]
let homeImage = Config.Common.CdnUrl+"/site-imgs/home";
let worldBuilderImage = Config.Common.CdnUrl+"/site-imgs/world-builder";
let fanfictionImage = Config.Common.CdnUrl+"/site-imgs/fanfiction";
let communityImage = Config.Common.CdnUrl+"/site-imgs/community";
let writeAIStoryImage = Config.Common.CdnUrl+"/site-imgs/ai-write-story";


const Home = () => {

  const [slide,setSlide] = useState(1);
  const [didMount,setDidMount] = useState(false);
  const { height, width } = useWindowDimensions();
  const [imageAffix,setImageAffix] = useState("-small.webp");

  useEffect(() => {
    let newAffix = "-small.webp";
    if(width >= 800){
      newAffix = "-320.webp";
    }
    setImageAffix(newAffix);
  },[height,width])

  useEffect(() => {
    if(didMount === false){
      shuffle(images);
    }
    setDidMount(true);
  },[didMount])

  function shuffle(array) {
    let currentIndex = array.length,  randomIndex;
    while (currentIndex > 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
    return array;
  }
  



  return (
    <div className="home-comp">

      <Helmet>
        <title>{Seo.Home.Title}</title>
        <meta name="description" content={Seo.Home.Desc} />
        <meta name="keywords" content={Seo.Home.Keywords} />
      </Helmet>

      <div className="section first">
        <div className="inner boxer reverse">
          <div className="image">
            <span className='maxwidth320'>
              <img alt={"AI Story Generator"} src={(homeImage+imageAffix)} 
                // src={(homeImage+"-small.png")} 
                // srcSet={homeImage+"-medium.png 800w, "+homeImage+".png 1200w"} 
                />
            </span>
          </div>
          <div className="spacer"></div>
          <div className="text">
            <h1>AI Story Generator</h1>
            <h2>Unleash Your Imagination with MythBlender’s AI Fanfiction Generator</h2>
            <p className='sub-note'><span className='bolden'>Full World Builder: </span>Dream up any story—craft characters, explore locations, and design entire cultures effortlessly.</p>
            {/* <p>Unleash Your Creativity: Dream up any story—craft characters, explore locations, and design entire cultures effortlessly.</p> */}
            <Link className="button start-creating-now" to="/dashboard" rel="canonical">
              <span className='main-text'>Start Creating Now</span>
              <span className='sub-text'>It's free</span>
            </Link>
          </div>
        </div>
      </div>
      <div className="section bg">
        <div className="inner boxer">
          <div className="image svg">
            <span>
            <Carousel className="world-builder-image-carousel hlo" useAutoHeight={false} slideTo={slide} setSlide={setSlide} sideSlider={true} slideCount={images.length} sideBarPadding={12}>
              {images.map((v,i) => {
                let url = v;
                if(url.includes("{{cdn}}")){
                  url = url.replace("{{cdn}}",Config.Common.CdnUrl);
                }
                return (
                  <div key={i} className='slide'>
                    <div className={"aspect-ratio aspect-ratio-1-1"}>
                      <img alt={"Example image "+i} src={(url+imageAffix)} 
                        // src={(url+"-small.png")} 
                        // srcSet={url+"-medium.png 500w, "+url+".png 800w"} 
                         />
                    </div>
                  </div>
                )
              })}
            </Carousel>
            </span>
          </div>
          <div className="spacer"></div>
          <div className="text">
            <h2>AI Generated Images</h2>
            <p>Bring your story to life: Partnered with leading AI models, we visualize your imagination with stunning, unique images. Characters, locations, and worlds are vividly illustrated, enhancing your narrative with visual depth.</p>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="inner boxer reverse">
          <div className="image svg">
            <span>
              <img alt={"World Builder image "} 
                src={(worldBuilderImage+"-small.png")} 
                srcSet={worldBuilderImage+"-medium.png 800w, "+worldBuilderImage+"-large.png 1200w"} 
                />
            </span>
          </div>
          <div className="spacer"></div>
          <div className="text">
            <h2>From Idea to Epic Saga</h2>
            <p>Begin with a Spark: Transform your initial ideas into rich, complex narratives. Our intuitive tools support you in outlining your story, developing characters, and constructing unique worlds. Embark on your journey to an epic saga.</p>
          </div>
        </div>
      </div>
      <div className="section bg">
        <div className="inner boxer">
          <div className="image svg">
            <span className='maxwidth320'>
              <img alt={"Fanfiction image "} src={(fanfictionImage+imageAffix)} 
                // src={(fanfictionImage+"-small.png")} 
                // srcSet={fanfictionImage+"-medium.png 800w, "+fanfictionImage+".png 1200w"} 
                />
            </span>
          </div>
          <div className="spacer"></div>
          <div className="text">
            <h2>Fanfiction</h2>
            <p>Expand Your Favorite Universes: Love a story and wish to add your own twist? Dive into existing universes, adding your chapters or spins. The possibilities are endless.</p>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="inner boxer reverse">
          <div className="image svg">
            <span className='maxwidth320'>
              <img alt={"Community image "} src={(communityImage+imageAffix)} 
                // src={(communityImage+"-small.png")} 
                // srcSet={communityImage+"-medium.png 800w, "+communityImage+".png 1200w"} 
                />
            </span>
          </div>
          <div className="spacer"></div>
          <div className="text">
            <h2>Share Your Stories, Inspire The World</h2>
            <p>Connect and Inspire: A story grows with its audience. Share your creations and connect with a community eager to read, provide feedback, and inspire each other. It's not just storytelling; it's story-evolving.</p>
          </div>
        </div>
      </div>
      <div className="section how-it-works bg">
        <div className="inner boxer reverse">
          <div className="text">
            <h2>How it Works</h2>
            <p>Simple Steps to Greatness:</p>
            <ul>
              <li>
                <div className='num'>1</div>
                <div className='li-icon'>
                  <FactCheckSvg/>
                </div>
                <div className='li-text'>
                  <h3>Choose</h3>
                  <span>
                    Choose Your Universe: Start by selecting the type of world you wish to create.
                  </span>
                </div>
              </li>
              <li>
                <div className='num'>2</div>
                <div className='li-icon'>
                  <ConstructionSvg/>
                </div>
                <div className='li-text'>
                  <h3>Craft</h3>
                  <span>
                    Craft with AI: Utilize AI to refine characters, locations, and plotlines.
                  </span>
                </div>
              </li>
              <li>
                <div className='num'>3</div>
                <div className='li-icon'>
                  <PublishSvg/>
                </div>
                <div className='li-text'>
                  <h3>Publish</h3>
                  <span>
                    Publish: Once satisfied, publish your story and share your vision with the world.
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className='section'>
        <div className="inner boxer">
          <div className="image svg">
            <span className='maxwidth320'>
              <img alt={"Write AI Story image "} src={(writeAIStoryImage+imageAffix)} 
                // src={(writeAIStoryImage+"-small.png")} 
                // srcSet={writeAIStoryImage+"-medium.png 800w, "+writeAIStoryImage+".png 1200w"} 
                />
            </span>
          </div>
          <div className="spacer"></div>
          <div className="text">
            <h2>Does Using AI Affect the Authenticity of Your Writing?</h2>
            <p>
              Are you curious about whether using an AI Story Generator impacts the authenticity of your writing? Rest assured, utilizing this technology doesn't diminish the value of your work.
            </p>
            <p>
              To begin with, the concepts generated by MythBlender - AI Story Generator are merely prompts. These suggestions aren't complete stories nor original content. It's up to you, as the author, to transform these ideas into distinctive and engaging narratives using your creativity and writing skills.
            </p>
            <p>
              Furthermore, employing an AI Story Generator can actually enhance your storytelling. By offering a variety of ideas and inspirations, these tools can help you develop more original and inventive stories, setting your work apart in a competitive literary landscape.
            </p>
            <p>
              In conclusion, using an AI Story Generator does not undermine your work's authenticity. Instead, it can assist you in crafting more unique and compelling stories that capture the attention of readers.
            </p>
            <p>
              So, don't hesitate to explore the benefits of an AI Story Generator and see how it can enhance your writing journey!
            </p>
          </div>
        </div>
      </div>

      <div className="section faq-section bg">
        <div className="inner boxer">
          <div className="text">
            <h2>Frequently Asked Questions</h2>
            <ul className='faq-list'>
              <li>
                <p className='question'>Can Only Writers Use This AI Story Generator?</p>
                <p className='answer'>                
                  While the MythBlender AI Story Writer is primarily designed for writers, it's not limited to them. Anyone interested in generating creative ideas can benefit from this tool. Whether you're a student, artist, or just someone looking for unique concepts for a project, this AI generator can be a valuable resource.
                </p>
              </li>
              <li>
                <p className='question'>Is the AI Story Writer Safe to Use?</p>
                <p className='answer'>
                  The MythBlender Artificial Intelligence (AI) Story Maker is a reputable and safe tool. However, it's always wise to exercise caution when using any online platform and be aware of potential security risks.
                </p>
              </li>
              <li>
                <p className='question'>Can I Trust the Ideas and Prompts Generated by AI Generators?</p>
                <p className='answer'>
                  The ideas and prompts provided by MythBlender's Free AI Story Generator can serve as a great starting point. However, it's essential for writers to evaluate their suitability and use their own judgment and creativity to develop the ideas into complete stories.
                </p>
              </li>
              <li>
                <p className='question'>Can I Contact the Developers of MythBlender if I Have Questions or Feedback?</p>
                <p className='answer'>
                  Currently, there isn't a direct way for users to provide feedback. However, the team at MythBlender is working on implementing a system for user input based on the analysis of user data. Stay tuned for updates!
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="section try-it-now">
        <div className="inner boxer">
          <div className="text">
            <h2>Give it a Try!</h2>
            <Link rel="canonical" className="button try-it-now-button" to="/dashboard">Try it<span className='its-free'>Its free</span></Link>
            <p className='really-free-text'>No really, you're welcome to use the free version for as long as you want. Tell your friends. 🥰</p>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Home;