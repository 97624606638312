import React, {useEffect, useState,} from 'react';
import './library.scss';
import ContentItem from './content-item';
import Modal from '../../common/modal/modal';
import { toast } from 'react-toastify';
import { request } from '../../../utils/request';
import VideoViewer from '../../common/video-viewer/video-viewer';
import { Link } from 'react-router-dom';
import Button from '../../elements/button/button';

let loadingNumber = 3;
let loadingNumberArr = Array.from(Array(loadingNumber).keys())

const Lbirary = () => {

  const [loading,setLoading] = useState(false);
  const [show,setShow] = useState(false);
  const [courses,setCourses] = useState([]);
  const [activeCourse,setActiveCourse] = useState(null);

  useEffect(() => {
    fetchCourses();
  },[])

  const fetchCourses = () => {
    setLoading(true);
    request("fetch-courses","/courses/","GET", {}, {
      then: function(res){
        setCourses(res.data.res.courses);
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false);}
    });
  }
  const handleClose = () => {
    setShow(false);
    setActiveCourse(null)
  }

  return (
    <div className="library-comp">
      <div className='block'>
        <div className='block-header'>
          <div className='left'>
            <h3>Courses to get you Started</h3>
            {/* <div className='text'>Browse content across an array of topics and disciplines.</div> */}
          </div>
          <div className='right'>
            {/* <select>
              <option>All Topics</option>
              <option>Web 3</option>
            </select> */}
          </div>
        </div>
        <div className='block-content'>
        {(loading && courses.length === 0)?
            <div className='loading-items'>
              {loadingNumberArr.map((v,i) => {
                return (
                  <div className="loading-item loading-item-flow" key={i}>
                    
                  </div>
                )
              })}
            </div>:null
          }
          {courses.map((course,index) => {
            // {bulletPointList,category,createdDate,desc,difficulty,introductionUid,listedDate,status,tags,thumbnailUid,title,uid,updatedDate,}
            return(
              <div className='content-item-container' key={index}>
                <ContentItem 
                  uid={course.uid}
                  imageUid={course.thumbnailUid}
                  header={course.title}
                  desc={course.desc}
                  difficulty={course.difficulty}
                  tags={course.tags}
                  time={"2 hours"}
                  onClickHandle={() => {
                    setShow(true);
                    setActiveCourse(course);
                  }}
                  />
              </div>
            )
          })}
        </div>
      </div>


      <Modal show={show} handleClose={handleClose}>
        <div className='show-video-modal'>
          <div className='course-tiny-text'>Introduction Video</div>
          <div className='course-itlte'>
            {activeCourse && activeCourse.title?activeCourse.title:""}
          </div>
          <VideoViewer 
            uid={activeCourse && activeCourse.introductionUid?activeCourse.introductionUid:null}
            autoPlay={true}
            />
          <div className='buttons'>
            <div className='left'>
              <Button parentClassName={"clear"} onClick={handleClose}>Close</Button>
            </div>
            <div className='right'>
              <Link className='button' to={"/course/"+((activeCourse && activeCourse.uid)?activeCourse.uid:"")}>
                View Course
              </Link>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );

};

export default Lbirary;