import React, { useEffect } from 'react';
import './footer.scss';
import * as BrowserUtil from '../../utils/browser';
import {request,} from '../../utils/request';

import Config from '../../config/config';
import { Link } from 'react-router-dom';
import Logo from '../common/logo/logo';
import { t } from "../../translations/i18n";

import {ReactComponent as LanguageSvg} from '../../css/imgs/icon-language.svg';
import {ReactComponent as FacebookSvg} from '../../css/imgs/sns-icon-facebook.svg';
import {ReactComponent as RedditSvg} from '../../css/imgs/sns-icon-reddit.svg';
import {ReactComponent as TwitterSvg} from '../../css/imgs/sns-icon-twitter.svg';
import {ReactComponent as YoutubeSvg} from '../../css/imgs/sns-icon-youtube.svg';
import {ReactComponent as WarningSvg} from '../../css/imgs/icon-warning.svg';

import useModalState,{ModalEnum} from '../../store/modal';


const Footer = () => {


  useEffect(() => {
    let r = BrowserUtil.getQueryVariable("r");
    if(r !== null){
      saveAffliate(r);
    }
  },[]);

  const saveAffliate = (code) => {
    request("ref-code","/ref-code","POST", {code}, {
      then: (res) => {},catch: (err) => {},finally: () => {}
    });
  }

  const year = new Date().getFullYear();

  const {setActiveModal} = useModalState();

  const communityLinks = [
    {icon: <RedditSvg/>, link: "https://www.reddit.com/r/MythBlender/",},
    {icon: <FacebookSvg/>, link: "https://www.facebook.com/MythBlender",},
    {icon: <TwitterSvg/>, link: "https://twitter.com/MythBlender",},
    {icon: <YoutubeSvg/>, link: "https://google.com",},
  ];

  return (
    <div className="footer">
      <div className="footer-sub">
        <div className="footer-logo">
          <Logo options={"white"}/>
        </div>

        {(Config.Sandbox)?
          <div className='sandbox-note'>
            <div className='sandbox-note-header'>
              <WarningSvg />
              <div className='text'>Sandbox Environment</div>
              <WarningSvg />
            </div>
            <p>This is the Sandbox Environment. It's meant for merchants & developers to test.</p>
            <p><a rel="canonical" href={Config.Common.LiveFrontEndUrl+"/login"}>Regular Users should use the live version. Click here to be redirected there. </a></p>
          </div>
        :null}

        <div className="footer-blocks">
          <div className="block">
            <div className="header">{t('footer.about')}</div>
            <div className="links">
              <a target="_blank" rel="noopener noreferrer" href={Config.Common.CompanyLink}>{t('footer.about')}</a>              
              <Link to="/terms-of-use">{t('footer.termsOfUse')}</Link>
              <Link to="/privacy-policy">{t('footer.privacyPolicy')}</Link>
            </div>
          </div>
          <div className="block">
            <div className="header">{t('footer.services')}</div>
            <div className="links">
              <Link to="/dashboard">{t('navi.dashboard')}</Link>
              <Link to="/ai-story-generator-pricing">{t('navi.pricing')}</Link>
              <Link to="/how-to-use">{t('navi.howToUse')}</Link>
              <Link to="/blog">{t('navi.blog')}</Link>
              {/* <Link to="/library">{t('footer.library')}</Link> */}
              {/* <Link to="/community">{t('footer.community')}</Link> */}
              {/* <Link to="/become-a-teacher">{t('footer.becomeATeacher')}</Link> */}
            </div>
          </div>
          <div className="block">
            <div className="header">{t('footer.support')}</div>
            <div className="links">
              <Link to="/help">{t('footer.help')}</Link>
              <Link to="/support">{t('footer.support')}</Link>
              <Link to="/feedback">{t('footer.submitFeedback')}</Link>
              <Link to="/bug-report">{t('footer.reportABug')}</Link>
              <a target="_blank" href="http://status.mythblender.com">{t('footer.siteHealthStatus')}</a>
            </div>
          </div>
          <div className="block community">
            <div className="header">{t('footer.community')}</div>
            <div className="links">
              {
                communityLinks.map((v,i) => {
                  return(
                    <a key={i} target="_blank" rel="noopener noreferrer" href={v.link}>
                      {v.icon}
                    </a>
                  )
                })
              }
            </div>
          </div>
        </div>
        <div className="language-button hlo" onClick={() => {setActiveModal(ModalEnum.Language)}}>        
          <span className="icon"><LanguageSvg/></span>
          <span className="text">{t("navi.language")}</span>
        </div>
        <div className="footer-legal">
          {/* <div>Made with care by <a target="_blank" rel="noopener noreferrer" href={Config.Common.CompanyLink}>{Config.Common.Company}</a> ❤️</div> */}
          <div>{Config.Common.AppName} &copy; {year}</div>
        </div>
      </div>
    </div>
  );

};

export default Footer;