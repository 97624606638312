import React,{useState,useEffect,} from 'react';
import './affiliate-referrals.scss';
import Table from '../../elements/table/table';
import * as DateUtil from '../../../utils/date';
import * as NumberUtil from '../../../utils/number';
import Carousel from '../../common/carousel/carousel';
import CarouselHeader from '../../common/carousel/carousel-header';
import CenterDiv from '../../common/center-div/center-div';

const initSideItems = [{display:"All",conditions:[]}];

const AffiliateReferrals = ({load,storeUid,affiliates}) => {

  const [slide,setSlide] = useState(1);
  const [affiliateLinksSideItems,setAffiliateLinksSideItems] = useState(initSideItems);
  const [affiliateRewardsSideItems,setAffiliateRewardsSideItems] = useState(initSideItems);
  const [affiliateObj,setAffiliateObj] = useState({});
  
  let ownerId = "user";
  if(storeUid){
    ownerId = storeUid;
  }

  useEffect(() => {
    let newAffiliateLinksSideItems = [];
    let newAffiliateRewardsSideItems = [];
    let newAffiliateObj = {};
    newAffiliateLinksSideItems.push(initSideItems[0]);
    newAffiliateRewardsSideItems.push(initSideItems[0]);
    for (let i = 0; i < affiliates.length; i++) {
      const affiliate = affiliates[i];
      newAffiliateLinksSideItems.push({
        display:affiliate.code,
        conditions:[
          {table:"affiliateLinks", column:"affiliateUid",value:affiliate.uid,action:"="},
        ]
      });
      newAffiliateRewardsSideItems.push({
        display:affiliate.code,
        conditions:[
          {table:"affiliateRewards", column:"affiliateUid",value:affiliate.uid,action:"="},
        ]
      })
      newAffiliateObj[affiliate.uid] = affiliate.code
    }
    setAffiliateLinksSideItems(newAffiliateLinksSideItems);
    setAffiliateRewardsSideItems(newAffiliateRewardsSideItems);
    setAffiliateObj(newAffiliateObj)
  },[affiliates]);

  const affiliateLinksColumns = [
    {table: "affiliateLinks",value: "affiliateUid", hide:true, },
    {table: "affiliate",value: "code", },
    {table: "affiliateLinks",value: "reward",
      custom: ({v,cv}) => <div>{v[cv]}%</div>, 
    },
    {table: "affiliateLinks",value: "createdDate",display:"Date",type:"date", index:true, defaultOrder:"DESC",
      custom: ({v,cv}) => <div>{DateUtil.dateTimeSince(v[cv])}</div>,  
    },
  ];

  const affiliateLinksJoinOptions = [
    {joinType:"LEFT JOIN",leftTable:"affiliateLinks",rightTable:"affiliate",leftColumn:"affiliateUid",rightColumn:"Uid",},
  ]

  const affiliateRewardsColumns = [
    {table: "affiliateRewards",value: "affiliateUid", display:"Code",
    custom:({v,cv}) => {
      let value = v[cv];
      if(value in affiliateObj){
        value = affiliateObj[value];
      }
      return(<div>{value}</div>)
    }},
    {table: "affiliateRewards",value: "reason", },
    {table: "affiliateRewards",value: "coin", hide:true, },
    {table: "affiliateRewards",value: "amountSats", hide:true, },
    {table: "affiliateRewards",value: "usdValueCents", hide:true, },
    {table: "affiliateRewards",value: "btcValueSats", hide:true, },
    {table: "affiliateRewards",value: "reward", ignore:true,
      custom: ({v,cv}) => {
        console.log("v",v);
        let coin = v.affiliateRewardsCoin;
        let amount = NumberUtil.satsToVal(v.affiliateRewardsAmountSats);
        let usdValue = NumberUtil.centsToVal(v.affiliateRewardsUsdValueCents);
        let btcValue = NumberUtil.satsToVal(v.affiliateRewardsBtcValueSats);
        return(
          <div className='amount'>
            <div className='main'>{amount} {coin}</div>
            <div className='sub'>~ {usdValue} USD</div>
            <div className='sub'>~ {btcValue} BTC</div>
          </div>
        )
      }, 
    },
    {table: "affiliateRewards",value: "createdDate",display:"Date",type:"date", index:true, defaultOrder:"DESC",
      custom: ({v,cv}) => <div>{DateUtil.dateTimeSince(v[cv])}</div>,  
    },
  ];

  const affiliateRewardsJoinOptions = [
    {joinType:"LEFT JOIN",leftTable:"affiliateRewardRefunds",rightTable:"affiliateRewards",leftColumn:"affiliateRewardsId",rightColumn:"id",},
  ]

  const affiliateRewardRefundsColumns = [
    {table: "affiliateRewardRefunds",value: "affiliateRewardsId", hide:true, },
    {table: "affiliateRewards",value: "", hide:true, },
    {table: "affiliateRewardRefunds",value: "affiliateUid", display:"Code",
      custom:({v,cv}) => {
        let value = v[cv];
        if(value in affiliateObj){
          value = affiliateObj[value];
        }
        return(<div>{value}</div>)
      }
    },
    {table: "affiliateRewardRefunds",value: "coin", hide:true, },
    {table: "affiliateRewardRefunds",value: "amountSats", hide:true, },
    {table: "affiliateRewardRefunds",value: "usdValueCents", hide:true, },
    {table: "affiliateRewardRefunds",value: "btcValueSats", hide:true, },
    {table: "affiliateRewardRefunds",value: "Refund", ignore:true,
      custom: ({v,cv}) => {
        console.log("v",v);
        let coin = v.affiliateRewardsCoin;
        let amount = NumberUtil.satsToVal(v.affiliateRewardRefundsAmountSats);
        let usdValue = NumberUtil.centsToVal(v.affiliateRewardRefundsUsdValueCents);
        let btcValue = NumberUtil.satsToVal(v.affiliateRewardRefundsBtcValueSats);
        return(
          <div className='amount'>
            <div className='main'>{amount} {coin}</div>
            <div className='sub'>~ {usdValue} USD</div>
            <div className='sub'>~ {btcValue} BTC</div>
          </div>
        )
      }, 
    },
    {table: "affiliateRewardRefunds",value: "createdDate",display:"Date",type:"date", index:true, defaultOrder:"DESC",
      custom: ({v,cv}) => <div>{DateUtil.dateTimeSince(v[cv])}</div>,  
    },
  ];

  const affiliateRewardRefundsJoinOptions = [

  ]


  return (
    <div className="affiliate-referrals">

      <CarouselHeader slideTo={slide} load={load}>
        <div onClick={() => {setSlide(1)}}>Referrals</div>
        <div onClick={() => {setSlide(2)}}>Rewards</div>
        <div onClick={() => {setSlide(3)}}>Refunds</div>
        <div onClick={() => {setSlide(4)}}>Taxes</div>
      </CarouselHeader>

      <Carousel slideTo={slide}>
        <div>
          <Table 
            load={load}
            database={"Main"}
            searchEndpoint={"/affiliate/users/"+ownerId}
            displayName="AffiliateLinksUser"
            disableAdvancedSearch={true}
            name="AffiliateLinksUserTable"
            sideItems={affiliateLinksSideItems}
            sideItemActiveClickFunc={(item) => {

            }}
            joinOptions={affiliateLinksJoinOptions}
            columns={affiliateLinksColumns}
            limit={100}/>
        </div>
        <div>
          <Table 
            load={load}
            database={"Trade"}
            searchEndpoint={"/affiliate/rewards/"+ownerId}
            displayName="AffiliateRewardsUser"
            disableAdvancedSearch={true}
            name="AffiliateRewardsUserTable"
            sideItems={affiliateRewardsSideItems}
            sideItemActiveClickFunc={(item) => {

            }}
            joinOptions={affiliateRewardsJoinOptions}
            columns={affiliateRewardsColumns}
            limit={100}/>
        </div>
        <div>
          <Table 
            load={load}
            database={"Trade"}
            searchEndpoint={"/affiliate/reward-refunds/"+ownerId}
            displayName="AffiliateRewardRefundsUsers"
            disableAdvancedSearch={true}
            name="AffiliateRewardRefundsUsersTable"
            sideItems={[]}
            sideItemActiveClickFunc={(item) => {

            }}
            joinOptions={affiliateRewardRefundsJoinOptions}
            columns={affiliateRewardRefundsColumns}
            limit={100}/>
        </div>
        <div>
          <CenterDiv>
            <div style={{textAlign:"center",}}>
              Tax information & reporting coming soon
            </div>
          </CenterDiv>
        </div>
      </Carousel>

    </div>
  );

};

export default AffiliateReferrals;