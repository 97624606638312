import React, { useState, useRef, useEffect } from 'react';
import './tooltip.scss';

const isTouchDevice = () => {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
}

const Tooltip = ({ className, positionOpt, tip, children, ...rest }) => {
  let position = (positionOpt !== undefined) ? positionOpt : "bottom";
  const [showTip, setShowTip] = useState(false);
  const tooltipRef = useRef(null);

  const [hovering, setHovering] = useState(false);

  useEffect(() => {
    if (isTouchDevice()) {
      // Immediately return if on a touch device to prevent showing the tooltip
      setShowTip(false);
      return;
    }

    if (hovering) {
      const timeout = setTimeout(() => {
        setShowTip(true);
      }, 400);
      return () => clearTimeout(timeout);
    } else {
      setShowTip(false);
    }
  }, [hovering]);

  let tipStyle = {};
  if (tooltipRef.current) {
    let tooltipRect = tooltipRef.current.getBoundingClientRect();
    tipStyle.width = tooltipRect.width - 2;
    tipStyle[position === "bottom" ? "bottom" : "top"] = tooltipRef.current.offsetHeight + 12;
  }

  return (
    <div className={`tooltip ${className || ""}`}
         onMouseOver={() => !isTouchDevice() && setHovering(true)}
         onMouseOut={() => setHovering(false)}
         ref={tooltipRef}
         {...rest}>
      {children}
      <div className={`tip ${showTip ? "" : " hide-fade"}`}
           style={tipStyle}>
        <div className={`tip-child ${position}`}
             style={{ width: tipStyle.width }}>
          <div className="tip-grandchild">
            {tip}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tooltip;



// import React,{useState, useRef, useEffect} from 'react';
// import './tooltip.scss'

// const isTouchDevice = () => {
//   return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
// }

// // top & bottom are only supported atm
// const Tooltip = ({className,positionOpt,tip,children,...rest}) => {

//   let position = (positionOpt !== undefined)?positionOpt:"bottom"

//   const [showTip,setShowTip] = useState(false);
//   const tooltipRef = useRef(null);
//   const tipRef = useRef(null);
//   const tipChildRef = useRef(null);

//   const [hovering,setHovering] = useState(false);

//   useEffect(() => {
//     if (hovering) {
//       const timeout = setTimeout(() => {
//         setShowTip(true);
//       }, 400);
//       return () => clearTimeout(timeout);
//     } else {
//       // If not hovering, hide the tooltip immediately
//       setShowTip(false);
//     }
//   },[hovering]);

//   let tipStyle = {};
//   let tipChildStyle = {};
//   if(tooltipRef !== null && tooltipRef.current !== null){
//     let tooltipRect = tooltipRef.current.getBoundingClientRect();
//     tipChildStyle = {
//       width: tooltipRect.width-2,
//     };
//     if(position === "bottom"){
//       tipStyle.bottom = tooltipRef.current.offsetHeight+12;
//     }else{
//       tipStyle.top = tooltipRef.current.offsetHeight+12;
//     }
//   }

//   return (
//     <div className={"tooltip"+((className !== undefined)?(" "+className):"")}
//       onMouseOver={() => !isTouchDevice() && setHovering(true)}
//       onMouseOut={() => setHovering(false)}
//       ref={tooltipRef}
//       {...rest}
//       >
//       {children}
//       <div className={"tip"+((showTip)?"":" hide-fade")} 
//         style={tipStyle}
//         ref={tipRef}
//         >
//         <div className={"tip-child "+position} 
//           style={tipChildStyle}
//           ref={tipChildRef}
//           >
//             <div className="tip-grandchild">
//               {tip}
//             </div>
//         </div>
//       </div>
//     </div>
//   )
// }

// export default Tooltip;