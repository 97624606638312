import React,{useEffect, useState} from 'react';
import './comment-box.scss';

import ReactTextareaAutosize from 'react-textarea-autosize';
import Button from '../../elements/button/button';
import useUserState from '../../../store/user';
import { request, unSubRequest } from '../../../utils/request';
import { toast } from 'react-toastify';
import {clone} from '../../../utils/clone';
import Loading from '../../common/loading/loading';
import {ReactComponent as UserSvg} from '../../../css/imgs/icon-person.svg';
import {ReactComponent as IconCarrotUpSvg} from '../../../css/imgs/icon-carrot-up.svg';
import {ReactComponent as IconCarrotDownSvg} from '../../../css/imgs/icon-carrot-down.svg';
import {ReactComponent as IconFlagSvg} from '../../../css/imgs/icon-flag.svg';
import {ReactComponent as IconEditSvg} from '../../../css/imgs/icon-edit.svg';
import {ReactComponent as IconDeleteSvg} from '../../../css/imgs/icon-delete.svg';
import {ReactComponent as IconSaveSvg} from '../../../css/imgs/icon-save.svg';

import Config from '../../../config/config';
import * as DateUtil from '../../../utils/date';

const DefaultLimitAmount = 10;

const CommentBox = ({type,uid,initComments,commentInitLimit}) => {

  const [loading,setLoading] = useState(false);
  const [show,setShow] = useState(false);
  const [value,setValue] = useState("");
  const {user} = useUserState();
  const [offset,setOffset] = useState(initComments && initComments.length?initComments.length:0);
  const [comments,setComments] = useState(initComments?initComments:[]);
  const [moreComments,setMoreComments] = useState(initComments && initComments.length === commentInitLimit?true:false);
  const [editUid,setEditUid] = useState(null);
  const [editValue,setEditValue] = useState("");

  // useEffect(() => {
  //   setComments([]);
  //   fetchComments(uid,type,0,InitialCommentAmount);
  // },[type,uid])

  useEffect(() => {
    setComments(initComments?initComments:[]);
    setOffset(initComments && initComments.length?initComments.length:0);
    setMoreComments(initComments && initComments.length === commentInitLimit?true:false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[type,uid])


  useEffect(() => {
    return () => {
      unSubRequest("fetch-comments");
      unSubRequest("post-comments");
    }
  },[])

  const fetchComments = (uid,type,offset,limit) => {
    let endpoint = "/community/comment/"+type+"_"+uid+"/"+offset+"/"+limit;
    setLoading(true);
    request("fetch-comments"+type+"_"+uid,endpoint,"GET", {}, {
      then: function(res){
        let fetchedComments = res.data.res;
        let newComments = clone(comments);
        let newMoreComments = (fetchedComments.length === limit)?true:false;
        // [{uid,body,score,userDisplayName?,userImageUid?,userUid,userUsername,createdDate}]
        for (let i = 0; i < fetchedComments.length; i++) {
          const fetchedComment = fetchedComments[i];
          newComments.push(fetchedComment)
        }
        setComments(newComments);
        setMoreComments(newMoreComments);
        setOffset(offset + limit);
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false);}
    });
  }

  const postComment = () => {
    setLoading(true);
    request("post-comments"+type+"_"+uid,"/community/comment/"+type+"_"+uid,"POST", {value}, {
      then: function(res){
        let newUid = res.data.res.uid;
        let newComments = clone(comments);

        let userDisplayName = user && user.displayName?user.displayName:null;
        let userImageUid = user && user.imageUid?user.imageUid:null;

        newComments.push({
          uid: newUid,
          body: value,
          userUid: user.uid,
          userUsername: user.username,
          userImageUid: userImageUid,
          userDisplayName: userDisplayName,
          createdDate: new Date(),
          score: 0,
        })
        setComments(newComments);
        setValue("");
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false);}
    });
  }


  const saveEdit = (uid) => {
    request("edit-comment"+uid,"/community/comment/"+uid,"PATCH", {text:editValue}, {
      then: function(res){},
      catch: function(err){toast.error(err.message);},
      finally: function(){}
    });
  }

  const deleteComment = (uid) => {
    request("delete-comment"+uid,"/community/comment/"+uid,"DELETE", {}, {
      then: function(res){},
      catch: function(err){toast.error(err.message);},
      finally: function(){}
    });
  }

  return (
    <div className={"comment-box-comp "+((show)?"show-box":"")}>
      <div className="comment-list">
        {comments.map((comment,key) => {
          // console.log("comment",comment);
          let edit = (editUid && editUid === comment.uid)?true:false;
          let userImageUid = comment.userImageUid?comment.userImageUid:null;
          let userDisplayName = comment.userDisplayName?comment.userDisplayName:comment.userUsername
          return(
            <div className='comment' key={key}>
              <div className="comment-score hlo"><span>0</span></div>
              <div className="comment-side-buttons left hlo">
                <div className='comment-upvote' onClick={() => {toast.info("Coming Soon")}}><IconCarrotUpSvg/></div>
                <div className='comment-downvote' onClick={() => {toast.info("Coming Soon")}}><IconCarrotDownSvg/></div>
              </div>
              <div className='comment-content'>
                <div className="comment-body">
                  {(edit)?
                    <ReactTextareaAutosize value={editValue} onChange={(e) => {setEditValue(e.target.value)}}/>
                  :comment.body}
                </div>
                <div className="comment-user-info">
                  <div className='comment-user-info-img'>
                    {(userImageUid)?<img src={Config.Common.ApiBaseUrl+"/f/"+userImageUid} alt="user icon"/>:<UserSvg/>}
                  </div>
                  <div className='comment-user-info-text'>
                    Posted {DateUtil.dateTimeSince(comment.createdDate)} by {userDisplayName}
                  </div>
                </div>
              </div>
              <div className="comment-side-buttons right hlo">
                {(comment.userUid !== user.uid)?<div className='comment-flag' onClick={() => {toast.info("Coming soon")}}><IconFlagSvg/></div>:null}
                {(comment.userUid === user.uid && !edit)?<div className='comment-edit' onClick={() => {setEditUid(comment.uid);setEditValue(comment.body)}}><IconEditSvg/></div>:null}
                {(comment.userUid === user.uid && !edit)?<div className='comment-delete' onClick={() => {
                  deleteComment(comment.uid);
                  let newComments = clone(comments);
                  newComments.splice(key,1);
                  setComments(newComments)
                  setOffset(offset - 1);
                }}><IconDeleteSvg/></div>:null}
                {(edit)?<div className='comment-edit' onClick={() => {
                  let newComments = clone(comments);
                  newComments[key].body = editValue;
                  setComments(newComments);
                  setEditUid(null);
                  saveEdit(comment.uid);
                }}><IconSaveSvg/></div>:null}

              </div>
            </div>
          )
        })}
        <Loading show={loading} size={"18px"}/>
        {(moreComments)?
          <div className='load-more-comments' onClick={() => {
            fetchComments(uid,type,offset,DefaultLimitAmount);
          }}>Load more comments</div>
        :null}
      </div>
      <div className='add-comment-toggle hlo' onClick={() => {
        setShow(true);
      }}>Add a comment</div>

      <div className='add-comment-box'>
        <div className='posting-as'>
          <span>Posting as Trevor Wood</span>
        </div>
        <ReactTextareaAutosize value={value} onChange={(e)=>{setValue(e.target.value)}} placeholder="Add your comment" />
        <div className='buttons'>
          <div><Button parentClassName={"clear"} onClick={() => {setShow(false)}}>Cancel</Button></div>
          <div><Button onClick={() => {postComment()}}>Post comment</Button></div>
        </div>
      </div>
    </div>
  );

};

export default CommentBox;