import React,{useEffect, useState, useRef} from 'react';
import './image-upload.scss'
import { toast } from 'react-toastify';
import { request } from '../../../utils/request';
import Loading from '../loading/loading';
import {ReactComponent as UploadSvg} from '../../../css/imgs/icon-upload.svg';
// import ReactCrop from 'react-image-crop';
import ReactCrop, {centerCrop,makeAspectCrop,} from 'react-image-crop';
import Modal from '../modal/modal';
import Button from '../../elements/button/button';
import { canvasPreview } from './canvas-preview';
import useDebouncedEffect from '../../../utils/use-debounced-effect';
import 'react-image-crop/dist/ReactCrop.css'

const ImageUpload = ({className,type,typeUid, text, aspect, callback, loading, disabled}) => {

  aspect = aspect?aspect:1;

  const [localLoading, setLocalLoading] = useState(loading === true?true:false);
  const [requestLoading, setRequestLoading] = useState(false);

  const previewCanvasRef = useRef(null)
  const imgRef = useRef(null)
  const [crop, setCrop] = useState()
  const [completedCrop, setCompletedCrop] = useState()
  const scale = 1;
  const rotate = 0;

  const [base64,setBase64] = useState(null);
  const [show,setShow] = useState(false);

  useEffect(() => {
    let newLoading = false;
    if(loading || requestLoading){
      newLoading = true;
    }
    setLocalLoading(newLoading);
  },[typeUid,loading,localLoading,requestLoading])

  const centerAspectCrop = (mediaWidth,mediaHeight,aspect,) => {
    return centerCrop(
      makeAspectCrop({unit: '%',width: 90,},aspect,mediaWidth,mediaHeight,),mediaWidth,mediaHeight,
    )
  }

  const onImageLoad = (e) => {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }  
  
  useDebouncedEffect(
    async () => {
      if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
        canvasPreview(imgRef.current,previewCanvasRef.current,completedCrop,scale,rotate,)
      }
    },100,[completedCrop, scale, rotate],
  )

  const convertCanvasToBase64 = (canvasRef) => {
    let croppedBase64 = null;
    if(canvasRef.current){
      try{
        croppedBase64 = imageToDataUri(canvasRef.current, 200, 200);
      }catch(e){ 
        console.log("e",e);
      }
    }
    return croppedBase64;
  }

  const imageToDataUri = (img, width, height) => {
    var canvas = document.getElementById('my-canvas'),
        ctx = canvas.getContext('2d');
    canvas.width = width;
    canvas.height = height;
    ctx.drawImage(img, 0, 0, width, height);
    return canvas.toDataURL();
}

  const uploadImage = (base64) => {
    let body = {
      type,
      typeUid,
      base64,
    }
    setRequestLoading(true);
    request("image-upload","/image-upload","POST", body, {
      then: function(res){
        let uid = res.data.res.uid;
        if(uid){
          callback(uid);
        }
        toast.success("Image Uploaded");
        setRequestLoading(false);
        setShow(false);
      },
      catch: function(err){toast.error(err.message);setRequestLoading(false);},
      finally: function(){}
    });
  }

  return (
    <div className={'hlo image-upload '+((className)?className:"")}>
      <label className={'hlo button'+((loading || disabled)?" disabled":"")}>
        {text?text:"Upload Image"}
        <input type="file" disabled={loading || disabled} onChange={(e) => {
          if(e.target.files.length){
            let file = e.target.files[0];
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
              let newBase64 = reader.result;
              setCrop(undefined);
              setBase64(newBase64);
              setShow(true);
              // uploadImage(base64);
            };
            reader.onerror = function (error) {
              console.log('Error: ', error);
              toast.error("Error Reading file");
            };
            e.target.value = null;
          }
        }} />
        <div className='icon'>
          {loading?
          <Loading size={"24px"} show={true}/>
          :<UploadSvg/>}
        </div>
      </label>
      <Modal className={"crop-modal"} show={show} handleClose={() => {setShow(false)}}>
        <div className='crop-modal-main'>
          <h3>Crop Image</h3>
          <div className='react-cropper-comp'>
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={aspect}
            >
              <img ref={imgRef} alt="Crop me" src={base64}
                style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                onLoad={onImageLoad}
              />
            </ReactCrop>
          </div>
          

          <div>
            {!!completedCrop && (
              <canvas
                className='hide'
                ref={previewCanvasRef}
                style={{
                  border: '1px solid black',
                  objectFit: 'contain',
                  width: completedCrop.width,
                  height: completedCrop.height,
                }}
              />
            )}
            <canvas className='hide' id="my-canvas"/>
          </div>

          <div className='buttons'>
            <div></div>
            <div>
              <Button status={(loading)?"loading":"upload"} onClick={async () => {
                let newBase64 = convertCanvasToBase64(previewCanvasRef);
                // console.log("newBase64",newBase64)
                uploadImage(newBase64);
              }}>Upload</Button>
            </div>
          </div>
          
        </div>
      </Modal>
    </div>
    
  )
}

export default ImageUpload;