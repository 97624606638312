import React from 'react';
import './content-video.scss';
import ReactTextareaAutosize from 'react-textarea-autosize';
import {clone} from '../../../utils/clone';
import VideoUpload from '../../common/video-upload/video-upload';
import VideoViewer from '../../common/video-viewer/video-viewer';

const ContentVideo = ({uid,i,i2,content, sections, setSections,loading}) => {

  return (
    <div className='content-video-comp'>
      <ReactTextareaAutosize placeholder='Video Description' value={content && content.desc?content.desc:""} onChange={(e) => {
        let newSections = clone(sections);
        newSections[i].update = true;
        newSections[i].contents[i2].desc = e.target.value;
        newSections[i].contents[i2].update = true;
        setSections(newSections);
      }}></ReactTextareaAutosize>
      <div className='video-container'>
        <div>
          <div className='video-box'>
            <VideoViewer uid={content && content.videoUid?content.videoUid:null} />
          </div>
          <VideoUpload disabled={loading} className="button upload" text="Upload Video" type={"course-video"} typeUid={uid} callback={(uid) => {
            let newSections = clone(sections);
            newSections[i].update = true;
            newSections[i].contents[i2].videoUid = uid;
            newSections[i].contents[i2].update = true;
            setSections(newSections);
          }}/>
        </div>
        <ReactTextareaAutosize placeholder='Instructor Notes' value={content && content.notes?content.notes:""} onChange={(e) => {
          let newSections = clone(sections);
          newSections[i].update = true;
          newSections[i].contents[i2].notes = e.target.value;
          newSections[i].contents[i2].update = true;
          setSections(newSections);
        }}></ReactTextareaAutosize>
      </div>
    </div>
  );

};

export default ContentVideo;