import React,{useState,useEffect} from 'react';
import './question.scss';

import { request, unSubRequest } from '../../../utils/request';
import { toast } from 'react-toastify';
import * as DateUtil from '../../../utils/date';
import {ReactComponent as UserSvg} from '../../../css/imgs/icon-person.svg';
import CommentBox from './comment-box';
import ReactTextareaAutosize from 'react-textarea-autosize';
import Button from '../../elements/button/button';
import Loading from '../../common/loading/loading';
import Config from '../../../config/config';
import useUserState from '../../../store/user';
import Pagination from '../../common/pagination/pagination';
import { clone } from '../../../utils/clone';
import {ReactComponent as IconFlagSvg} from '../../../css/imgs/icon-flag.svg';
import { Link } from 'react-router-dom';


const Question = ({uid}) => {

  const [loading,setLoading] = useState(false);
  const [answerText,setAnswerText] = useState("");
  const [questionData,setQuestionData] = useState(null);
  const [answers,setAnswers] = useState([]);
  const {user} = useUserState();
  const [page,setPage] = useState(0);
  const [pages,setPages] = useState(0);
  const [answerCount,setAnswerCount] = useState(0);
  const [editQuestionTitle,setEditQuestionTitle] = useState("");
  const [editQuestion,setEditQuestion] = useState("");
  const [edit,setEdit] = useState(false);

  const [editAnswerText,setEditAnswerText] = useState("");
  const [editAnswerIndex,setEditAnswerIndex] = useState(null);

  useEffect(() => {
    return () => {
      unSubRequest("fetch-question");
    }
  },[]);

  useEffect(() => {
    fetchQuestion(uid);
  },[uid])

  useEffect(() => {
    if(page !== 0){
      fetchAnswers(uid,page);
    }
  },[uid,page])

  const fetchQuestion = (uid,pageOpt) => {
    setLoading(true);
    request("fetch-question","/community/question/"+uid,"GET", {}, {
      then: function(res){
        // console.log("res",res.data.res);
        setLoading(false);
        let newAnswerCount = parseInt(res.data.res.answerCount);
        let newPage = pageOpt?pageOpt:0;
        let newPages = parseInt(res.data.res.answerPages);
        if(!pageOpt){
          if(newAnswerCount >= 1){
            newPage = 1;
          }
        }
        setAnswerCount(newAnswerCount);
        setPage(newPage);
        setPages(newPages);
        setQuestionData(res.data.res);
      },
      catch: function(err){toast.error(err.message);setLoading(false);},
      finally: function(){}
    });
  }

  const fetchAnswers = (uid,page) => {
    setLoading(true);
    request("fetch-answers","/community/answer/"+uid+"/"+page,"GET", {}, {
      then: function(res){
        let newAnswers = [];
        if(res.data.res.length){
          newAnswers = res.data.res;
        }
        setAnswers(newAnswers);
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false);}
    });
  }

  const postAnswer = (uid,text) => {
    setLoading(true);
    request("post-answer","/community/answer/"+uid,"POST", {text}, {
      then: function(res){
        fetchQuestion(uid,page);
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){}
    });
  }

  const saveQuestion = (title,body) => {
    setLoading(true);
    request("edit-question","/community/question/"+uid,"PATCH", {title,body}, {
      then: function(res){
        let newClone = clone(questionData);
        newClone.title = title;
        newClone.body = body;
        setQuestionData(newClone);
        setEdit(false);
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false)}
    });
  }

  const saveAnswer = (uid,index,body) => {
    setLoading(true);
    request("edit-answer","/community/answer/"+uid,"PATCH", {body}, {
      then: function(res){
        let newAnswers = clone(answers);
        newAnswers[index].body = body;
        setAnswers(newAnswers);
        setEditAnswerIndex(null);
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false)}
    });
  }

  return (
    <div className="question-comp">
      
      {(questionData && questionData.relatedContentUid)?
        <div className='content-details'>
          {(questionData.relatedCourseUid && questionData.courseTitle)?
            <Link to={"/course/"+questionData.relatedCourseUid}>{questionData.courseTitle}</Link>
          :null}
          {(questionData.relatedSectionUid && questionData.sectionTitle)?
            <Link to={"/course/"+questionData.relatedCourseUid}>{questionData.sectionTitle}</Link>
          :null}
          {(questionData.relatedCourseUid && questionData.courseTitle)?
            <Link to={"/course/"+questionData.relatedCourseUid+"/"+questionData.relatedContentUid}>{questionData.contentTitle}</Link>
          :null}
        </div>
      :loading?<div className='header-loading loading-item-flow'></div>:null}
      <div className='question-container'>
        <div className='user-info'>
          <div className='icon'>
            {(questionData && questionData.user && questionData.user.imageUid)?
            <img src={Config.Common.ApiBaseUrl+"/f/"+questionData.user.imageUid} alt="user icon" />:
            <UserSvg/>}
          </div>
          <div className='text'>
            <span>Posted</span>
            <span>{(questionData && questionData.createdDate)?DateUtil.dateTimeSince(questionData.createdDate):""}</span>
            <span>by {(questionData && questionData.user)?questionData.user.displayName:""}</span>
          </div>
        </div>
        <div className='question-content'>
          <h1>
            {(questionData && questionData.title && !edit)?questionData.title:""}
            {edit?<input disabled={loading} type="text" value={editQuestionTitle} onChange={(e) => {setEditQuestionTitle(e.target.value)}}/>:null}
          </h1>
          <div className='question-body'>
            <ReactTextareaAutosize 
              className={(edit)?"edit":""}
              readOnly={!edit}
              disabled={loading}
              value={!edit?(questionData && questionData.body)?questionData.body:"":editQuestion} 
              onChange={(e) => {setEditQuestion(e.target.value)}}>
            </ReactTextareaAutosize>
          </div>

          {questionData?<CommentBox uid={uid} type="question" initComments={questionData.comments} commentInitLimit={questionData.commentInitLimit}/>:null}
        </div>
        <div className='top-buttons'>
          {(user && questionData && questionData.user  && user.uid !== questionData.user.userUid)?<div className='question-flag' onClick={() => {
            toast.info("Coming soon")
          }}><IconFlagSvg/></div>:null}
          {(user && questionData && questionData.user  && user.uid === questionData.user.userUid  && !edit)?
            <Button status={loading?"loading":"edit"} onClick={() => {
                setEditQuestion((questionData && questionData.body)?questionData.body:"");
                setEditQuestionTitle((questionData && questionData.title)?questionData.title:"");
                setEdit(true)
              }}
              >
                Edit
            </Button>:null}
          {(edit)?<Button status={loading?"loading":"save"} onClick={() => {saveQuestion(editQuestionTitle,editQuestion)}}>Save</Button>:null}
        </div>
        <div className='answer-content'>
          <h3>{answerCount} {(answerCount === 1)?"Answer":"Answers"}</h3>
          <Loading show={loading}/>
          {answers.map((answer,key) => {
            // {body,createdDate,downvoteCount,solved,uid,upvoteCount,userUsername,userUid,userDisplayName?,userImageUid?}
            let userDisplayName = answer.userDisplayName?answer.userDisplayName:answer.userUsername;
            let userImageUid = answer.userImageUid?answer.userImageUid:null;
            let editAnswer = editAnswerIndex !== null && editAnswerIndex === key?true:false;

            return(
              <div className='answer' key={key}>
                <div className='top-buttons'>
                {(user && answer.userUid && user.uid !== answer.userUid)?<div className='question-flag' onClick={() => {
                  toast.info("Coming soon")
                }}><IconFlagSvg/></div>:null}
                {(user && answer.userUid && user.uid === answer.userUid  && !editAnswer)?
                  <Button status={loading?"loading":"edit"} onClick={() => {
                      setEditAnswerText(answer.body);
                      setEditAnswerIndex(key)
                    }}
                    >
                      Edit
                  </Button>:null}
                {(editAnswer)?<Button status={loading?"loading":"save"} onClick={() => {saveAnswer(answer.uid,key,editAnswerText)}}>Save</Button>:null}
                </div>
                <div className='answer-user-info'>
                  <div className='image'>
                    {(userImageUid)?<img src={Config.Common.ApiBaseUrl+"/f/"+userImageUid} alt="user icon"/>:<UserSvg />}
                  </div>
                  <div className='text'>
                    <span>Posted</span>
                    <span>{DateUtil.dateTimeSince(answer.createdDate)}</span>
                    <span>by {userDisplayName}</span>
                  </div>
                  {answer.userId}
                </div>
                <div className="answer-body">
                  <ReactTextareaAutosize 
                    className={"edit-answer "+((editAnswer)?"editting":"")}
                    readOnly={!editAnswer}
                    disabled={loading}
                    value={editAnswer?editAnswerText:answer.body} 
                    onChange={(e) => {setEditAnswerText(e.target.value)}}>
                  </ReactTextareaAutosize>
                </div>
                <CommentBox uid={answer.uid} type="answer" initComments={answer.comments?answer.comments:[]} commentInitLimit={answer.commentInitLimit}/>
              </div>
            )
          })}
        </div>
        <Pagination page={page} pages={pages} setPage={setPage} />

        <div className='add-answer-box'>
          <h3>Add an Answer</h3>
          <div className='input-box'>
            <div className='user-image'>
              {(user && user.imageUid)?<img src={Config.Common.ApiBaseUrl+"/f/"+user.imageUid} alt="user icon"/>:<UserSvg/>}
            </div>
            <div className='input-box-sub'>
              <p className='posting-as'>Posting as {(user && user.displayName)?user.displayName:user.username}</p>
              <ReactTextareaAutosize placeholder="Add your answer" value={answerText} onChange={(e) => {setAnswerText(e.target.value)}}/>
              <div className='buttons'>
                <div></div>
                <div>
                  <Button type="button" onClick={() => {
                    postAnswer(uid,answerText);
                  }}>Post answer</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

};

export default Question;