import React,{useEffect, useState,} from 'react';
import './video-upload.scss'
import { toast } from 'react-toastify';
import { request } from '../../../utils/request';
// import Loading from '../loading/loading';
// import {ReactComponent as UploadSvg} from '../../../css/imgs/icon-upload.svg';
import Button from '../../elements/button/button';

const VideoUpload = ({className,type,typeUid, text, callback, loading, disabled}) => {

  const [localLoading, setLocalLoading] = useState(loading === true?true:false);
  const [requestLoading, setRequestLoading] = useState(false);

  const [selectVal,setSelectVal] = useState("youtube");
  const [textVal,setTextVal] = useState("");


  useEffect(() => {
    let newLoading = false;
    if(loading || requestLoading){
      newLoading = true;
    }
    setLocalLoading(newLoading);
  },[typeUid,loading,localLoading,requestLoading])

  // const uploadVideo = (base64) => {
  //   let body = {
  //     type,
  //     typeUid,
  //     base64,
  //   }
  //   setRequestLoading(true);
  //   request("video-upload","/video-upload","POST", body, {
  //     then: function(res){
  //       // console.log("rse",res);
  //       let uid = res.data.res.uid;
  //       if(uid){
  //         callback(uid);
  //       }
  //       toast.success("Video Uploaded");
  //     },
  //     catch: function(err){toast.error(err.message);},
  //     finally: function(){setRequestLoading(false);}
  //   });
  // }

  const saveYoutubeVideo = () => {
    let body = {
      type,
      typeUid,
      textVal,
    }
    setRequestLoading(true);
    request("video-youtube-upload","/video-youtube-upload","POST", body, {
      then: function(res){
        // console.log("rse",res);
        let uid = res.data.res.uid;
        if(uid){
          callback(uid);
        }
        toast.success("Youtube Video Uploaded");
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){setRequestLoading(false);}
    });
  }

  return (
    <div className='video-upload-comp'>
      <select onChange={(e) => {setSelectVal(e.target.value)}}>
        <option value="youtube">Youtube Upload</option>
        <option value="direct">Direct Upload</option>
      </select>
      {(selectVal === "youtube")?
      <div className='youtube-box'>
          <input type="text" placeholder='Youtube Link' value={textVal} onChange={(e) => {setTextVal(e.target.value)}}/>
          <Button type="button"  status={(loading?"loading":"upload")} onClick={()=> {saveYoutubeVideo()}}>
            Upload
          </Button>
      </div>
      :
      <div className='direct-soon'>
        Direct upload coming soon
      </div>
      // <label className={'hlo video-upload button '+((className)?className:"")+((loading || disabled)?" disabled":"")}>
      //   {text?text:"Upload Video"}
      //   <input type="file" disabled={loading || disabled} onChange={(e) => {
      //     if(e.target.files.length){
      //       let file = e.target.files[0];
      //       var reader = new FileReader();
      //       reader.readAsDataURL(file);
      //       reader.onload = function () {
      //         let base64 = reader.result;
      //         uploadVideo(base64);
      //       };
      //       reader.onerror = function (error) {
      //         console.log('Error: ', error);
      //         toast.error("Error Reading file");
      //       };
      //     }
      //   }} />
      //   <div className='icon'>
      //     {loading?
      //     <Loading size={"24px"} show={true}/>
      //     :<UploadSvg/>}
      //   </div>
      // </label>
      }
    </div>
  )
}

export default VideoUpload;