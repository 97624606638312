import React, {useState,useEffect,useRef} from 'react';
import './content.scss';
import Button from '../../elements/button/button';
import {clone} from '../../../utils/clone';
import { toast } from 'react-toastify';
import { request } from '../../../utils/request';
import VideoViewer from '../../common/video-viewer/video-viewer';
import { ContentTypeEnum } from '../../../enums/content-type';
import {ReactComponent as BackSvg} from '../../../css/imgs/icon-arrow-back.svg';
import ContentItem from './content-item';
import CarouselHeader from '../../common/carousel/carousel-header';
import Carousel from '../../common/carousel/carousel';
import * as ObjectUtil from '../../../utils/object';
import CodeEditor from '@uiw/react-textarea-code-editor';
import ContentQuestions from './content-questions';
import ReactTextareaAutosize from 'react-textarea-autosize';


const PercentageNeededToCompleteVideo = 50;

const Content = ({course,section,content,backToCourse,goToContent,nextSection,markLocalContentComplete}) => {

  const videoRef = useRef(null);
  const [contentUid,setContentUid] = useState(null);
  const [slide,setSlide] = useState(1);
  const [questionIndex,setQuestionIndex] = useState(0);
  const [quizProgress,setQuizProgress] = useState({});
  const [wrongAnswerIndex,setWrongAnswerIndex] = useState(null);
  const [answers,setAnswers] = useState([]);
  const [question,setQuestion] = useState(null);
  const [code,setCode] = useState(null);
  const [challengePassed,setChallengePassed] = useState(false);
  const [codeErrorMessage,setCodeErrorMessage] = useState("");
  const [finish,setFinish] = useState(false);
  const [updateWatchTime,setUpdateWatchTime] = useState(0);
  const [markedComplete,setMarkedComplete] = useState(false);
  const [showCongrats,setShowCongrats] = useState(false);
  const [finishedQuiz,setFinishedQuiz] = useState(false);
  // const [loading,setLoading] = useState(false);

  useEffect(() => {
    setContentUid(content.uid);
  },[content]);

  useEffect(() => {
    let percentage = Math.round(100 / PercentageNeededToCompleteVideo);

    if(videoRef.current && markedComplete === false){
      let video = videoRef.current;
      let newMarkedComplete = false;
      let realWatchTime = Math.round(updateWatchTime/4);
      if(realWatchTime > (video.duration/percentage)){
        newMarkedComplete = true;
        markContentAsComplete();
      }
      setMarkedComplete(newMarkedComplete)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[videoRef,updateWatchTime,markedComplete])


  useEffect(() => {
    setSlide(1);
    setQuestionIndex(0);
    setQuizProgress({});
    setWrongAnswerIndex(null);
    setAnswers([]);
    setQuestion(null);
    setCode(null);
    setChallengePassed(false);
    setCodeErrorMessage("");
    setFinish(false);
    setUpdateWatchTime(0);
    setMarkedComplete(false);
    setShowCongrats(false);
    setFinishedQuiz(false);
  },[contentUid]);

  useEffect(() => {
    let newQuestion = null;
    let newCode = null;
    if(contentUid && content && content.type === ContentTypeEnum.Quiz && content.questions && content.questions.length >= 1){
      newQuestion = content.questions[questionIndex];
    }
    if(contentUid && content && content.type === ContentTypeEnum.Challenge){
      newCode = content.codeTemplate;
    }
    setQuestion(newQuestion);
    setCode(newCode);
  },[contentUid,content,questionIndex,])

  useEffect(() => {
    let newQuestion = null;
    if(content && content.type === ContentTypeEnum.Quiz && content.questions && content.questions.length >= 1){
      newQuestion = content.questions[questionIndex];
    }
    setQuestion(newQuestion);
  },[content,questionIndex,])

  useEffect(() => {
    let newAnswers = [];
    if(question !== null){
      if(question.answers && question.answers.length){
        newAnswers = clone(question.answers)
        ObjectUtil.shuffleArray(newAnswers);
    
        if(question.allOfAbove){
          newAnswers.push({
            text:"All of the Above",
            correct: question.allOfAboveCorrect,
          })
        }
        if(question.noneOfAbove){
          newAnswers.push({
            text:"None of the Above",
            correct: question.noneOfAboveCorrect,
          })
        }
      }
    }
    setAnswers(newAnswers)
  },[question,])

  useEffect(() => {
    let newMarkedComplete = false;
    let newShowCongrats = false;
    if(markedComplete === false && content && content.questions && content.questions.length){
      let quizPassRate = 80;
      let totalRightNeeded = content.questions.length * (quizPassRate/100);
      totalRightNeeded = Math.ceil(totalRightNeeded);
      let totalRight = 0;
      for(let key in quizProgress){
        if(quizProgress[key] === true){
          totalRight++;
        }
      }

      if(totalRight >= totalRightNeeded){
        newMarkedComplete = true;
        newShowCongrats = true;
        markContentAsComplete()
      }
    }

    setMarkedComplete(newMarkedComplete);
    setShowCongrats(newShowCongrats);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[quizProgress,])
  
  const nextContent = () => {
    if(section.contents[section.contents.length-1].uid === content.uid){
      setFinish(true);
    }else{
      let newContentItem = null;
      for (let u = 0; u < section.contents.length; u++) {
        const contentItem = section.contents[u];
        if(contentItem.uid === content.uid){
          newContentItem = section.contents[u+1];
          break;
        }
      }
      goToContent({
        section: section,
        content: newContentItem,
      });
    }
  }

  const checkWork = (code,expectedResult) => {
    //TODO: Need to cleanse eval before running this. 
    toast.info("Coming Soon");
    // let pass = false;
    // let message = "Did not pass"
    // try{
    //   let userCode = eval(code);
    //   let returnCode = eval("(userInputObject) => {"+expectedResult+" return {pass,errorMsg}}");
    //   let userInputObject = userCode();
    //   let result = returnCode(userInputObject);
    //   if(result.pass){pass = result.pass}
    //   if(result.errorMsg){message = result.errorMsg}
    // }catch(e){
    //   message = "Compiler error, please make sure the inputted code is correct. ";
    // }

    // let newMarkedComplete = false;
    // let newCodeErrorMessage = "";
    // if(pass){
    //   if(!markedComplete){
    //     newMarkedComplete = true;
    //     markContentAsComplete();
    //   }
    //   toast.success("Great work! Thats right! ");
    // }else{
    //   newCodeErrorMessage = message;
    // }
    // setCodeErrorMessage(newCodeErrorMessage)
    // setChallengePassed(pass);
    // setMarkedComplete(newMarkedComplete);
  }

  const markContentAsComplete = () => {
    request("mark-content-complete","/mark-content-complete/"+contentUid,"POST", {}, {
      then: function(res){
        if(markLocalContentComplete){
          markLocalContentComplete()
        }
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){}
    });
  }

  return (
    <div className="content-comp">
      <div className='top-buttons'>
        <div className='back-button' onClick={() => {
          backToCourse(course.uid);
        }}>
          <div className='icon'><BackSvg/></div>
          <div>Back to {(course && course.title)?course.title:""}</div>
        </div>
      </div>

      <div className='section-header'>
        <div className='content-line'></div>
        {section.contents.map((contentItem,i) => {
          return(
            <ContentItem key={i}
              active={contentItem.uid === content.uid}
              content={contentItem} 
              goToContent={({section,content}) => {
                // setFinish(false);
                goToContent({section,content});
              }} 
              course={course}
              section={section}
              />
          )
        })}
      </div>

      {(finish)?
        <div className='finished-section'>
          <div>
            <h3>Course Section Finished! Congrats</h3>
            <Button status="forward" onClick={() => {
              if(nextSection && nextSection.contents && nextSection.contents.length){
                let nextContent = nextSection.contents[0];
                goToContent({
                  section: nextSection,
                  content: nextContent,
                })
              }else{
                backToCourse();
              }
            }}>
            {(nextSection && nextSection.contents && nextSection.contents.length)?
            "Continue to Next Section":"Finish Course"}
            </Button>
          </div>
        </div>
      :null}

      {(content && content.type === ContentTypeEnum.Video && !finish)?
        <div className='content-video'>
          <div className='video-box'>
            <VideoViewer videoRef={videoRef} uid={content && content.videoUid?content.videoUid:""} 
              autoPlay={true}
              onTimeUpdate={(e)=>{
                setUpdateWatchTime(updateWatchTime + 1)
              }}
            />
          </div>
          <div className='video-details'>
            <div className='video-headers'>
              <div className='text'>
                <h3 className='title'>{content && content.title?content.title:""}</h3>
                <div className='desc'>{content && content.desc?content.desc:""}</div>
              </div>
              <div className='buttons'>
                <Button status="forward" onClick={() => {
                  nextContent();
                }}>
                  {section.contents[section.contents.length-1].uid === content.uid?
                    "Finish Section"
                  :"Continue"
                  }
                </Button>
              </div>
            </div>
            <div className='video-related'>
              <CarouselHeader slideTo={slide}>
                <div onClick={()=>{setSlide(1)}}>Teacher Notes</div>
                <div onClick={()=>{setSlide(2)}}>Questions</div>
                <div onClick={()=>{setSlide(3)}}>Video Transcripts</div>
              </CarouselHeader>
              <Carousel slideTo={slide}>
                <div>
                  <div className='notes'>
                    <ReactTextareaAutosize readOnly value={
                      content && content.notes?content.notes:""
                    }></ReactTextareaAutosize>
                  </div>
                </div>
                <div>
                  <ContentQuestions 
                    courseUid={course.uid}
                    sectionUid={section.uid}
                    contentUid={contentUid} />
                </div>
                <div>Coming Soon</div>
              </Carousel>
            </div>
          </div>
        </div>
      :null}

      {(content && content.type === ContentTypeEnum.Quiz && question && !finish)?
        <div className='content-quiz'>
          <div className='quiz-header'>
            <div className='quiz-question-num'>Quiz Question {questionIndex+1} of {content.questions.length} </div>
            <div className='quiz-question-text'>{question && question.questionText?question.questionText:""}</div>
            {(finishedQuiz && showCongrats)?
            <div className='congrats'>Congratulations! You passed this quiz. </div>:null}
            {(finishedQuiz && !showCongrats)?
            <div className='oops'>Oops, Unfortunately you didn't pass this quiz, no worries, you can retake it. </div>:null}
          </div>
          <div className='quiz-buttons'>
            <div>Choose the correct answer below</div>
            <div>
              {((questionIndex+1) === content.questions.length)?
              <Button status="forward" onClick={()=>{
                nextContent();
              }}>Finish Quiz</Button>
              :<Button status="forward" onClick={()=>{
                setWrongAnswerIndex(null);
                setQuestionIndex(questionIndex + 1); 
              }}>Next Question</Button>}
            </div>
          </div>
          <div className='quiz-answers hlo'>
            {(answers.length)?
              answers.map((answer,i) => {
                let classText = "click-on";
                if(answer.correct && question.uid in quizProgress){
                  classText = "correct";
                }else if(question.uid in quizProgress && wrongAnswerIndex === i){
                  classText = "wrong";
                }else if(question.uid in quizProgress){
                  classText = "click-off";
                }
                return(
                  <div className={'answer '+classText} key={i} onClick={() => {
                    let newQuizProgress = clone(quizProgress);
                    let newWrongAnswerIndex = wrongAnswerIndex;
                    let newFinishedQuiz = finishedQuiz;
                    if(answer.correct){
                      if(!(question.uid in newQuizProgress)){
                        newQuizProgress[question.uid] = true;
                      }
                    }else{
                      if(!(question.uid in newQuizProgress)){
                        newQuizProgress[question.uid] = false;
                        newWrongAnswerIndex = i;
                      }
                    }
                    if(((questionIndex+1) === content.questions.length)){
                      newFinishedQuiz = true;
                    }
                    setQuizProgress(newQuizProgress)
                    setWrongAnswerIndex(newWrongAnswerIndex);
                    setFinishedQuiz(newFinishedQuiz);
                  }}>
                    <div className='icon'>{(i + 10).toString(36).toUpperCase()}</div>
                    <div className='text'>{answer.text}</div>
                  </div>
                )
              })
            :null}
          </div>
        </div>
      :null}

      {(content && content.type === ContentTypeEnum.Challenge && !finish)?
        <div className='content-challenge'>
          <div className='challenge-header'>
            <div className='details'>
              <h3 className='challenge-title'>{content && content.title?content.title:""}</h3>
              <div className='challenge-instructions'>{content && content.instructions?content.instructions:""}</div>
              {(codeErrorMessage && codeErrorMessage !== "")?<div className='code-error-message'>{codeErrorMessage}</div>:null}
            </div>
            <div className='buttons'>
             
              <Button parentClassName={(challengePassed)?"":"clear"} status="forward" onClick={() => {
                nextContent();
              }}>{(challengePassed)?"Finish":"Skip"} Challenge</Button>
              
            </div>
          </div>

          <CodeEditor
            value={code}
            language="js"
            placeholder="Code Editor"
            onChange={(evn) => {
              let newCode = evn.target.value;
              setCode(newCode);
            }}
            padding={12}
            style={{
              fontSize: 12,
              backgroundColor: "#f5f5f5",
              fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
            }}
          />
          <div className='bottom-buttons'>
            <div></div>
            <div>
              <Button onClick={() => {
                checkWork(code,content.expectedResult);
              }}>Check Work</Button>
            </div>
          </div>


          {/* codeTemplate: " "
          expectedResult: " "
          instructions: " "
          language: "JavaScript"
          title: "Tough Challenge"
          type: "Challenge" */}
        </div>
      :null}

    </div>
  );

};

export default Content;