
let WorldItemEnum = {
  characters:{
    name:"Character",table:"characters",
    columns:[`name`,`appearance`,`archetype`,`traits`,`backstory`],
  },
  locations:{
    name:"Location",table:"locations",
    columns:[`name`,`features`,`plotInfluence`], 
  },
  cultures:{
    name:"Culture",table:"cultures",
    columns:[`name`,`beliefs`,`traditions`,`socialNorms`,`backstory`], 
  },
  species:{
    name:"Species",table:"species",
    columns:[`name`,`appearance`,`lifespan`,`habitat`,`backstory`], 
  },
  conflicts:{
    name:"Conflict",table:"conflicts",
    columns:[`name`,`description`], 
  },
  religions:{
    name:"Religion",table:"religions",
    columns:[`name`,`practices`,`influence`], 
  },
  religionGods:{
    name:"Relgion Diety",table:"religion_gods",
    columns:[`name`,`appearance`,`traits`,`backstory`], 
  },
  factions:{
    name:"Factions",table:"factions",
    columns:[`name`,`backstory`], 
  },
}

export default WorldItemEnum;