export const faq = [
  {
    question: {
      en: "How long does my subscription last?",
      ja: "How long does my subscription last? ja",
    },
    answer: {
      en: "You can subscribe for 1 month or 12 months",
      ja: "You can subscribe for 1 month or 12 months ja",
    },
  },{
    question: {
      en: "Do my AI Tokens reset each month?",
      ja: "Do my AI Tokens reset each month? ja",
    },
    answer: {
      en: "Tokens given via subscriptions reset each month on the day you subscribed. Tokens bought directly via top up tokens last forever.",
      ja: "Tokens given via subscriptions reset each month on the day you subscribed. Tokens bought directly via top up tokens last forever. ja",
    },
  },{
    question: {
      en: "How do I cancel subscription?",
      ja: "How do I cancel subscription? ja",
    },
    answer: {
      en: "You can cancel your subscription by heading over to your 'My Account' page",
      ja: "You can cancel your subscription by heading over to your 'My Account' page ja",
    },
  },
]






