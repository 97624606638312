import React, { useState, useEffect } from 'react';
import './progress-bar.scss';

const ProgressBar = ({duration,show}) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const startTime = Date.now();
    const interval = setInterval(() => {
      const elapsedTime = Date.now() - startTime;
      const updatedProgress = (elapsedTime / (duration * 1000)) * 100;

      if (updatedProgress >= 99) {
        setProgress(99);
        clearInterval(interval);  
      } else {
        setProgress(updatedProgress);
      }
    }, 25);

    return () => {
      clearInterval(interval);
    }
  }, [duration,show]);

  return (
    <div className='progress-bar-comp'>
      <div className='outer-bar'>
        <div className='inner-bar' style={{width: `${progress}%`}}>
          {Math.round(progress)}%
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;