import React,{useEffect, useState}  from 'react';
import './navi.scss';

import NaviMain from './navi-main';
import NaviMobile from './navi-mobile';
import NaviGrayOutScreen from './navi-gray-out-screen';
import NaviLanguageModal from './navi-language-modal';

import useHeaderState from '../../store/header';
import useModalState,{ModalEnum} from '../../store/modal';
import useUserState from '../../store/user';
import useSocketState from '../../store/socket';
import SocketEnum from '../../enums/socket';
import SocketEventEnum from '../../enums/socket-event';

const Navi = () => {

  const [toggleWidth,setToggleWidth] = useState((localStorage.naviToggleWidth === "true")?true:false);
  const {header} = useHeaderState();
  const {activeModal,setActiveModal} = useModalState();

  const {user} = useUserState();
  const {socketMessages,sendSocketMessage,} = useSocketState();
  // const socketMessages = null;
  // const sendSocketMessage = () => {};
  const [notifications,setNotifications] = useState(null);

  const menuClass = (header.menuOpen) ? " open" : " close";
  const toggleClass = (toggleWidth)?" toggle-width-active":"";

  useEffect(() => {
    const data = {
      event: SocketEventEnum.Subscribe,
      channels: {},
    }
    data.channels[SocketEnum.Notifications] = "userUid$"+SocketEnum.Notifications;

    sendSocketMessage(data);
    return () => {
      data.event = SocketEventEnum.Unsubscribe
      sendSocketMessage(data);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    let newNotifs = notifications;
    if(socketMessages && socketMessages.notifications){
      newNotifs = socketMessages.notifications;
    }

    setNotifications(newNotifs);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[socketMessages,user,notifications])

  return (
    <div className={"navi"+menuClass+toggleClass}>
      <NaviMobile unSeenNotifs={(notifications && notifications.unseen)?notifications.unseen:0}/>
      <NaviMain notifs={notifications} menuClass={menuClass} toggleWidth={toggleWidth} setToggleWidth={setToggleWidth} />
      <NaviGrayOutScreen />
      <NaviLanguageModal show={activeModal === ModalEnum.Language} handleClose={()=>{setActiveModal("")}}/>
    </div>
  );

};

export default Navi;