import React from 'react';
import './my-account.scss';
import { Helmet } from "react-helmet";
import Seo from '../../../data/seo';

import AccountType from './account-type';
// import Notifications from './notifications';
import PaymentDetails from './payment-details';
import DangerZone from './danger-zone';
import ProfileDetails from './profile-details';
import LinkSnsAccounts from './link-sns-accounts';
// import AccountSecurity from './account-security';
// import MyCourses from './my-courses';
// import PublicProfile from './public-profile';
import Affiliate from './affiliate';
// import Api from '../api/api';
import * as BrowserUtil from '../../../utils/browser';


const MyAccount = () => {

  const action = BrowserUtil.getQueryVariable("action");

  return (
    <div className="my-account">
      <Helmet>
        <title>{Seo.MyAccount.Title}</title>
        <meta name="description" content={Seo.MyAccount.Desc} />
        <meta name="keywords" content={Seo.MyAccount.Keywords} />
      </Helmet>
      <h1>My Account</h1>

      {/* <PublicProfile/> */}
      {/* <MyCourses /> */}
      <AccountType />
      <ProfileDetails action={action}/>
      <PaymentDetails />
      {/* <AccountSecurity/> */}
      <LinkSnsAccounts />
      <Affiliate/>
      {/* <Api load={true}/> */}
      {/* <Notifications /> */}
      <DangerZone />

    </div>
  );

};

export default MyAccount;