import React from 'react';
import './plinko.scss';
import PlinkoBoard from './plinko-board';

const Plinko = () => {

  return (
    <div className="plinko">
      <h1>plinko</h1>
      <PlinkoBoard/>
    </div>
  );

};

export default Plinko;