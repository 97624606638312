import React, {useState, useEffect } from 'react';
import './account-type.scss';
import AccountStatusEnum, {AccountStatusIdEnum} from '../../../enums/account-status';
import useUserState from '../../../store/user';
import {request,unSubRequest} from '../../../utils/request';
import {clone} from '../../../utils/clone';
import Loading from '../../common/loading/loading';
import {prettifyDate} from '../../../utils/string';
import {formatCurrency} from '../../../utils/number';
import Config,{ConfigVariables} from '../../../config/config';
import * as DateUtil from '../../../utils/date';
import * as StringUtil from '../../../utils/string';
import * as NumberUtil from '../../../utils/number';
import { toast } from 'react-toastify';
import SwitchToggle from '../../elements/switch-toggle/switch-toggle';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import PlatformEnum from '../../../enums/platform';
import Button from '../../elements/button/button';

const AccountType = () => {

  const {user,setUser} = useUserState();
  const [updateStatus,setUpdateStatus] = useState(false);
  const [newStatus,setNewStatus] = useState();

  const [loading,setLoading] = useState(false);
  const [autoRenewLoading,setAutoRenewLoading] = useState(false);
  const [accountResults,setAccountResults] = useState({});
  const [hasActiveSub,setHasActiveSub] = useState(false)
  const [subAutoRenew,setSubAutoRenew] = useState(false);

  const [tokenAmount,setTokenAmount] = useState("1");
  const [tokenType,setTokenType] = useState("text");

  useEffect(() => {
    refreshSubStatus();
    return () => {
      unSubRequest("consume-token");
      unSubRequest("subscription-status");
    }
  },[])

  useEffect(() => {
    if(updateStatus && newStatus !== undefined){
      let cloneUser = clone(user);
      cloneUser.status = newStatus;    
      setUser(cloneUser);
      setUpdateStatus(false);
    }
  },[user,setUser,updateStatus,newStatus])

  const refreshSubStatus = () => {
    setLoading(true);
    request("subscription-status","/subscription-status?force","GET", {}, {
      then: function(res){
        // console.log("res",res);
        let results = {};
        let newStatus = -1;
        let newUpdateStatus = false;
        let newSubAutoRenew = false;
        if(res.data.res){
          results = res.data.res;
          if(results.autoRenew){
            newSubAutoRenew = true;
          }
        }
        if(results.user && results.user.status){
          newStatus = results.user.status;
          newUpdateStatus = true;
        }
        setAccountResults(results);
        setHasActiveSub(results.hasActiveSub?true:false);
        setUpdateStatus(newUpdateStatus);
        setNewStatus(newStatus);
        setSubAutoRenew(newSubAutoRenew);
      },
      catch: function(err){ toast.error(err.message);},
      finally: function(){setLoading(false);}
    });
  };

  const toggleAutoRenew = (autoRenew,autoRenewLoading) => {
    if(autoRenewLoading){ return; }
    setSubAutoRenew(!autoRenew);
    setAutoRenewLoading(true);
    let data = {autoRenew: !autoRenew,}
    request("stripe-subscriptions-auto-renew","/stripe-subscriptions-auto-renew","POST", data, {
      then: function(res){toast.success("Updated Subscription Settings");},
      catch: function(err){toast.error(err.message);},
      finally: function(){setAutoRenewLoading(false);}
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    consumeToken();
  }

  const consumeToken = () => {
    let body = {amount:tokenAmount,type:tokenType}
    let url = "/consume-token";
    setLoading(true);
    request("consume-token",url,"POST",body, {
      then: function(res){
        setLoading(false);
        refreshSubStatus();
      },
      catch: function(err){ toast.error(err.message); setLoading(false);},
      finally: function(){}
    });
  }

  return (
    <div className="account-type">
      <h3>Account Details</h3>
      <div className='general-block'>
        <div>Static Text Tokens: </div>
        <div className="value">
          {(accountResults.staticTextTokens !== undefined)?
          <><span className='big'>{NumberUtil.numberWithCommas(accountResults.staticTextTokens)}</span></>:
          "---"}
          <Link className="upgrade" to={"/ai-story-generator-pricing/top-ups/text"}>View Tokens</Link>
        </div>
      </div>
      <div className='general-block'>
        <div>Static Image Tokens: </div>
        <div className="value">
          {(accountResults.staticImageTokens !== undefined)?
          <><span className='big'>{NumberUtil.numberWithCommas(accountResults.staticImageTokens)}</span></>:
          "---"}
          <Link className="upgrade" to={"/ai-story-generator-pricing/top-ups/image"}>View Tokens</Link>
        </div>
      </div>
      <div className='general-block'>
        <div>Account Status: </div>
        <div className="value">
          {AccountStatusIdEnum[user.status]} 
          <Link className="upgrade" to={"/ai-story-generator-pricing"}>View Plans</Link>
        </div>
      </div>
      <div className='general-block'>
        <div>Monthly Text Tokens: </div>
        <div className="value">
          {(accountResults.monthlyTextRemaningTokens !== undefined && accountResults.monthlyTextTokenLimit !== undefined)?
          <><span className='big'>{NumberUtil.numberWithCommas(accountResults.monthlyTextRemaningTokens)}</span><span className='small'>/{NumberUtil.numberWithCommas(accountResults.monthlyTextTokenLimit)}</span></>:
          "---"}
        </div>
      </div>
      <div className='general-block'>
        <div>Monthly Image Tokens: </div>
        <div className="value">
        {(accountResults.monthlyImageRemaningTokens !== undefined && accountResults.monthlyImageTokenLimit !== undefined)?
          <><span className='big'>{NumberUtil.numberWithCommas(accountResults.monthlyImageRemaningTokens)}</span><span className='small'>/{NumberUtil.numberWithCommas(accountResults.monthlyImageTokenLimit)}</span></>:
          "---"}
        </div>
      </div>
      <div className='date-block'>
        {(accountResults.textTokenResetDate !== undefined)?
        <><span>Tokens Refill on {DateUtil.dateTimeSince(accountResults.textTokenResetDate)}</span></>:
        "---"}
      </div>

      {(ConfigVariables.TestMode)?
      <div className='test-buttons'>
        <form onSubmit={handleSubmit}>
          <input type="text" value={tokenAmount} onChange={(e) => {setTokenAmount(e.target.value)}} />
          <select value={tokenType} onChange={(e) => {setTokenType(e.target.value)}}>
            <option value="text">text</option>
            <option value="image">image</option>
          </select>
          <Button type="submit" value="Consume Monthly Token"/>
        </form>
      </div>
      :null}
      
      

      <Loading show={loading} size="24px" />

      {(hasActiveSub)?
        <div className={"sub-details"+((!hasActiveSub)?"":"")}>
          <h3>Subscription Details</h3>

          {(accountResults.plan)?
          <div className="plan-name">
            <div className="text">Plan Name:</div>
            <div className="value">
              {Config.Common.AppName+" "+StringUtil.capitalize(accountResults.plan)}
            </div>
          </div>
          :null}
          {(accountResults.platform)?
          <div className="general">
            <div className="text">Payment Method:</div>
            <div className="value">{accountResults.platform === PlatformEnum.Stripe?"Credit Card":StringUtil.capitalize(accountResults.platform)}</div>
          </div>
          :null}
          {(accountResults.periodEnd && accountResults.periodEnd !== "none")?
          <div className="period-end">
            <div className="text">Period End:</div>
            <div className="value">{prettifyDate(accountResults.periodEnd*1000)}</div>
          </div>
          :null}
          {(accountResults.billingRate)?
          <div className="plan-interval">
            <div className="text">Billing Rate:</div>
            <div className="value">{StringUtil.capitalize(accountResults.billingRate)}</div>
          </div>
          :null}
          {(accountResults.planAmount)?
          <div className="plan-cost">
            <div className="text">Plan Cost:</div>
            <div className="value">{formatCurrency(accountResults.planAmount)+" USD"}</div>
          </div>
          :null}
          {(accountResults.status)?
          <div className="general">
            <div className="text">Plan Status:</div>
            <div className="value">{accountResults.status}</div>
          </div>
          :null}
          
          {(accountResults.autoRenew !== undefined && accountResults.platform && accountResults.platform === PlatformEnum.Stripe)?
          <div className="auto-renew hlo">
            <div className="text">Auto Renew:</div>
            <SwitchToggle 
              isOn={subAutoRenew}
              onClickFunc={() => {toggleAutoRenew(subAutoRenew,autoRenewLoading)}}
              loading={autoRenewLoading}
              />
          </div>
          :null}

          {(accountResults.platform && accountResults.platform === PlatformEnum.Paypal && accountResults.paypalLink)?
          <div className="paypal">
            <a href={accountResults.paypalLink} target="_blank" rel="noopener noreferrer">PayPal Sub Info Link</a>
          </div>
          :null}
        </div>
      :null}
    </div>
  );

};

export default AccountType;