import React,{useEffect, useState} from 'react';
import './image-modal.scss';
import CarouselHeader from '../../common/carousel/carousel-header';
import Carousel from '../../common/carousel/carousel';
import Modal from '../../common/modal/modal';
import { clone } from '../../../utils/clone';
import Config from '../../../config/config';


const ImageModal = ({show,handleClose,images,initSlide}) => {

  const [slide,setSlide] = useState(1);
  const [localImages,setLocalImages] = useState([]);

  useEffect(() => {
    let newImages = clone(images);
    newImages.shift();
    setLocalImages(newImages)
  },[images]);

  useEffect(() => {
    let newSlide = 1;
    if(show === true){
      newSlide = initSlide
    }
    setSlide(newSlide);
  },[show,initSlide,])

  return (
    <Modal className={"image-modal"} show={show} handleClose={handleClose}>
      <div>
        {localImages.length?
        <>
        <CarouselHeader className={"image-modal-header"} slideTo={slide} forceResize={show}>
          {localImages.map((v,i) => {
            let url = v.url;
            if(url && url.includes("{{cdn}}")){
              url = url.replace("{{cdn}}",Config.Common.CdnUrl);
            }
            if(url && url.includes(".png")){
              url = url.replace(".png","");
            }
            return (
              <div className='header-slide' key={i} onClick={() => {setSlide(i+1)}}>
                <div className={'aspect-ratio aspect-ratio-1-1'}>
                  <img alt={"Modal Image"}
                    src={(url+"-small.png")} 
                    // srcSet={url+"-medium.png 800w, "+url+".png 1200w"} 
                    />
                </div>
              </div>
            )
          })}
        </CarouselHeader>
        <Carousel slideTo={slide} setSlide={setSlide} sideSlider={true} slideCount={localImages.length} sideBarPadding={12}>
          {localImages.map((v,i) => {
            let url = v.url;
            if(url.includes("{{cdn}}")){
              url = url.replace("{{cdn}}",Config.Common.CdnUrl);
            }
            if(url.includes(".png")){
              url = url.replace(".png","");
            }
            return (
              <div key={i} className='slide'>
                <div className={"aspect-ratio aspect-ratio-1-1"}>
                  <img alt={"Modal Image"}
                    src={(url+"-small.png")} 
                    srcSet={url+"-medium.png 800w, "+url+".png 1200w"} 
                    />
                </div>
              </div>
            )
          })}
        </Carousel>
        </>
        :<div>no images</div>}
      </div>
    </Modal>
  );

};

export default ImageModal;
