import React, { useEffect, useState } from 'react';
import './affiliate.scss';
import Config from '../../../config/config';
import * as StringUtil from '../../../utils/string';
import * as DateUtil from '../../../utils/date';
import {request,unSubRequest} from '../../../utils/request';
import { toast } from 'react-toastify';
import Button from '../../elements/button/button';
import Modal from '../../common/modal/modal';
import Loading from '../../common/loading/loading';
import SwitchToggle from '../../elements/switch-toggle/switch-toggle';
import CopyInput from '../../elements/copy-input/copy-input';
import AffiliateReferrals from './affiliate-referrals';

const Affiliate = () => {

  const appName = StringUtil.capitalize(Config.Common.AppName);
  const [loading,setLoading] = useState(false);
  const [affiliates,setAffiliates] = useState([]);
  const [show,setShow] = useState(false);
  const [modalValue,setModalValue] = useState(null);
  const [toggle,setToggle] = useState(true);
  const [display,setDisplay] = useState("");
  const [code,setCode] = useState("");
  
  const [showAffiliateReferralsModal,setShowAffiliateReferralsModal] = useState(false);

  useEffect(() => {
    fetchAffliliate();
    return () => {
      unSubRequest("affiliate-user");
      unSubRequest("send-affliliate");
    }
  },[])

  const fetchAffliliate = () => {
    setLoading(true);
    request("affiliate-user","/affiliate-user","GET", {}, {
      then: function(res){
        setAffiliates(res.data.res);
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false);}
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    sendAffiliate();
  }

  const sendAffiliate = () => {
    let method = "POST";
    let data = {
      useDisplay: (toggle)?1:0,
      display: display,
      code: code,
    }
    if(modalValue){
      data.uid = modalValue;
      method = "PATCH";
    }

    setLoading(true);
    request("send-affliliate","/affiliate",method, data, {
      then: function(res){
        setShow(false);
        fetchAffliliate();
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false);}
    });
  }

  return (
    <div className="affiliate-comp">
      <div className='affiliate-header'>
        <h3>Affiliate</h3>
        <button className='button view-referrals hide' onClick={() => {
          setShowAffiliateReferralsModal(true);
        }}>
          View Referrals
        </button>
      </div>

      <p>Refer users to {appName} and <span className="buff">Earn</span> <span className="buff">10%</span> of the {appName} profits made by that user <span className="buff">Forever</span>. </p>
      <p className="note">Profits meaning; Profit made by {appName} from the referred user. </p>
      
      <div className="affiliates">
        <Loading show={loading} size="24px"/>
        {(affiliates.length)?
          <div className="links">
            {affiliates.map((v,i,) => {
              let linkValue = Config.Common.FrontEndUrl+"/r/"+v.code;
              return (
                <div className="link" key={i}>
                  <CopyInput display={"Link"} value={linkValue} />
                  <div className="details">
                    <div className="specifics">
                      <div>
                        <div>Code: {v.code}</div>
                        <div>Display: {v.display} <span className="use-display">{(v.useDisplay)?"On":"Off"}</span></div>
                        <div>Reward: {v.reward}%</div>
                      </div>
                      <div>
                        <div>Status: {(v.active)?"Active":"Inactive"}</div>
                        <div>Created: {DateUtil.dateTimeSince(v.createdDate)}</div>
                        <div>Updated: {DateUtil.dateTimeSince(v.updatedDate)}</div>
                      </div>
                    </div>
                    <Button parentClassName="edit-button" status={"edit"} onClick={() => {
                      setModalValue(v.uid);
                      setDisplay(v.display);
                      setCode(v.code);
                      setToggle((v.useDisplay)?true:false);
                      setShow(true);
                    }}>Edit</Button>
                  </div>
                </div>
              )
            })}
          </div>
        :<div className="no-affiliate-links">No Affiliate Links</div>}
      </div>
      <Button status={"add"} type="button" onClick={() => {
        setModalValue(null)
        setShow(true);
      }}>Create New Affiliate Link</Button>

      <Modal className="modify-affiliate-link-modal-main" show={show} handleClose={() => {setShow(false)}}>
        <div className="modify-affiliate-link-modal">
          <h3>{(modalValue)?"Modify":"Create"} Affiliate Link</h3>
          <form onSubmit={handleSubmit}>
            <div className="toggle-box">
              <div>
                <label>Use Display Name</label>
                <p>Will show the user who they've been referred by.</p>
              </div>
              <SwitchToggle onClickFunc={() => {setToggle(!toggle)}} isOn={toggle} />
            </div>
            <div>
              <label>Display Name</label>
              <input type="text" disabled={!toggle} value={display} onChange={(e) => {setDisplay(e.target.value)}} />
            </div>
            <div>
              <label>Affiliate Code</label>
              <input type="text" value={code} onChange={(e) => {setCode(e.target.value)}} />
            </div>
            <Button type="submit" status={(loading)?"loading":"save"} value={((modalValue)?"Modify":"Create")+" Link"} />
          </form>
        </div>
      </Modal>

      <Modal 
        className="affiliate-referrals-modal-main" 
        show={showAffiliateReferralsModal} 
        handleClose={() => {setShowAffiliateReferralsModal(false)}}>
        <div className="affiliate-referrals-modal-content">
          <AffiliateReferrals load={showAffiliateReferralsModal} affiliates={affiliates} />
        </div>
      </Modal>

    </div>
  );

};

export default Affiliate;