import React, { useState,useEffect } from 'react';
import ReactDOM from 'react-dom'; // Import ReactDOM

import './paypal.scss';
import Config from '../../../config/config';
import useScript from '../../../utils/use-script';
import { request,unSubRequest } from '../../../utils/request';
import {toast} from 'react-toastify';
import Loading from '../../../components/common/loading/loading';

const Paypal = ({priceUid,oneTimePayment,oneTimePaymentType,price,callback}) => {

  // console.log("Paypal");
  // console.log("priceUid",priceUid);

  const [loading,setLoading] = useState(false);

  const paypalScriptUrl = oneTimePayment 
    ? `${Config.Common.PaypalBaseUrl}/sdk/js?client-id=${Config.Keys.PaypalPublicKey}&currency=USD`
    : `${Config.Common.PaypalBaseUrl}/sdk/js?client-id=${Config.Keys.PaypalPublicKey}&vault=true&intent=subscription`;
  console.log("paypalScriptUrl",paypalScriptUrl);
  const [loaded, error] = useScript(paypalScriptUrl);

  useEffect(() => {
    console.log("loaded",loaded);
  },[loaded])

  useEffect(() => {
    return () => {
      unSubRequest("paypal-save-sub-id");
    }
  },[])

  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [{
        amount: {
          value: price/100, // Replace with the price for one-time payment
        },
      }],
    });
  };

  const onApproveOneTimePayment = (data, actions) => {
    return actions.order.capture().then(details => {
      // console.log('Transaction completed by', details.payer.name.given_name);
      confirmPaymentSuccess(details);
    });
  };

  const confirmPaymentSuccess = (details) => {
    // console.log("details",details);
    let body = {id: details.id,type: oneTimePaymentType,}
    setLoading(true);
    request("paypal-one-time-payment","/paypal-one-time-payment","POST", body, {
      then: function(res){
        // console.log("res",res.data.res);
        setLoading(false);
        if (callback) {
          callback(true, details);
        }
      },
      catch: function(err){toast.error(err.message);setLoading(false);},
      finally: function(){}
    });
  }

  const createSubscription = (data, actions) => {
    return actions.subscription.create({
      'plan_id': priceUid // Replace with your plan ID
    });
  };

  const onApproveSubscription = (data, actions) => {
    // console.log("data",data);
    passSubIdToBackend(data.subscriptionID);
  };

  const passSubIdToBackend = (id,) => {
    let body = { subId: id, }
    setLoading(true);
    request("paypal-save-sub-id","/paypal-save-sub-id","POST", body, {
      then: function(res){
        // console.log("res",res.data.res);
        setLoading(false);
        if(callback){
          callback(true);
        }
      },
      catch: function(err){toast.error(err.message);setLoading(false);},
      finally: function(){}
    });
  }

  if (error) { 
    return <div className='paypayl-comp'>Failed to load PayPal SDK script.</div>; 
  }
  if (!loaded) { 
    return <div className='paypayl-comp'>Loading...</div>;  
  }

  let loadedButton = false;
  let PayPalButton = null;
  try{
    PayPalButton = window.paypal.Buttons.driver('react', { React, ReactDOM });
    loadedButton = true;
  }catch(e){}

  return (
    <div className="paypal-comp">
      <Loading show={loading}/>
      {loadedButton && oneTimePayment ?
        <PayPalButton
          createOrder={(data, actions) => createOrder(data, actions)}
          onApprove={(data, actions) => onApproveOneTimePayment(data, actions)}
        />
        : loadedButton ?
        <PayPalButton
          createSubscription={(data, actions) => createSubscription(data, actions)}
          onApprove={(data, actions) => onApproveSubscription(data, actions)}
        />
        : null
      }

    </div>
  );

};

export default Paypal;
