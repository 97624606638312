import React,{useState,useEffect} from 'react';
import './world-builder-new-item-modal.scss';
import Carousel from '../../common/carousel/carousel';
import * as StringUtil from '../../../utils/string';
import Textarea from 'react-textarea-autosize';
import Button from '../../elements/button/button';
import CoinButton from '../../elements/button/coin-button';
import Modal from '../../common/modal/modal';
import { clone } from '../../../utils/clone';
import CarouselHeader from '../../common/carousel/carousel-header';
import { toast } from 'react-toastify';
import { request,unSubRequest } from '../../../utils/request';
import SwitchToggle from '../../elements/switch-toggle/switch-toggle';
import WorldItemEnum from '../../../enums/world-item';
import Config from '../../../config/config';

let textareaVals = [
  "appearance","backstory","beliefs","traditions","socialNorms",
  "features","plotInfluence","description"
];

// let tables = {
//   characters:{
//     name:"Character",table:"characters",
//     columns:[`name`,`appearance`,`archetype`,`traits`,`backstory`],
//   },
//   locations:{
//     name:"Location",table:"locations",
//     columns:[`name`,`features`,`plotInfluence`], 
//   },
//   cultures:{
//     name:"Culture",table:"cultures",
//     columns:[`name`,`beliefs`,`traditions`,`socialNorms`,`backstory`], 
//   },
//   species:{
//     name:"Species",table:"species",
//     columns:[`name`,`appearance`,`lifespan`,`habitat`,`backstory`], 
//   },
//   conflicts:{
//     name:"Conflict",table:"conflicts",
//     columns:[`type`,`description`], 
//   },
//   religions:{
//     name:"Religion",table:"religions",
//     columns:[`name`,`practices`,`influence`], 
//   },
//   religionGods:{
//     name:"Relgion Diety",table:"religion_gods",
//     columns:[`name`,`appearance`,`traits`,`backstory`], 
//   },
// }

const WorldBuilderNewItemModal = ({show,setShow,table,worldUid,refreshTableCallback}) => {

  const [values,setValues] = useState({});
  const [slide,setSlide] = useState(1);
  const [prompt,setPrompt] = useState("");
  const [loading,setLoading] = useState(false);
  const [genResults,setGenResults] = useState([]);
  const [usePrompt,setUsePrompt] = useState(false);
  const [amount,setAmount] = useState("1");

  let worldItem = table in WorldItemEnum?WorldItemEnum[table]:{};
  let columns = worldItem.columns?worldItem.columns:[];
  let header = worldItem.name?worldItem.name:table;

  const handleSubmitSaveItem = (e) => {
    e.preventDefault();
    saveItem(worldUid,table,[values]);
  }

  const saveItem = (worldUid,table,valuesArr) => {
    let body = {
      worldUid: worldUid,
      table: table,
      valuesArr: valuesArr,
    }
    setLoading(true);
    request("save-item","/world-item/save","POST", body, {
      then: function(res){
        setValues({});
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){
        setLoading(false);
        if(refreshTableCallback){refreshTableCallback(table);}
      }
    });
  }

  const handleSubmitGenerateItems = (e) => {
    e.preventDefault();
    generateItems(worldUid,table,prompt,amount);
  }

  const generateItems = (worldUid,table,prompt,amount) => {
    let listGen = slide === 1?true:false;
    let body = {
      worldUid: worldUid,
      table: table,
      prompt: prompt,
      amount: parseInt(amount),
      listGen: listGen,
    }
    setLoading(true);
    request("generate-items","/world-item/generate","POST", body, {
      then: function(res){
        // console.log("res",res);
        let results = res.data.res.message;
        // console.log("results",results);
        results = results.replace("```json","");
        results = results.replace("```","");
        let newGenResults = [];
        try{
          newGenResults = JSON.parse(results);
          let overrideVal = "";
          for(let key in newGenResults){
            overrideVal = newGenResults[key];
            break;
          }
          newGenResults = overrideVal;
        }catch(e){toast.error("error parsing response")}
        setGenResults(newGenResults);
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false);}
    });
  }

  const completeItem = (worldUid,table,values,usePrompt,prompt) => {
    let body = {worldUid: worldUid,table: table,values:values,usePrompt:usePrompt,prompt: prompt,};
    setLoading(true);
    request("generate-items","/world-item/complete","POST", body, {
      then: function(res){
        let results = res.data.res.message;
        // console.log("results",results);
        results = results.replace("```json","");
        results = results.replace("```","");
        let newGenResults = {};
        try{
          let hold = JSON.parse(results);
          let overrideVal = "";
          for(let key in hold){overrideVal = hold[key];break;}
          hold = overrideVal;
          if(hold.length >= 1){newGenResults = hold[0];}
          // console.log("newGenResults",newGenResults);
        }catch(e){toast.error("error parsing response")};
        setValues(newGenResults);
        // setGenResults(newGenResults);
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false);}
    });
  }

  return (
    <Modal className={'world-builder-new-item-modal'} show={show} handleClose={() => {setShow(false)}}>
      <div>
        <CarouselHeader slideTo={slide} forceResize={show}>
          <div onClick={() => {setSlide(1)}}>List</div>
          <div onClick={() => {setSlide(2)}}>Detailed</div>
          <div onClick={() => {setSlide(3)}}>Blank</div>
        </CarouselHeader>
        <Carousel slideTo={slide}>
        <div className='slide-content'>
            <h4>Batch List New {header}(s)</h4>
            <form onSubmit={handleSubmitGenerateItems}>
              <div>
                <label>List {header} Prompt</label>
                <p className='note'>Just type the work of art you're trying to reference. {Config.Common.AppName} will try to create a list of 20 {header}(s) from that work of art. </p>
                <p className='note'>{Config.Common.AppName} will try not to create duplicate {header}(s)</p>
                <Textarea type="text" value={prompt} onChange={(e) => {
                  setPrompt(e.target.value);
                }}></Textarea>
              </div>
              <div className='buttons'>
                <div className='left'></div>
                <div className='right'>
                  <CoinButton 
                    text="Generate"
                    type="submit" 
                    loading={loading}
                    rightText={1}
                    tipText={("Uses 1 Text Token")}
                    />
                  {/* <Button status={loading?"loading":"forward"} type="submit" value="Generate" /> */}
                </div>
              </div>
            </form>

            {genResults.length === 0?null:
              <div className='gen-results'>
                <div className='header'>
                  <div className='left'>
                    <h4>Gen Results</h4>
                  </div>
                  <div className='right'>
                    <Button status={loading?"loading":"add"} onClick={() => {
                      saveItem(worldUid,table,genResults);
                      setGenResults([]);
                    }}>Add All</Button>
                  </div>
                </div>
                <div className='gen-item-results-list'>
                  {genResults.map((v,i) => {
                    // console.log("v",v);
                    return(
                      <div key={i} className='gem-list-item'>
                        <div className='details'>
                          {Object.keys(v).map((keyName, i2) => {
                            return(
                              <div className='detail' key={i2}>
                                <label>{StringUtil.prettifyCamelCase(keyName)}</label>
                                {(textareaVals.indexOf(keyName) !== -1)
                                ?<Textarea value={v[keyName]} onChange={(e) => {
                                  let newGenResults = clone(genResults);
                                  newGenResults[i][keyName] = e.target.value;
                                  setGenResults(newGenResults);
                                }}></Textarea>
                                :<input type="text" value={v[keyName]} onChange={(e) => {
                                  let newGenResults = clone(genResults);
                                  newGenResults[i][keyName] = e.target.value;
                                  setGenResults(newGenResults);
                                }} />}
                              </div>
                            )
                          })}
                        </div>
                        <div className='actions'>
                          <Button status={loading?"loading":"add"} onClick={() => {
                            saveItem(worldUid,table,[v]);
                            let newGenResults = clone(genResults);
                            newGenResults.splice(i,1);
                            setGenResults(newGenResults);
                          }}></Button>
                        </div>
                      </div>
                    )
                  })}
                </div>
                <div className='header'>
                  <div className='left'></div>
                  <div className='right'>
                    <Button status={loading?"loading":"add"} onClick={() => {
                      saveItem(worldUid,table,genResults);
                      setGenResults([]);
                    }}>Add All</Button>
                  </div>
                </div>
              </div>
            }
          </div>
          <div className='slide-content'>
            <h4>Create Multiple Detailed New {header}(s)</h4>
            <form onSubmit={handleSubmitGenerateItems}>
              <div>
                <label>New {header} Prompt</label>
                <p className='note'>Prompt AI to create a new {header}. Multiple options will be provided and you can select which to add. </p>
                <p className='note'>{Config.Common.AppName} will try not to create duplicate {header}(s)</p>
                <Textarea type="text" value={prompt} onChange={(e) => {
                  setPrompt(e.target.value);
                }}></Textarea>
              </div>
              <div className='buttons'>
                <div className='left'></div>
                <div className='right'>
                  <select value={amount} onChange={(e) => {setAmount(e.target.value)}}>
                    <option value="1">1 {header}</option>
                    <option value="2">2 {header}(s)</option>
                    <option value="3">3 {header}(s)</option>
                    <option value="4">4 {header}(s)</option>
                  </select>
                  <CoinButton 
                    text="Generate"
                    type="submit" 
                    loading={loading}
                    rightText={amount}
                    tipText={("Uses "+amount+" Text Token")}
                    />
                  {/* <Button status={loading?"loading":"forward"} type="submit" value="Generate" /> */}
                </div>
              </div>
            </form>

            {genResults.length === 0?null:
              <div className='gen-results'>
                <div className='header'>
                  <div className='left'>
                    <h4>Gen Results</h4>
                  </div>
                  <div className='right'>
                    <Button status={loading?"loading":"add"} onClick={() => {
                      saveItem(worldUid,table,genResults);
                      setGenResults([]);
                    }}>Add All</Button>
                  </div>
                </div>
                <div className='gen-item-results-list'>
                  {genResults.map((v,i) => {
                    // console.log("v",v);
                    return(
                      <div key={i} className='gem-list-item'>
                        <div className='details'>
                          {Object.keys(v).map((keyName, i2) => {
                            return(
                              <div className='detail' key={i2}>
                                <label>{StringUtil.prettifyCamelCase(keyName)}</label>
                                {(textareaVals.indexOf(keyName) !== -1)
                                ?<Textarea value={v[keyName]} onChange={(e) => {
                                  let newGenResults = clone(genResults);
                                  newGenResults[i][keyName] = e.target.value;
                                  setGenResults(newGenResults);
                                }}></Textarea>
                                :<input type="text" value={v[keyName]} onChange={(e) => {
                                  let newGenResults = clone(genResults);
                                  newGenResults[i][keyName] = e.target.value;
                                  setGenResults(newGenResults);
                                }} />}
                              </div>
                            )
                          })}
                        </div>
                        <div className='actions'>
                          <Button status={loading?"loading":"add"} onClick={() => {
                            saveItem(worldUid,table,[v]);
                            let newGenResults = clone(genResults);
                            newGenResults.splice(i,1);
                            setGenResults(newGenResults);
                          }}></Button>
                        </div>
                      </div>
                    )
                  })}
                </div>
                <div className='header'>
                  <div className='left'></div>
                  <div className='right'>
                    <Button status={loading?"loading":"add"} onClick={() => {
                      saveItem(worldUid,table,genResults);
                      setGenResults([]);
                    }}>Add All</Button>
                  </div>
                </div>
              </div>
            }
          </div>
          
          <div className='slide-content'>
            <div className='buttons'>
              <div className='left'></div>
              <div className='right'>
                <SwitchToggle isOn={usePrompt} onClickFunc={() => {setUsePrompt(!usePrompt)}}
                  textOn={"Use Prompt"} 
                  textOff={"Use Prompt"} 
                  width={112}
                  height={40}
                  divisor={8}
                  borderRadius={4}
                  />
                <CoinButton 
                  onClick={() => {
                    completeItem(worldUid,table,values,usePrompt,prompt);
                  }}
                  // text={"Complete "+header}
                  text={"Fill"}
                  type="button" 
                  loading={loading}
                  // rightText={usePrompt?"2":"1"}
                  // tipText={("Uses "+(usePrompt?"2":"1")+" Text Token")}
                  rightText={"1"}
                  tipText={("Uses 1 Text Token")}
                  tipPosition={"top"}
                  ></CoinButton>
              </div>
            </div>
            <form onSubmit={handleSubmitSaveItem}>
              {usePrompt?
                <div>
                  <label>Completion Prompt</label>
                  <p className='note'>Prompt AI to complete the {header} for you. Add details to define the {header} for you.</p>
                  <Textarea type="text" value={prompt} disabled={loading} onChange={(e) => {
                    setPrompt(e.target.value);
                  }}></Textarea>
                </div>
              :null}
              {columns.map((v,i) => {
                let useTextarea = textareaVals.indexOf(v) !== -1?true:false;
                return(
                  <div key={i}>
                    <label>{StringUtil.prettifyCamelCase(v)}</label>
                    {
                    (useTextarea)?
                      <Textarea type="text" value={values[v]?values[v]:""} disabled={loading} onChange={(e) => {
                        let newValues = clone(values);
                        newValues[v] = e.target.value;
                        setValues(newValues);
                      }}></Textarea>
                    :<input type="text" value={values[v]?values[v]:""} disabled={loading} onChange={(e) => {
                      let newValues = clone(values);
                      newValues[v] = e.target.value;
                      setValues(newValues);
                    }}/>
                    }
                    
                  </div>
                )
              })}
              <div className='buttons'>
                <div className='left'></div>
                <div className='right'>
                  <Button status={loading?"loading":"save"} type="submit" value="Save" />
                </div>
              </div>
            </form>
          </div>
        </Carousel>
        
        
      </div>
    </Modal>
  );
};

export default WorldBuilderNewItemModal;