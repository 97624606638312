import React from 'react';
import './projects.scss';

const Projects = () => {

  return (
    <div className="projects-comp">
      <h1>projects-comp</h1>
    </div>
  );

};

export default Projects;