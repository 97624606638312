import React from 'react';
import './terms-of-use.scss';
import Config from '../../../../config/config';
import {useMaskEmail} from '../../../../utils/use-mask-email';
import { Helmet } from "react-helmet";
import Seo from '../../../../data/seo';

const TermsOfUse = () => {

  const appName = Config.Common.AppName;
  const {maskedEmail} = useMaskEmail(Config.Common.SupportEmailName,Config.Common.SupportEmailDomain);
  const domain = "mythblender.com";
  const company = "MythBlender";
  const companyGroup = "MythBlender Group";

  return (
    <div className="terms-of-use">
      <Helmet>
        <title>{Seo.TermsOfUse.Title}</title>
        <meta name="description" content={Seo.TermsOfUse.Desc} />
        <meta name="keywords" content={Seo.TermsOfUse.Keywords} />
      </Helmet>
      <h1 id="terms-of-use">Terms of Use</h1>

      <p>These {appName} Terms of Use explain the relationship between {companyGroup} ("{company}") and you when you access and use <a rel="canonical" href={"https://"+domain}>{domain}</a> and its related domains and browser extensions</p>
      <p>Please read these {appName} terms of use (“Terms of Use”) carefully: by using <a rel="canonical"  href={"https://"+domain}>{domain}</a> (“Site”), you agree to be bound by these Terms of Use. If you do not agree with any of these terms, you are prohibited from using or accessing this site.</p>
      <p>These terms, together with our privacy policy, form an agreement between you and {company}. By using the {appName} services, you represent to us that you are legally competent to enter into this agreement. </p>

      <h2 id="description-of-service">Description of Service</h2>
      <p>{appName} is an online education platform.</p>

      <h2 id="registration-and-security">Registration and Security</h2>
      <p>By completing the registration process for any given Services, you are agreeing subject to the terms and conditions of these terms. You agree to provide {appName} with accurate and complete registration information and to promptly notify our support team <a href={"mailto:"+maskedEmail}>{maskedEmail}</a> in the event of any changes to any such information.</p>
      <p>Accounts registered by “bots” or other automated methods are not permitted. You are responsible for maintaining the security of your account. {company} cannot and will not be liable for any loss or damage from your failure to comply with this security obligation. You may not use the service for any illegal or unauthorized purpose. You must not, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws). Unless you have the express written permission from {company}, you may not distribute {appName} or any portion thereof to any third parties. You may not disassemble or reverse engineer {appName} for any purpose and at all times are bound by {appName} Terms of Use.</p>
      <p>You may use all of the free features of {appName} free of charge. Using features that are not free of charge as a “Premium” user may incur charges.</p>
      <p>You are responsible for keeping your password confidential. This means, that you don’t give it to anyone else, that you don’t permit or enable third parties to gain knowledge of it and that you take the necessary steps to guarantee its confidentiality. If your credentials are lost or misused or if you suspect that they have been lost or misused, you must notify us of this immediately via email at: <a href={"mailto:"+maskedEmail}>{maskedEmail}</a>.</p>

      <h2 id="payment-refunds-upgrading-and-downgrading-terms">Payment, Refunds, Upgrading and Downgrading Terms</h2>
      <p>Certain features are only accessible to users who have registered as Premium users and who pay for Premium accounts. 
        {/* You will find all the benefits and features of {appName} Premium Accounts on <a href={"https://"+domain+"/pricing"}>https://{domain}/pricing</a> */}
      </p>
      <ol>
        <li>A valid credit card is required for {appName} non-free users. Free users are not required to provide a credit card number or use any payment service.</li>
        <li>Charges are due at the beginning of each invoicing period. If you have a subscription, it will renew automatically until you disable auto renewal. Every further payment will be due at the beginning of the new payment cycle. As long as your Account remains active and in good standing, You will be charged the Subscription Fee even if You never use the service. You may, however, cancel Your Paid Plan at any time.</li>
        <li>If you cancel after your subscription renewal date, you will not receive a refund for any amounts that have been charged. Your cancellation will be effective at the end of your then-current subscription period, subject to applicable law, and you may use the Services until your cancellation is effective (unless your access is suspended or terminated as set forth below).</li>
        <li>If you are delayed with your payment obligations (including immediately after the first default), we will be entitled to block access to your {appName} Premium Account.</li>
        <li>Downgrading your Service may cause the loss of content, features, or capacity of the Service. {company} does not accept any liability for such loss.</li>
      </ol>
      <p>Our payment process is conducted by  <a href="https://stripe.com">Stripe.com</a>. They handle all of our payment processing.</p>

      <h2 id="cancellation-and-termination">Cancellation and Termination</h2>
      <p>Because of situations beyond the control of {company}, {company}, in its sole discretion, has the right to suspend or terminate your {appName} account and refuse any and all current or future use of the Service, or any other {appName} Service, for any reason at any time. Such termination of the Service will result in the deactivation of your account and cancellation of any existing subscription. {company} reserves the right to refuse service to anyone for any reason at any time. Following a termination pursuant to the foregoing sentence, {company} shall refund the pro rata portion of any Prices relating to the remaining term, as applicable.</p>

      <h2 id="modifications-to-the-service-and-prices">Modifications to the Service and Prices</h2>
      <ol>
        <li>
          <p>{company} reserves the right at any time and from time to time to modify or discontinue, temporarily or permanently, the Service (or any part thereof) with or without notice.</p>
        </li>
        <li>
          <p>Prices of all Services are subject to change. Changes will be displayed on the {appName} website
        (<a rel="canonical" href={"https://"+domain}>{domain}</a>) or the Service itself</p>
        </li>
        <li>
          <p>{company} shall not be liable to you or to any third party for any modification, price change, suspension or discontinuance of the Service.</p>
        </li>
      </ol>
      <p>{company} reserves the right to revise its Prices, including by increasing or adding new Prices, at any time on ten (10) days’ notice. Such notice may be sent to you by email to your most recently provided email address or posted on the Site or by any other manner chosen by {company} in its commercially reasonable discretion. You will be deemed to have received any such notice that is posted on the Site on the day it was posted. Your use of the Services after the ten (10) day notice period constitutes your acceptance of the new or revised Prices.</p>

      <h2 id="copyright-and-content-ownership">Copyright and Content Ownership</h2>
      <ol>
      <li>You are strictly prohibited from, and agree that you will not, adapt, edit, change, modify, transform, publish, republish, distribute, or redistribute {appName} or any elements, portions, or parts thereof, including without limitation, to any elements, portions, or parts of {appName} software (in any form or media) without {company} prior written consent. You agree not to use any automated data collection methods, data mining, robots, scraping or any data gathering methods of any kind on {appName} different Services.</li>
      <li>The look and feel of the Service is copyright {company}. All rights reserved. You may not duplicate, copy, or reuse any portion of the HTML/CSS, Javascript, or visual design elements or concepts without express written permission from {company}.</li>
      <li>If {company} discovers that you have used its copyrighted or other protected intellectual property in contravention of the terms described above, {company} may bring legal proceedings against you, seeking monetary damages and an injunction against you. You could also be ordered to pay legal fees and costs.</li>
      <li>{appName} reserves the right of ownership of all original works created on {appName} .</li>
      </ol>

      <h2 id="interruption-of-service">Interruption of service</h2>
      <p>{company} may, from time to time, need to interrupt {appName} Site or the Service itself for maintenance and other operational reasons, as determined in its sole discretion, and you shall not receive any compensation or refund for such interruptions, and you hereby release and agree to defend, indemnify, and hold harmless {company} and its parent companies, affiliates, and subsidiaries and their respective officers, directors, employees, consultants, agents, representatives, professional advisors, and contractors from and against any and all claims, costs (including without limitation attorneys’ fees), injuries, losses, or damages arising in connection with such interruptions.</p>

      <h2 id="disputes">Disputes</h2>
      <p>If there is any dispute about or involving {company} or Service, you agree that the dispute shall be governed by the United States laws, without regard to conflict of law provisions and you agree to the exclusive United States personal jurisdiction. No waiver of any breach of any provision of these Terms of Use shall constitute a waiver of any prior, concurrent, or subsequent breach of the same or any other provisions hereof, and no waiver shall be effective unless made in writing and signed by an authorized representative of the waiving party. The Service is controlled and operated by {company}. These Terms of Use and any and all other online policies and agreements promulgated by {company}, including without limitation {company}'s User Agreement (for Entrants) and User Agreement (for Sponsors) constitute the entire agreement between {company} and you with respect to the subject matter hereof, and supersedes all prior oral or written understandings, communications or agreements not specifically incorporated herein. The failure of {company} to exercise or enforce any right or provision of these Terms of Use shall not operate as a waiver of such right or provision. The section titles in these Terms of Use are for convenience only and have no legal or contractual effect. If a court of competent jurisdiction determines that any provision of these Terms of Use is invalid, unlawful, void or unenforceable, that provision shall be modified or severed to the maximum extent permitted by law; however, any and all other provisions shall remain valid and be given full force and effect in a valid and enforceable manner to accomplish the purposes of these Terms of Use.</p>

      <h2 id="responsibility-for-content">Responsibility for Content</h2>
      <p>{companyGroup} accepts no responsibility for texts, content, images, data and/or information uploaded by you or other {appName} users.</p>
      <p>{companyGroup} has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by {companyGroup} of the site. Use of any such linked website is at the user's own risk.</p>

      <h2 id="changes-to-terms-of-use">Changes to Terms of Use</h2>
      <p>{company} reserves the right to change or revise these Terms of Use at any time by posting any changes or revised Terms of Use on this website, {domain} at any time and in its sole discretion. By using this website you are agreeing to be bound by the then current version of these terms of service.</p>

      <h2 id="governing-law">Governing Law</h2>
      <p>These terms and conditions are governed by and construed in accordance with the laws of United States and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.</p>
    
    </div>
  );
};

export default TermsOfUse;