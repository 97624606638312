import React from 'react';
// import React,{useEffect, useState,} from 'react';
import './video-viewer.scss'

// import { toast } from 'react-toastify';
// import { request } from '../../../utils/request';

import YoutubeVideoViewer from './youtube-video-viewer';

const VideoViewer = ({uid,autoPlay,videoRef,onTimeUpdate}) => {

  return <YoutubeVideoViewer 
            uid={uid}
            autoPlay={autoPlay}
            videoRef={videoRef}
            onTimeUpdate={onTimeUpdate}
          />

  // const [loading,setLoading] = useState(false);
  // const [base64,setBase64] = useState(null);

  // useEffect(() => {
  //   if(uid){
  //     fetchVideo(uid);
  //   }else{
  //     setBase64(null);
  //   }
  // },[uid])

  // const fetchVideo = (uid,) => {
  //   setLoading(true);
  //   request("video-viewer","/video-viewer/"+uid,"GET", {}, {
  //     then: function(res){
  //       let base64 = res.data.res.base64;
  //       let contentType = res.data.res.contentType;
  //       let fullBase64 = "data:"+contentType+";base64,"+base64;
  //       setBase64(fullBase64);
  //       // toast.success("Video Fetched");
  //     },
  //     catch: function(err){toast.error(err.message);},
  //     finally: function(){setLoading(false);}
  //   });
  // }

  // return (
  //   <div className={'hlo video-viewer'}>
  //     {(loading)?
  //       <div className='loading-item loading-item-flow'></div>
  //     :((base64)?
  //       <div className='video-viewer-sub'>
  //         <video ref={videoRef} width="240" height="180" controls 
  //           autoPlay={autoPlay?true:false}
  //           src={base64} 
  //           onTimeUpdate={(e) => {
  //             if(onTimeUpdate){
  //               onTimeUpdate(e)
  //             }
  //           }}
  //           controlsList="nodownload">

  //         </video>
  //       </div>
  //     :<div className='no-video'><span>No Video</span></div>)}
  //   </div>
  // )
}

export default VideoViewer;