import React,{useState,useEffect,useRef} from 'react';
import './stripe.scss';
import Loading from '../../common/loading/loading';
import Button from '../../elements/button/button';
import {request, unSubRequest} from '../../../utils/request';
import {toast} from 'react-toastify';
import {capitalize} from '../../../utils/string';
import Payment from './payment';
import useUserState from '../../../store/user';
import * as ObjectUtil from '../../../utils/object';

const Stripe = ({priceUid,oneTimePayment,oneTimePaymentType,callback}) => {

  const {user} = useUserState();

  const [loading,setLoading] = useState(false);
  const [loadingPaymentMethods,setLoadingPaymentMethods] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [defaultPaymentMethodId,setDefaultPaymentMethodId] = useState(null);
  const paymentRef = useRef(null)

  useEffect(() => {
    return () => {
      unSubRequest("stripe-payment-method-sub");
      unSubRequest("stripe-subscriptions");
    }
  },[]);

  useEffect(() => {
    fetchPaymentDetails();
  },[]);

  const fetchPaymentDetails = () => {
    if(!user){ return;}
    setLoadingPaymentMethods(true);
    request("stripe-payment-method-sub","/stripe-payment-method","GET", {}, {
      then: function(res){
        setDefaultPaymentMethodId(res.data.res.defaultPaymentMethodId)
        setPaymentMethods(res.data.res.paymentMethods.data);
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoadingPaymentMethods(false);}
    });
  }
  
  const successCallback = (res,priceUid) => {
    setPaymentMethods([res.data.res]);
    if(oneTimePayment){
      performOneTimePayment(priceUid,null,null,true);
    }else{
      createSubscription(priceUid,null,null,true);
    }
  }
  const errorCallback = (err,activePriceId) => {
    toast.error("Error Processing");
    console.log("err",err);
    setLoading(false);
  }

  const createSubscription = (priceUid,paymentMethods,paymentRef,bypassPaymentMethodCheck) => {
    let hasPaymentMethod = false;
    if(bypassPaymentMethodCheck !== true){
      if(paymentMethods.length >= 1){hasPaymentMethod = true;}

      if(!hasPaymentMethod){
        if(paymentRef.current === null){
          toast.error("paymentRef.current null when it shouldnt be");
          return false;
        }else{
          paymentRef.current();
          return false;
        }
      }
    }

    const data = {priceId: priceUid,}
    setLoading(true);
    request("stripe-subscriptions","/stripe-subscriptions","POST", data, {
      then: function(res){
        toast.success("Successfully Subscribed");
        setLoading(false);
        if(callback !== undefined){callback();}
      },
      catch: function(err){toast.error(err.message);setLoading(false);},
      finally: function(){
      }
    });
  }

  const performOneTimePayment = (priceUid,paymentMethods,paymentRef,bypassPaymentMethodCheck) => {
    let hasPaymentMethod = false;
    if(bypassPaymentMethodCheck !== true){
      if(paymentMethods.length >= 1){hasPaymentMethod = true;}

      if(!hasPaymentMethod){
        if(paymentRef.current === null){
          toast.error("paymentRef.current null when it shouldnt be");
          return false;
        }else{
          paymentRef.current();
          return false;
        }
      }
    }

    const data = {priceId: priceUid,oneTimePaymentType:oneTimePaymentType,}
    setLoading(true);
    request("stripe-one-time-payment","/stripe-one-time-payment","POST", data, {
      then: function(res){
        toast.success("Payment Completed");
        setLoading(false);
        if(callback !== undefined){callback();}
      },
      catch: function(err){toast.error(err.message);setLoading(false);},
      finally: function(){
      }
    });
  }

  const displayPaymentMethod = (defaultPaymentMethodId,paymentMethods) => {
    let paymentMethod = null;
    if(paymentMethods.length === 0){
      return (<div></div>)
    }
    paymentMethods = ObjectUtil.toCamelCaseKeys(paymentMethods);
    for (let index = 0; index < paymentMethods.length; index++) {
      let item = paymentMethods[index];
      if(defaultPaymentMethodId === item.id){
        paymentMethod = item;
        break;
      }
    }
    if(paymentMethod === null){
      paymentMethod = paymentMethods[0];
    }

    return (
      <div className="payment-method">
        <div className="card-info">
          <div className="name">{paymentMethod.billingDetails.name}</div>
          <div className="digits">{capitalize(paymentMethod.card.brand)} •••• •••• •••• {paymentMethod.card.last4}</div>
          <div className="expiry">{paymentMethod.card.expMonth}/{paymentMethod.card.expYear}</div>
        </div>
        <div className="buttons">
          <button className="button clear" onClick={() => {setPaymentMethods([])}}>Enter New Card</button>
        </div>
      </div>
    )

  }

  return (
    <div className="stripe-comp">
      <div className="payment-details">
        <div className="payment-option-info">
          <div className="credit-card">
            <Loading show={loadingPaymentMethods} size={"24px"}/>
            {displayPaymentMethod(defaultPaymentMethodId,paymentMethods)}
            <Payment 
              hide={(paymentMethods.length >= 1) || (loadingPaymentMethods)}
              formSubmitedCallback={() => {setLoading(true)}}
              successCallback={(res) => {successCallback(res,priceUid)}}
              errorCallback={(err) => {errorCallback(err,priceUid)}}
              hideButton={true} 
              passRef={paymentRef} />
          </div>
        </div>
      </div>
      <Button type="button" status={(loading || loadingPaymentMethods)?"loading":"forward"} onClick={() => {
        if(oneTimePayment){
          performOneTimePayment(priceUid,paymentMethods,paymentRef)
        }else{
          createSubscription(priceUid,paymentMethods,paymentRef)
        }
      }}>{oneTimePayment?"Purchase":"Upgrade"}</Button>
    </div>
  );

};

export default Stripe;