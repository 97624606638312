// src/PlinkoBoard.js

import React, { useEffect, useState } from 'react';
import Matter from 'matter-js';
// import usePersistState from '../../../utils/use-persist-state';

function PlinkoBoard() {

  const [globalEngine,setGlobalEngine] = useState(null);
  const [ballX, setBallX] = useState(400); // Default center
  const [ballY, setBallY] = useState(10); // Default center

  const [resetInc,setResetInc] = useState(1);
  const [isRunning, setIsRunning] = useState(false);

  useEffect(() => {
    // Create an engine
    const engine = Matter.Engine.create();

    // Create a renderer
    const render = Matter.Render.create({
      element: document.getElementById('plinko-container'),
      engine: engine,
      options: {
        width: 800,
        height: 600,
        wireframes: false,
      }
    });

    // Create the pins
    const pinRadius = 1;
    const xOffset = 20;
    const yOffset = 80;
    const gap = 40;
    const rows = 12;
    const columns = 20;
    const pins = [];

    for (let i = 0; i < rows; i++) {
      for (let j = 0; j < columns; j++) {
        if (i % 2 === 0) {
          pins.push(
            Matter.Bodies.circle(
              xOffset + j * gap,
              yOffset + i * gap,
              pinRadius,
              {
                isStatic: true,
                render: {
                  fillStyle: 'white' 
                }
              }
            )
          );
        } else {
          pins.push(
            Matter.Bodies.circle(
              xOffset + gap / 2 + j * gap,
              yOffset + i * gap,
              pinRadius,
              {
                isStatic: true,
                render: {
                  fillStyle: 'white' 
                }
              }
            )
          );
        }
      }
    }

    const ball = Matter.Bodies.circle(ballX, ballY, 10, {
      render: {
        fillStyle: 'red'  // Change 'blue' to any color you want for the ball
      }
    });

    // Define compartments
    const compartmentCount = 30;  // Number of compartments
    const compartmentWidth = 800 / compartmentCount;
    const compartmentHeight = 50;
    const compartments = [];

    for (let i = 0; i <= compartmentCount; i++) {
      compartments.push(
        Matter.Bodies.rectangle(
          i * compartmentWidth,
          600 - (compartmentHeight / 2), // Adjusted position
          5,    // Width of the division barrier
          compartmentHeight,
          {
            isStatic: true,
            render: { fillStyle: 'white' },
            label: 'compartment-' + (i + 1)
          }
        )
      );
    }

    Matter.Events.on(render, 'afterRender', () => {
      const context = render.context;
      for (let i = 0; i < compartmentCount; i++) {
        context.font = '20px Arial';
        context.fillStyle = 'white';
        context.textAlign = 'center';
        context.fillText(
          (i + 1).toString(),
          i * compartmentWidth + compartmentWidth / 2,
          585  // Adjust position as needed
        );
      }
    });


    // Add numbers to each compartment
    const labels = [];
    for (let i = 0; i < compartmentCount; i++) {
      labels.push(
        Matter.Bodies.rectangle(
          i * compartmentWidth + compartmentWidth / 2,
          575, // Slightly above the compartments
          compartmentWidth,
          10,  // Height doesn't matter much here as it's not used for collision
          {
            isStatic: true,
            render: {
              fillStyle: 'transparent',
              strokeStyle: 'transparent',
              text: {
                content: (i + 1).toString(),
                size: 20,
                color: 'white',
              }
            }
          }
        )
      );
    }

     // Create the walls
     const leftWall = Matter.Bodies.rectangle(0, 300, 10, 600, { isStatic: true, render: { fillStyle: 'white' } });
     const rightWall = Matter.Bodies.rectangle(800, 300, 10, 600, { isStatic: true, render: { fillStyle: 'white' } });
 
     // Add the walls to the world
    //  Matter.World.add(engine.world, [leftWall, rightWall]);


    Matter.World.clear(engine.world);
    Matter.Engine.clear(engine);

    // Add everything to the world (make sure to spread ...compartments and ...labels)
    Matter.World.add(engine.world, [...pins, ball, ...compartments, ...labels,leftWall,rightWall]);

    // Run the engine and renderer
    Matter.Engine.run(engine);
    Matter.Render.run(render);

    setGlobalEngine(engine)

    // Cleanup on unmount
    return () => {
      Matter.Engine.clear(engine);
      Matter.Render.stop(render);
      render.canvas.remove();
      render.canvas = null;
      render.context = null;
      render.textures = {};
    };
  }, [ballX,ballY,resetInc]);

  const startSimulation = () => {
    Matter.Engine.run(globalEngine);
    setIsRunning(true);
  };

  const pauseSimulation = () => {
    Matter.Engine.clear(globalEngine);
    setIsRunning(false);
  };

  const reverseSimulation = () => {
    Matter.World.allBodies(globalEngine.world).forEach(body => {
      Matter.Body.setVelocity(body, { x: -body.velocity.x, y: -body.velocity.y });
    });
  };

  return (
    <div>
      <div id="plinko-container" style={{ width: '600px', height: '420px' }}></div>
      <div style={{ marginTop: '20px' }}>
        <label>Ball X</label>
        <input type="number" value={ballX} onChange={(e) => {
            setBallX(parseFloat(e.target.value))
          }}
        />
        <label>Ball Y</label>
        <input type="number" value={ballY} onChange={(e) => {
            setBallY(parseFloat(e.target.value))
          }}
        />
        <button onClick={() => setResetInc(resetInc + 1)}>Reset Ball</button>
        <button onClick={() => startSimulation()}>Start</button>
        <button onClick={() => pauseSimulation()}>Pause</button>
        <button onClick={() => reverseSimulation()}>Reverse</button>
      </div>
    </div>
  );
}

export default PlinkoBoard;
