import React,{useState,useEffect} from 'react';
import './community.scss';
import {clone} from "../../../utils/clone";

import { useHistory } from 'react-router-dom';
import Carousel from '../../common/carousel/carousel';
import CarouselHeader from '../../common/carousel/carousel-header';
import { toast } from 'react-toastify';

import Forum from './forum';
import Projects from './projects';

const SlideEnum = {
  Forum: {Key: 1, Path: "forum", Display: "Forum"},
  Projects: {Key: 2, Path: "projects", Display: "Projects"},
}

const Community = () => {

  const history = useHistory();
  let newSlide = 1;
  let currrentPathname = window.location.pathname.replace(/\/community/g,"").replace("/","");
  if(currrentPathname !== ""){
    for (let key in SlideEnum) {
      if(SlideEnum[key].Path === currrentPathname){
        newSlide = SlideEnum[key].Key; break;
      }
    }
  }

  const [slide,setSlide] = useState(newSlide);
  const [slidToArray,setSlidToArray] = useState([]);


  useEffect(() => {
    let newSlidToArray = slidToArray;
    if(newSlidToArray.indexOf(slide) === -1){
      newSlidToArray = clone(slidToArray);
      newSlidToArray.push(slide);
    }
    setSlidToArray(newSlidToArray);
  },[slide,slidToArray])

  useEffect(() => {
    history.listen((location) => {
      let newSlide = slide;
      let currrentPathname = location.pathname.replace(/\/community/g,"").replace("/","");
      if(currrentPathname !== ""){
        for (let key in SlideEnum) {
          if(SlideEnum[key].Path === currrentPathname){
            newSlide = SlideEnum[key].Key; 
            break;
          }
        }
      }else{
        newSlide = SlideEnum.Forum.Key;
      }
      setSlide(newSlide);
    })
  },[history,slide,])

  const updateUrl = (headerPath) => {
    let newPath = "/community/"+headerPath;
    window.history.pushState(null, headerPath, newPath)
  }

  return (
    <div className="community-comp">
      {/* <h3>Community</h3> */}
      <div className='community-carousel-header-container'>
        <CarouselHeader className={"community-carousel-header"} slideTo={slide}>
          <div onClick={() => {updateUrl(SlideEnum.Forum.Path);setSlide(SlideEnum.Forum.Key)}}>Forum</div>
          <div onClick={() => {toast.info("Coming Soon")}}>Live Workshops</div>
        </CarouselHeader>
      </div>

      {/* <div onClick={() => {updateUrl(SlideEnum.Projects.Path);setSlide(SlideEnum.Projects.Key)}}>Projects</div> */}
      
      <Carousel slideTo={slide}>
        <div><Forum/></div>
        <div><Projects/></div>
      </Carousel>
    </div>
  );

};

export default Community;