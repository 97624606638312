import React,{useState,useEffect} from 'react';
import './world-builder-center.scss';
import Carousel from '../../common/carousel/carousel';
import * as StringUtil from '../../../utils/string';
import Textarea from 'react-textarea-autosize';
import Button from '../../elements/button/button';
import CoinButton from '../../elements/button/coin-button';
import { toast } from 'react-toastify';
import { request,unSubRequest } from '../../../utils/request';
import { clone } from '../../../utils/clone';
import ConfirmModal from '../../common/modal/confirm-modal';
import { Redirect } from 'react-router-dom';
import SwitchToggle from '../../elements/switch-toggle/switch-toggle';
import WorldItemEnum from '../../../enums/world-item';

let textareaVals = [
    "appearance","backstory","beliefs","traditions","social norms",
    "features","plot influence","description","traits",
    "system","function","limitations","society impact",
    "lifespan","habitat","practices","influence",
];

const FormBuilder = ({setLocalValues,worldUid,values,item,refreshTableCallback,setConfirmUid}) => {
  let table = item;

  const [loading,setLoading] = useState(false);
  const [usePrompt,setUsePrompt] = useState(false);
  const [prompt,setPrompt] = useState("");

  let worldItem = table in WorldItemEnum?WorldItemEnum[table]:{};
  // let columns = worldItem.columns?worldItem.columns:[];
  let header = worldItem.name?worldItem.name:table;

  const handleSubmit = (e) =>{
    e.preventDefault();
    saveEditItem(values[item],table,refreshTableCallback);
  }
  const saveEditItem = (data,table,refreshTableCallback) => {
    // console.log("data",data);
    // console.log("table",table);
    // console.log("refreshTableCallback",refreshTableCallback);
    data = clone(data);

    let deleteKeys = [];
    for(let key in data){
      let newKey = key.replace(table,"");
      newKey = StringUtil.pascalCaseToCamelCase(newKey);
      deleteKeys.push(key);
      if(newKey === "createdDate"){
        continue;
      }
      data[newKey] = data[key];
    }
    for (let i = 0; i < deleteKeys.length; i++) {
      const deleteKey = deleteKeys[i];
      delete data[deleteKey];
    }

    let body = {
      data,
      table,
    }

    setLoading(true);
    request("edit-item","/world-item/edit","POST", body, {
      then: function(res){toast.success("Saved");},
      catch: function(err){toast.error(err.message);},
      finally: function(){
        setLoading(false);
        if(refreshTableCallback){refreshTableCallback(table)}
      }
    });
  }

  const completeItem = (worldUid,table,values,usePrompt,prompt) => {
    let cleanValues = {};
    if(!table in values){toast.error("table not in values");return;}
    let item = values[table];
    let worldItem = WorldItemEnum[table];
    for(let key in item){
      let newKey = key.replace(table,"");
      newKey = StringUtil.pascalCaseToCamelCase(newKey);
      if(worldItem.columns.indexOf(newKey) !== -1 || newKey === "uid"){
        cleanValues[newKey] = item[key];
      }
    }

    let body = {worldUid: worldUid,table: table,values:cleanValues,usePrompt:usePrompt,prompt: prompt,};
    setLoading(true);
    request("generate-items","/world-item/complete","POST", body, {
      then: function(res){
        let results = res.data.res.message;
        // console.log("results",results);
        results = results.replace("```json","");
        results = results.replace("```","");
        let newGenResults = {};
        try{
          let hold = JSON.parse(results);
          let overrideVal = "";
          for(let key in hold){overrideVal = hold[key];break;}
          hold = overrideVal;
          if(hold.length >= 1){newGenResults = hold[0];}
          // console.log("newGenResults",newGenResults);
        }catch(e){toast.error("error parsing response")};
        // console.log("values",values);
        // console.log("newGenResults",newGenResults);

        let newValues = clone(values);
        for(let key in newGenResults){
          let newKey = StringUtil.capitalize(key);
          newKey = table+newKey;
          if(newKey in  newValues[table]){
            newValues[table][newKey] = newGenResults[key];
          }
        }
        setLocalValues(newValues)
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false);}
    });
  }
  

  let uid = "";
  if(item in values){
    for(let key in values[item]){
      let label = key.replace(item,"");
      if(label === "Uid"){
        uid = values[item][key];
        break;
      }
    }
  }

  return (
    <form onSubmit={handleSubmit} key={item} className='form-builder'>
      <div className='buttons-top'>
        <div className='left'></div>
        <div className='right'>
          <SwitchToggle isOn={usePrompt} onClickFunc={() => {setUsePrompt(!usePrompt)}}
            textOn={"Use Prompt"} 
            textOff={"Use Prompt"} 
            width={112}
            height={40}
            divisor={8}
            borderRadius={4}
            />
          <CoinButton 
            onClick={() => {
              completeItem(worldUid,table,values,usePrompt,prompt);
            }}
            // text={"Fill "+header}
            text={"Fill"}
            type="button" 
            loading={loading}
            // rightText={usePrompt?"2":"1"}
            // tipText={("Uses "+(usePrompt?"2":"1")+" Text Token")}
            rightText={"1"}
            tipText={("Uses 1 Text Token")}
            tipPosition={"top"}
            ></CoinButton>
        </div>
      </div>

      {usePrompt?
        <div>
          <label>Fill In Prompt</label>
          <p className='note'>
            Prompt AI to fill the {header} in for you. It can also expand the {header} for you.
          </p>
          <Textarea type="text" value={prompt} disabled={loading} 
            placeholder={'i.e. Add more backstory to this '+header}
            onChange={(e) => {
            setPrompt(e.target.value);
          }}></Textarea>
        </div>
      :null}

      {values && values[item]?Object.keys(values[item]).map((keyName, i) => {
        // console.log("values[item][keyName]",keyName,values[item][keyName]);
        let label = keyName.replace(item,"");
        if(!label) return null;
        if(label.includes("Uid") || label.includes("Date") || label.includes("Image") || label.includes("Prompt") ){return null;}
        label = StringUtil.prettifyCamelCase(label).trim();
        let val = values[item][keyName]
        return(
          <div key={i}>
            <label>{label}</label>
            {(textareaVals.indexOf(label.toLowerCase().trim()) !== -1)?
              <Textarea key={item+"-"+i} value={val} disabled={loading} onChange={(e) => {
                let newLocalValues = clone(values);
                newLocalValues[item][keyName] = e.target.value;
                setLocalValues(newLocalValues);
              }}></Textarea>
            :
              <input key={item+"-"+i} type="text" disabled={loading} value={val} onChange={(e) => {
                let newLocalValues = clone(values);
                newLocalValues[item][keyName] = e.target.value;
                setLocalValues(newLocalValues);
              }}/>
            }
          </div>
        )
      }):null}
      <div className='buttons'>
        <div className='left'>
          <Button parentClassName="delete" status="delete" onClick={() => {
            setConfirmUid({
              table: table,
              uid: uid,
            });
          }}></Button>
        </div>
        <div className='right'>
          <Button type="submit" value="Save Changes" status={loading?"loading":"save"} />
        </div>
      </div>
    </form>
  )
}

const WorldBuilderCenter = ({slide,world,values,saveEditWorld,refreshTableCallback}) => {

  const [loading,setLoading] = useState(false);
  const [localValues,setLocalValues] = useState([]);
  const [confirmUid,setConfirmUid] = useState(null);
  const [confirmWorldUid,setConfirmWorldUid] = useState(null);
  const [localWorld,setLocalWorld] = useState(world);
  const [redirect,setRedirect] = useState(null);
  const [usePrompt,setUsePrompt] = useState(false);
  const [prompt,setPrompt] = useState("");


  useEffect(() => {
    setLocalWorld(world);
  },[world]);

  useEffect(() => {
    // console.log("values",values);
    setLocalValues(values);
  },[values]);

  const handleSubmit = (e) =>{
    e.preventDefault();
    let passLocalWorld = clone(localWorld);
    delete passLocalWorld['worldSettings']
    saveEditWorld(world.uid,passLocalWorld);
  }

  const deleteWorld = (uid) => {
    let data = {uid,}
    setLoading(true);
    request("delete-world","/world/delete","POST", data, {
      then: function(res){
        setRedirect("/dashboard");
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false);}
    });
  }

  const deleteItem = (table,uid,refreshTableCallback) => {
    let data = {table,uid};
    setLoading(true);
    request("delete-item","/world-item/delete","POST", data, {
      then: function(res){toast.success("Saved");},
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false);
        if(refreshTableCallback){refreshTableCallback(table,true)}
      }
    });
  }

  const completeWorld = (world,usePrompt,prompt) => {

    console.log("completeWorld");
    console.log("world",world);
    console.log("usePrompt",usePrompt);
    console.log("prompt",prompt);

    let body = {world: world,usePrompt:usePrompt,prompt: prompt,};
    setLoading(true);
    request("generate-items","/world/complete","POST", body, {
      then: function(res){
        // console.log("res",res);
        let results = res.data.res.message;
        results = results.replace("```json","");
        results = results.replace("```","");
        let newWorld = clone(localWorld);
        try{
          let hold = JSON.parse(results);
          newWorld.name = hold.worldName;
          newWorld.backstory = hold.worldBackstory;
          newWorld.magicAndTech = hold.magicAndTech;
        }catch(e){toast.error("error parsing response")};
        setLocalWorld(newWorld);
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false);}
    });
  }

  if(redirect){
    return(<Redirect to={redirect}/>)
  }

  return (
    <div className="world-builder-center-comp">
      <Carousel slideTo={slide}>
        <div className='slide world-slide'>
          <form onSubmit={handleSubmit} className='form-builder'>
            <div className='buttons-top'>
              <div className='left'></div>
              <div className='right'>
                <SwitchToggle isOn={usePrompt} onClickFunc={() => {setUsePrompt(!usePrompt)}}
                  textOn={"Use Prompt"} 
                  textOff={"Use Prompt"} 
                  width={112}
                  height={40}
                  divisor={8}
                  borderRadius={4}
                  />
                <CoinButton 
                  onClick={() => {
                    completeWorld(world,usePrompt,prompt);
                  }}
                  text={"Fill"}
                  type="button" 
                  loading={loading}
                  rightText={"1"}
                  tipText={("Uses 1 Text Token")}
                  tipPosition={"top"}
                  ></CoinButton>
              </div>
            </div>
            {usePrompt?
              <div>
                <label>Fill In Prompt</label>
                <p className='note'>
                  Prompt AI to fill the World in for you. It can also expand the World for you.
                </p>
                <Textarea type="text" value={prompt} disabled={loading} 
                  placeholder='i.e. Add more backstory to this world'
                  onChange={(e) => {
                  setPrompt(e.target.value);
                }}></Textarea>
              </div>
            :null}
            <div>
              <label>Name</label>
              <input type="text" value={localWorld && localWorld.name?localWorld.name:""} 
                disabled={loading} 
                onChange={(e) => {
                  let newWorld = clone(localWorld);
                  newWorld.name = e.target.value;
                  setLocalWorld(newWorld);
                }}/>
            </div>
            <div>
              <label>Backstory</label>
              <Textarea value={localWorld && localWorld.backstory?localWorld.backstory:""} 
                disabled={loading} 
                onChange={(e) => {
                  let newWorld = clone(localWorld);
                  newWorld.backstory = e.target.value;
                  setLocalWorld(newWorld); 
                }}
              ></Textarea>
            </div>
            <h4>World's Magic and Tech</h4>
            {localWorld && localWorld["magicAndTech"]?Object.keys(localWorld["magicAndTech"]).map((keyName, i) => {
              // console.log("values[item][keyName]",keyName,values[item][keyName]);
              let label = keyName;
              label = StringUtil.prettifyCamelCase(label).trim();
              let item = "magicAndTech";
              let val = localWorld[item][keyName];
              return(
                <div key={i}>
                  <label>{label}</label>
                  {(textareaVals.indexOf(label.toLowerCase().trim()) !== -1)?
                    <Textarea key={item+"-"+i} value={val} disabled={loading} onChange={(e) => {
                      let newWorld = clone(localWorld);
                      newWorld[item][keyName] = e.target.value;
                      setLocalWorld(newWorld);
                    }}></Textarea>
                  :
                    <input key={item+"-"+i} type="text" disabled={loading} value={val} onChange={(e) => {
                      let newWorld = clone(localWorld);
                      newWorld[item][keyName] = e.target.value;
                      setLocalWorld(newWorld);
                    }}/>
                  }
                </div>
              )
            }):null}
            <div className='buttons'>
              <div className='left'>
                <Button parentClassName="delete" status="delete" onClick={() => {
                  let worldUid = world && world.uid?world.uid:"";
                  setConfirmWorldUid(worldUid);
                }}></Button>
              </div>
              <div className='right'>
                <Button type="submit" value="Save Changes" status={loading?"loading":"save"} />
              </div>
            </div>
          </form>
        </div>
        <div className='slide'><div>
          {"characters" in localValues?
            <FormBuilder setLocalValues={setLocalValues} 
            worldUid={world && world.uid?world.uid:""}
            values={localValues} 
            item="characters" 
            setConfirmUid={setConfirmUid}
            refreshTableCallback={refreshTableCallback} />
          :<div className='item-empty'><div>Select a Character from the right</div></div>}
        </div></div>
        <div className='slide'><div>
        {"locations" in localValues?
          <FormBuilder setLocalValues={setLocalValues} 
            worldUid={world && world.uid?world.uid:""}
            values={localValues} 
            item="locations" 
            setConfirmUid={setConfirmUid}
            refreshTableCallback={refreshTableCallback} />  
          :<div className='item-empty'><div>Select a Location from the right</div></div>}
        </div></div>
        <div className='slide'><div>
        {"cultures" in localValues?
          <FormBuilder setLocalValues={setLocalValues} 
            worldUid={world && world.uid?world.uid:""}
            values={localValues} 
            item="cultures" 
            setConfirmUid={setConfirmUid}
            refreshTableCallback={refreshTableCallback} />  
          :<div className='item-empty'><div>Select a Culture from the right</div></div>}
        </div></div>
        <div className='slide'><div>
        {"species" in localValues?
          <FormBuilder setLocalValues={setLocalValues} 
            worldUid={world && world.uid?world.uid:""}
            values={localValues} 
            item="species" 
            setConfirmUid={setConfirmUid}
            refreshTableCallback={refreshTableCallback} />  
          :<div className='item-empty'><div>Select a Species from the right</div></div>}
        </div></div>
        <div className='slide'><div>
        {"conflicts" in localValues?
          <FormBuilder setLocalValues={setLocalValues} 
            worldUid={world && world.uid?world.uid:""}
            values={localValues} 
            item="conflicts" 
            setConfirmUid={setConfirmUid}
            refreshTableCallback={refreshTableCallback} />  
          :<div className='item-empty'><div>Select a Conflict from the right</div></div>}
        </div></div>
        <div className='slide'><div>
        {"religions" in localValues?
          <FormBuilder setLocalValues={setLocalValues} 
            worldUid={world && world.uid?world.uid:""}
            values={localValues} 
            item="religions" 
            setConfirmUid={setConfirmUid}
            refreshTableCallback={refreshTableCallback} />  
          :<div className='item-empty'><div>Select a Religion from the right</div></div>}
        </div></div>
        <div className='slide'><div>
        {"religionGods" in localValues?
          <FormBuilder setLocalValues={setLocalValues} 
            worldUid={world && world.uid?world.uid:""}
            values={localValues} 
            item="religionGods" 
            setConfirmUid={setConfirmUid}
            refreshTableCallback={refreshTableCallback} />  
          :<div className='item-empty'><div>Select a Diety from the right</div></div>}
        </div></div>
        <div className='slide'><div>
        {"factions" in localValues?
          <FormBuilder setLocalValues={setLocalValues} 
            worldUid={world && world.uid?world.uid:""}
            values={localValues} 
            item="factions" 
            setConfirmUid={setConfirmUid}
            refreshTableCallback={refreshTableCallback} /> 
          :<div className='item-empty'><div>Select a Factions from the right</div></div>}
        </div></div>
        <div className='slide'><div>
          <div className='coming-soon-item'>
            <div>Stories - Coming Soon</div>
          </div>
        </div></div>
        <div className='slide'><div>
          <div className='coming-soon-item'>
            <div>Languages - Coming Soon</div>
          </div>
        </div></div>
      </Carousel>

      <ConfirmModal show={confirmWorldUid} title="Confirm Delete World" handleClose={(confirmed) => {
          if(confirmed){
            try{
              deleteWorld(confirmWorldUid);
            }catch(e){console.log("e",e);}
          }
          setConfirmWorldUid(null)
        }}>
        <div>
          Are you sure you want to delete this world? This will delete everything inside of the world.
        </div>
      </ConfirmModal>

      <ConfirmModal show={confirmUid} title="Confirm Delete" handleClose={(confirmed) => {
          if(confirmed){
            try{
              let {table,uid} = confirmUid;
              deleteItem(table,uid,refreshTableCallback);
            }catch(e){console.log("e",e);}
          }
          setConfirmUid(null)
        }}>
        <div>
          Are you sure you want to delete this?
        </div>
      </ConfirmModal>

    </div>
  );

};

export default WorldBuilderCenter;