

const PlanEnum = {
  Basic: "basic",
  Lite: "lite",
  Premium: "premium",
  Pro: "pro",
  Elite: "elite",
}

export default PlanEnum;