import React, { useState, useCallback } from 'react';
import './stream.scss';

import Config from '../../../config/config';
// import { AiChatServiceClient } from './generated/cellplus_ai_grpc_pb';
// import { ChatCompletionRequest } from './generated/cellplus_ai_pb';


// Assuming you have a gRPC-web compatible client setup
// const client = new AiChatServiceClient(Config.Common.ApiBaseUrl, null, null);

const Stream = () => {

  // const [userInput, setUserInput] = useState("");
  // const [aiResponse, setAiResponse] = useState("");

  // const handleUserInputChange = (event) => {
  //   setUserInput(event.target.value);
  // };

  // const handleSendMessage = useCallback(() => {
  //   const request = new ChatCompletionRequest();
  //   request.setInput(userInput);

  //   const call = client.chatCompletion(request, {});

  //   call.on('data', (response) => {
  //     // console.log(`AI says: ${response.getPart().getPart()}`);
  //     // Assuming `response.getPart().getPart()` is valid and returns the expected response string
  //     setAiResponse(`AI says: ${response.getPart().getPart()}`);
  //   });

  //   call.on('end', () => {
  //     console.log('AI response complete.');
  //   });
  // }, [userInput]);

  return (
    <div className="stream">
      <h1>Stream</h1>
      {/* <div>
        <input
          type="text"
          value={userInput}
          onChange={handleUserInputChange}
          placeholder="Enter your message"
        />
        <button onClick={handleSendMessage}>Send</button>
        {aiResponse && <p>{aiResponse}</p>}
      </div> */}
    </div>
  );

};

export default Stream;