import React,{useState} from 'react';
import './world-builder-images.scss';
import CarouselHeader from '../../common/carousel/carousel-header';
import Carousel from '../../common/carousel/carousel';
import Button from '../../elements/button/button';
import { toast } from 'react-toastify';
import { request,unSubRequest } from '../../../utils/request';
import Modal from '../../common/modal/modal';
import ConfirmModal from '../../common/modal/confirm-modal';
import ImageModal from './image-modal';
import Config from '../../../config/config';
import CopyInput from '../../elements/copy-input/copy-input';


const WorldBuilderImages = ({setShow,images,slide,setSlide,refreshCallback}) => {

  const [loading, setLoading] = useState(false);
  const [confirmDeleteUid, setConfirmDeleteUid] = useState(null);
  const [showViewImageModal,setShowViewImageModal] = useState(false);
  const [initImageSlideModal,setInitImageSlideModal] = useState(1);

  const deleteImage = (uid) => {
    setLoading(true);
    request("delete-image","/world-item/delete-image","POST", {uid,}, {
      then: function(res){
        if(refreshCallback){
          refreshCallback();
        }
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false);}
    });
  }

  const moveImage = (uid) => {
    setLoading(true);
    request("move-image","/world-item/move-image","POST", {uid,}, {
      then: function(res){
        if(refreshCallback){
          refreshCallback();
        }
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false);}
    });
  }

  return (
    <div className="world-builder-image-comp">
      <CarouselHeader className={"world-builder-image-header hlo"} slideTo={slide} forceResize={slide} >
        {images.map((v,i) => {
          // if(images.length-1 === i){
          if(i === 0){
            return (
              <div key={i} className='add-div slide header-slide' onClick={() => {setSlide(i+1)}}>
                <Button status="add" onClick={() => {setShow(true)}}></Button>
              </div>
            )
          }
          let url = v.url;
          if(url && url.includes("{{cdn}}")){
            url = url.replace("{{cdn}}",Config.Common.CdnUrl);
          }
          if(url && url.includes(".png")){
            url = url.replace(".png","");
          }
          
          return (
            <div className='header-slide' key={i} onClick={() => {
                let newSlide = i+1;
                if(newSlide === slide){
                  setInitImageSlideModal(i);
                  setShowViewImageModal(true);
                }
                setSlide(newSlide)
              }}>
              <div className={'aspect-ratio aspect-ratio-1-1'}>
                <img alt={"Modal Image"}
                    src={(url+"-small.png")} 
                    // srcSet={url+"-medium.png 800w, "+url+".png 1200w"} 
                    />
              </div>
            </div>
          )
        })}
      </CarouselHeader>
      <Carousel className="world-builder-image-carousel hlo" useAutoHeight={false} slideTo={slide} setSlide={setSlide} sideSlider={true} slideCount={images.length} sideBarPadding={12}>
        {images.map((v,i) => {
          if(i === 0){
          // if(images.length-1 === i){
            return (
              <div key={i} className='add-div image slide'>
                <Button status="add" onClick={() => {setShow(true)}}></Button>
              </div>
            )
          }
          let url = v.url;
          if(url && url.includes("{{cdn}}")){
            url = url.replace("{{cdn}}",Config.Common.CdnUrl);
          }
          if(url && url.includes(".png")){
            url = url.replace(".png","");
          }
          return (
            <div key={i} className='slide'>
              <div className={"aspect-ratio aspect-ratio-1-1"}>
                <img alt={"Modal Image"}
                  src={(url+"-small.png")} 
                  srcSet={url+"-medium.png 800w, "+url+".png 1200w"} 
                  />
              </div>
              <div className='slide-clickable'>
                <div className='slide-clickable-container'>
                  <div className='slide-clickable-top' onClick={() => {
                    setShowViewImageModal(true);
                  }}></div>
                  <div className='slide-clickable-bottom'>
                    <Button parentClassName={"clearish"} status={loading?"loading":"up"} onClick={() => {
                      moveImage(v.uid);
                    }}></Button>
                    <div className='copy-button'>
                      <CopyInput 
                        value={url+".png"}
                        callback={() => {
                          toast.info("Copied Image Url");
                        }}/>
                    </div>
                    <Button parentClassName={"clearish"} status={loading?"loading":"delete"} onClick={() => {
                      setConfirmDeleteUid(v.uid);
                    }}></Button>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </Carousel>


      <ImageModal 
        images={images}
        show={showViewImageModal}
        initSlide={initImageSlideModal}
        handleClose={() => {
          setShowViewImageModal(false)
        }}
        />


      <ConfirmModal 
          show={confirmDeleteUid !== null?true:false} 
          title="Confirm Delete Image"
          handleClose={(confirm) => {
            if(confirm){
              deleteImage(confirmDeleteUid);
            }
            setConfirmDeleteUid(null);
          }}>
        <div>
          Are you sure you want to delete this image?
        </div>
      </ConfirmModal>
      
    </div>
  );

};

export default WorldBuilderImages;

