import React, {useState,useEffect,} from 'react';
import './course.scss';
import {clone} from '../../../utils/clone';
import { toast } from 'react-toastify';
import { request } from '../../../utils/request';
import VideoViewer from '../../common/video-viewer/video-viewer';
import { Link, } from 'react-router-dom';
import {ReactComponent as CheckSvg} from '../../../css/imgs/icon-check.svg';
import {ReactComponent as ForwardSvg} from '../../../css/imgs/icon-arrow-forward.svg';
import {ReactComponent as ExpandMoreSvg} from '../../../css/imgs/icon-expand-more.svg';
import {ReactComponent as ExpandLessSvg} from '../../../css/imgs/icon-expand-less.svg';
import {ReactComponent as PersonSvg} from '../../../css/imgs/icon-person.svg';
import {ReactComponent as VideoSvg} from '../../../css/imgs/icon-video.svg';
import {ReactComponent as CodeSvg} from '../../../css/imgs/icon-code.svg';
import {ReactComponent as QuizSvg} from '../../../css/imgs/icon-quiz.svg';
import Content from './content';
import Config from '../../../config/config';


const Course = ({uid,contentUid}) => {

  const [didMountContentUid,setDidMountContentUid] = useState(false);
  const [course,setCourse] = useState(null);
  const [sections,setSections] = useState([]);
  const [teacher,setTeacher] = useState(null);
  const [expandSections,setExpandSections] = useState([]);

  const [activeSection,setActiveSection] = useState(null);
  const [activeContent,setActiveContent] = useState(null);
  const [nextSection,setNextSection] = useState(null);

  const [loading,setLoading] = useState(false);

  useEffect(() => {
    if(uid){
      fetchCourse(uid);
    }
  },[uid])

  useEffect(() => {
    if(course !== null && didMountContentUid === false){

      let newActiveContent = activeContent;
      let newActiveSection = activeSection;
      dance:  for (let i = 0; i < sections.length; i++) {
        const section = sections[i];
        for (let k = 0; k < section.contents.length; k++) {
          const content = section.contents[k];
          if(content.uid === contentUid){
            newActiveContent = content;
            newActiveSection = section;
            break dance;
          }
        }
      }

      setActiveSection(newActiveSection);
      setActiveContent(newActiveContent);
      setDidMountContentUid(true);
    }
  },[course,sections,contentUid,didMountContentUid,activeSection,activeContent,nextSection])

  useEffect(() => {
    let newNextSection = null;
    if(activeSection !== null){
      for (let i = 0; i < sections.length; i++) {
        const section = sections[i];
        if(section.uid === activeSection.uid){
          if(sections[i+1] !== undefined){
            newNextSection = sections[i+1];
          }
          break;
        }
      }
    }
    setNextSection(newNextSection);
  },[course,sections,contentUid,didMountContentUid,activeSection,activeContent,nextSection])


  const goToContent = ({section,content}) => {
    window.scrollTo({top: 0, left: 0});
    let url = "/course/"+uid+"/"+content.uid;
    window.history.replaceState("", "", url);
    setActiveSection(section);
    setActiveContent(content);
  }

  const backToCourse = (courseUid) => {
    let url = "/course/"+courseUid;
    window.history.replaceState("", "", url);
    setActiveSection(null);
    setActiveContent(null);
  }

  const fetchCourse = (uid) => {
    setLoading(true);
    request("fetch-course","/fetch-course/"+uid,"GET", {}, {
      then: function(res){
        // console.log("res",res);
        setLoading(false);
        setTeacher(res.data.res.teacher);
        setSections(res.data.res.sections);
        setCourse(res.data.res.course);
      },
      catch: function(err){toast.error(err.message);setLoading(false);},
      finally: function(){}
    });
  }

  const markLocalContentComplete = () => {
    let cloneSections = clone(sections);
    for (let i = 0; i < cloneSections.length; i++) {
      const section = cloneSections[i];
      section.completedCount++;
      if(section.uid === activeSection.uid){
        for (let k = 0; k < section.contents.length; k++) {
          const content = section.contents[k];
          if(content.uid === activeContent.uid){
            content.completed = true;
            break;
          }
        }
        break;
      }
    }
    setSections(cloneSections);
  }


  let tags = [];
  if(course && course.tags){
    tags = course.tags.split(",");
  }

  let bulletPoints = [];
  if(course && course.bulletPointList){
    bulletPoints = course.bulletPointList.split(",");
  }

  if(didMountContentUid === false){
    return (
      <div className='course-comp loading-item loading-item-flow'>

      </div>
    )
  }

  return (
    <div className="course-comp">
      
      {(course && course.canEdit && !activeSection && !activeContent)?
      <div className='buttons'>
        <div></div>
        <div>
          <Link className='button' to={"/course-studio/"+uid}>Edit Course</Link>
        </div>
      </div>
      :null}
      
      <div className={'course-details '+((activeSection && activeContent)?"hide":"")}>
        <div className={'video-block '+((loading)?"loading-vid":"")}>
          <VideoViewer uid={course && course.introductionUid?course.introductionUid:""} />
        </div>
        <div className='tag-block'>
          {(course && course.difficulty)?<div className='difficulty'>{course.difficulty}</div>:null}
          {tags.map((v,i) => {return(<div className='tag' key={i}>{v}</div>)})}
        </div>
        <div className='content-block'>
          <div className='left'>
            <div className='about'>
              <div>
                <h1>{course && course.title?course.title:""}</h1>
                <h3>About this Course</h3>
                <div className='desc'>{course && course.desc?course.desc:""}</div>
              </div>
              <div className='what-you-will-learn'>
                <h4>What you'll learn</h4>
                <ul>
                  {bulletPoints.map((v,i) => {return(
                    <li className='bullet-point' key={i}>
                      <div className='icon'><CheckSvg/></div>
                      <div className='text'>{v}</div>
                    </li>)})}
                </ul>
              </div>
            </div>
            <div className='sections'>
              {sections.map((section,i) => {
                let expand = (expandSections.indexOf(i) !== -1);
                let percentage = 0;
                if(section.completedCount >= 1){
                  percentage = section.completedCount/section.contents.length;
                  if(percentage > 1){ percentage = 1; }
                  percentage = percentage * 100;
                }
                let degree = 360*(percentage/100);
                degree = Math.round(degree/2);
                let style = {
                  transform: "rotate("+degree+"deg)",
                }
                let nextContent = section.contents.length >= 1?section.contents[0]:null;
                for (let x = 0; x < section.contents.length; x++) {
                  const content = section.contents[x];
                  if(content.completed === false){
                    nextContent = content;
                    break;
                  }
                }

                return(
                  <div className='section' key={i}>
                    <div className='left'>
                      <div className="circle-wrap" onClick={() => {
                        if(nextContent){
                          goToContent({section,content:nextContent});
                        }
                      }}>
                        <div className="circle">
                          <div className="mask full" style={style}>
                            <div className="fill" style={style}></div>
                          </div>
                          <div className="mask half">
                            <div className="fill" style={style}></div>
                          </div>
                          <div className="inside-circle">
                            {/* <CheckSvg /> */}
                            <ForwardSvg />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='right'>
                      <div>
                        <h3>{section.title}</h3>
                        <div className='desc'>{section.desc}</div>
                      </div>
                      <div className='steps hlo' onClick={() => {
                        let cloneExpandSections = clone(expandSections);
                        if(expand){
                          let index = expandSections.indexOf(i);
                          if(index !== -1){ cloneExpandSections.splice(index, 1); }
                        }else{
                          cloneExpandSections.push(i);
                        }
                        setExpandSections(cloneExpandSections);
                      }}>
                        {(expand)?<ExpandLessSvg/>:<ExpandMoreSvg/>}
                        <div>{section.contents.length} Steps</div>
                      </div>
                      <div className={'contents '+((expand)?"expand":"")}>
                        {section.contents.map((content,i2) => {
                          let completedClass = "";
                          if(content.completed){
                            completedClass = "completed";
                          } 
                          return(
                            <div className='content' key={i2} onClick={() => {
                              goToContent({section,content});
                            }}>
                              <div className={'icon '+completedClass}>
                                {(content.type === "Video")?<VideoSvg />:null}
                                {(content.type === "Challenge")?<CodeSvg />:null}
                                {(content.type === "Quiz")?<QuizSvg />:null}
                              </div>
                              <div className='text'>
                                <div className='top'>{content.title}</div>
                                <div className='bottom'>
                                {(content.type === "Video")?<div>Video Length</div>:null}
                                {(content.type === "Challenge")?<div>Challenge</div>:null}
                                {(content.type === "Quiz")?<div>{content.questions.length?content.questions.length:0} {(content.questions.length && content.questions.length === 1)?"Question":"Questions"}</div>:null}
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          <div className='right teacher-block'>
            <h3>Instructors</h3>
            <div className='teacher-image'>
              {(teacher && teacher.imageUid)?
                <img alt="teacher" src={Config.Common.ApiBaseUrl+"/f/"+teacher.imageUid} />
              :<PersonSvg />}
            </div>
            <div className='teacher-name'>{(teacher && teacher.displayName?teacher.displayName:"Teacher Name")}</div>
            <div className='teacher-desc'>{(teacher && teacher.desc?teacher.desc:"Teacher Desc")}</div>
          </div>
        </div>
      </div>


      {(activeSection && activeContent)
        ?<Content 
          course={course} 
          section={activeSection} 
          content={activeContent} 
          nextSection={nextSection}
          backToCourse={backToCourse}
          goToContent={goToContent}
          markLocalContentComplete={markLocalContentComplete}
          />
      :null}
    </div>
  );

};

export default Course;