import React,{useState,} from 'react';
import './forum.scss';

import Table from '../../elements/table/table';
import AskQuestionModal from './ask-question-modal';
import { Redirect } from 'react-router-dom';
import * as DateUtil from '../../../utils/date';
import {ReactComponent as UserSvg} from '../../../css/imgs/icon-person.svg';
import Config from '../../../config/config';

const Forum = () => {

  const [show,setShow] = useState(false);
  const [redirect,setRedirect] = useState(null);

  const columns = [
    {table: "forumQuestions",value: "uid",hide: true, },
    {table: "users",value: "uid",hide: true, },
    {table: "users",value: "username",hide: true, },
    {table: "forumQuestions",value: "title",default: true, index:true, 
      custom: ({v,cv}) => {

        let createdDate = v["forumQuestionsCreatedDate"];
        createdDate = DateUtil.dateTimeSince(createdDate);

        let displayName = "Display Name";
        if(v["publicProfileDisplayName"]){
          displayName = v["publicProfileDisplayName"];
        }else{
          displayName = v["usersUsername"]
        }
        let imageUid = null;
        if(v["publicProfileImageUid"]){
          imageUid = v["publicProfileImageUid"];
        }

        return (
          <div className='question-item'>
            <div className='left'>
              <div className='icon'>
                {(imageUid)?
                  <img src={Config.Common.ApiBaseUrl+"/f/"+imageUid} alt="user icon" />:
                  <UserSvg />
                }
              </div>
            </div>
            <div className='center'>
              <div className='main'>{v[cv]}</div>
              <div className='sub'>
                Posted {createdDate} by {displayName}
              </div>
            </div>
            <div className='right'>
              <div className='count'>{v["forumQuestionsAnswerCount"]}</div>
              <div className='label'>Answers</div>
            </div>
          </div>
        )
      }
    },
    {table: "publicProfile",value: "imageUid",hide:true, },
    {table: "publicProfile",value: "displayName",hide:true, },
    {table: "forumQuestions",value: "answerCount", index:true,hide:true, },
    {table: "forumQuestions",value: "createdDate",hide:true, index:true, type:"date", defaultOrder:"DESC",},
  ]
  const joinOptions = [
    {joinType:"LEFT JOIN",leftTable:"forumQuestions",rightTable:"users",leftColumn:"userId",rightColumn:"id",},
    {joinType:"LEFT JOIN",leftTable:"forumQuestions",rightTable:"publicProfile",leftColumn:"userId",rightColumn:"userId",},
  ];

  if(redirect){
    return <Redirect push to={redirect} />
  }

  return (
    <div className="forum-comp">
      <Table 
        buttons={[
          {
            parentClassName:"ask-question",
            status:"",
            onClick:() => {
              setShow(true)
            },
            text:"Ask New Question",
          }
        ]}
        searchEndpoint={"/community-search"}
        disableAdvancedSearch={true}
        displayName="Forum"
        name="ForumTable"
        sideItems={[]}
        sideItemActiveClickFunc={(item) => {
          // console.log("item",item);
        }}
        joinOptions={joinOptions}
        rowOnClickFunc={(value) => {
          // console.log("value",value)
          setRedirect("/question/"+value.forumQuestionsUid);
        }}
        columns={columns}
        limit={25}
      />


      <AskQuestionModal 
        show={show} 
        handleClose={() => {setShow(false)}}
        />

    </div>
  );

};

export default Forum;