export const roboRally = {
  "roboRally": {
    "en": "Robo Rally",
    "ja": "ロボラリー"
  },
  "myRobos": {
    "en": "My Robos",
    "ja": "マイ ロボ"
  },
  "roboRallyTracks": {
    "en": "Robo Rally Tracks",
    "ja": "ロボラリーコース"
  },
  "allRobos": {
    "en": "All Robos",
    "ja": "すべてのロボ"
  },
  "roboDetails": {
    "en": "Robo Details",
    "ja": "ロボ詳細"
  },
  "trackDetails": {
    "en": "Track Details",
    "ja": "コース詳細"
  },
  "robos": {
    "en": "Robos",
    "ja": "ロボ"
  },
  "tracks": {
    "en": "Tracks",
    "ja": "コース"
  },
  "newRobo": {
    "en": "New Robo",
    "ja": "新しいロボ"
  },
  "newTrack": {
    "en": "New Track",
    "ja": "新しいコース"
  },
  "official": {
    "en": "Official",
    "ja": "公式"
  },
  "myTracks": {
    "en": "My Tracks",
    "ja": "私の"
  },
  "all": {
    "en": "All",
    "ja": "すべて"
  },
  "createRobo": {
    "en": "Create Robo",
    "ja": "ロボを作成"
  },
  "speed": {
    "en": "Speed",
    "ja": "速度"
  },
  "intelligence": {
    "en": "Intelligence",
    "ja": "知性"
  },
  "vision": {
    "en": "Vision",
    "ja": "視力"
  },
  "traction": {
    "en": "Traction",
    "ja": "トラクション"
  },
  "energy": {
    "en": "Energy",
    "ja": "燃費"
  },
  "name": {
    "en": "Name",
    "ja": "名前"
  },
  "edit": {
    "en": "Edit",
    "ja": "編集"
  },
  "delete": {
    "en": "Delete",
    "ja": "削除"
  },
  "prompt": {
    "en": "Prompt",
    "ja": "プロンプト"
  },
  "version": {
    "en": "Version",
    "ja": "バージョン"
  },
  "created": {
    "en": "Created",
    "ja": "作成日時"
  },
  "hideStatsMessage": {
    "en": "Complete a course with this robot to see its stats",
    "ja": "このロボットとコースを完了して、その統計を見てください"
  },
  "instructionsTitle": {
    "en": "Robo Rally Course Instructions",
    "ja": "ロボラリーコースの説明"
  },
  "instructionsGoal": {
    "en": "Goal: Collect all of the items before the timer runs out. Each item is worth different points.",
    "ja": "目標：タイマーが切れる前にすべてのアイテムを集めます。各アイテムには異なるポイントがあります。"
  },
  "instructionsTableItemName": {
    "en": "Item Name",
    "ja": "アイテム名"
  },
  "instructionsTablePicture": {
    "en": "Picture",
    "ja": "写真"
  },
  "instructionsTableDescription": {
    "en": "Description",
    "ja": "説明"
  },
  "instructionsGreenBallName": {
    "en": "Green Ball",
    "ja": "緑のボール"
  },
  "instructionsGreenBallDescription": {
    "en": "A cool green ball. Worth 1 point.",
    "ja": "クールな緑のボール。 1ポイントの価値があります。"
  },
  "instructionsYellowBallName": {
    "en": "Yellow Ball",
    "ja": "黄色のボール"
  },
  "instructionsYellowBallDescription": {
    "en": "A sunny yellow ball. Worth 2 points.",
    "ja": "晴れた黄色のボール。 2ポイントの価値があります。"
  },
  "instructionsBlueBallName": {
    "en": "Blue Ball",
    "ja": "青いボール"
  },
  "instructionsBlueBallDescription": {
    "en": "A calm blue ball. Worth 3 points.",
    "ja": "穏やかな青いボール。 3ポイントの価値があります。"
  },
  "instructionsRedBallName": {
    "en": "Red Ball",
    "ja": "赤いボール"
  },
  "instructionsRedBallDescription": {
    "en": "A fiery red ball. Worth 4 points.",
    "ja": "燃えるような赤いボール。 4ポイントの価値があります。"
  },
  "instructionsWarning": {
    "en": "The following items are also on the course. Be careful, some can cause harm.",
    "ja": "次のアイテムもコースにあります。 気をつけて、危害を与えるものもあります。"
  },
  "instructionsOilName": {
    "en": "Oil Slick",
    "ja": "オイルスリック"
  },
  "instructionsOilDescription": {
    "en": "This hazard will move you in a random direction.",
    "ja": "この危険は、ランダムな方向に移動します。"
  },
  "instructionsConeName": {
    "en": "Cone Wall",
    "ja": "コーンウォール"
  },
  "instructionsConeDescription": {
    "en": "You can't move through these walls. Be careful! It will still cost battery power if you move into a wall.",
    "ja": "これらの壁を通過することはできません。 気をつけて！ 壁に移動すると、バッテリー電力が消費されます。"
  },
  "instructionsPitStopName": {
    "en": "Pit Stop",
    "ja": "ピットストップ"
  },
  "instructionsPitStopDescription": {
    "en": "This will completely recharge your battery. Very useful!",
    "ja": "これにより、バッテリーが完全に充電されます。 とても便利！"
  },
  "instructionsCloseButton": {
    "en": "Close Instructions",
    "ja": "説明を閉じる"
  },
  "instructionsShowButton": {
    "en": "Show Instructions",
    "ja": "説明を表示"
  },
  "descriptionShowButton": {
    "en": "Show Track Description",
    "ja": "コースの説明を表示"
  },
  "playTrack": {
    "en": "Play Track",
    "ja": "コースをプレイする"
  },
  "roboIntelligenceHeader": {
    "en": "Robo Intelligence",
    "ja": "ロボ知性"
  },
  "roboIntelligenceDistanceToNearestPitStop": {
    "en": "Distance to nearest Pit Stop",
    "ja": "最寄りのピットストップまでの距離"
  },
  "roboIntelligenceMovesRemaining": {
    "en": "Moves Remaining",
    "ja": "残りの動き"
  },
  "roboBatteryHeader": {
    "en": "Battery",
    "ja": "バッテリー残量"
  },
  "roboStartTrack": {
    "en": "Start Track",
    "ja": "コースを開始"
  },
  "roboScoreValue": {
    "en": "Score: ",
    "ja": "スコア："
  },
  "roboMovesValue": {
    "en": "Moves: ",
    "ja": "動き："
  },
  "roboTimeValue": {
    "en": "Time: ",
    "ja": "時間："
  },
  "roboRestartTrack": {
    "en": "Restart Track",
    "ja": "コースを再開"
  },
  "roboViewRobotButton": {
    "en": "View Robot",
    "ja": "ロボを見る"
  },
  "roboHideRobotButton": {
    "en": "Hide Robot",
    "ja": "ロボを隠す"
  },
  "endScreenCongrats": {
    "en": "Congratulations, you've completed the level!",
    "ja": "おめでとうございます、レベルをクリアしました！"
  },
  "endScreenScore": {
    "en": "Your score was",
    "ja": "あなたのスコアは"
  },
  "endScreenTime": {
    "en": "Your time was",
    "ja": "あなたの時間は"
  },
  "endScreenMoves": {
    "en": "Your moves were",
    "ja": "あなたの動きは"
  },
  "endScreenPlayAgain": {
    "en": "Play Again",
    "ja": "もう一度プレイ"
  },
  "endScreenNextLevel": {
    "en": "Stage select",
    "ja": "ステージ選択"
  },
  "gameOverBattery": {
    "en": "Your battery ran out!",
    "ja": "バッテリーが切れました！"
  },
  "gameOverTime": {
    "en": "Your time ran out!",
    "ja": "時間切れ！"
  },
  "CreateTrack": {
    "en": "Create Track",
    "ja": "トラックを作成"
  },
  "TrackName": {
    "en": "Track Name",
    "ja": "トラック名"
  },
  "Random": {
    "en": "Random",
    "ja": "ランダム"
  },
  "TrackPromptText": {
    "en": "Track Prompt Text",
    "ja": "トラックプロンプトテキスト"
  }
}