import React,{useState,useEffect} from 'react';
import './world-builder-image-queue-modal.scss';
import Carousel from '../../common/carousel/carousel';
import * as StringUtil from '../../../utils/string';
import Textarea from 'react-textarea-autosize';
import Button from '../../elements/button/button';
import Modal from '../../common/modal/modal';
import { clone } from '../../../utils/clone';
import CarouselHeader from '../../common/carousel/carousel-header';
import { toast } from 'react-toastify';
import { request,unSubRequest } from '../../../utils/request';
import Table from '../../elements/table/table';
import useUserState from '../../../store/user';


const StatusEnum = {
  Waiting:1,
  Processing:2,
  Cancelled:3,
  Done:4,
}
const StatusIdEnum = {
  1:"Waiting",
  2:"Processing",
  3:"Cancelled",
  4:"Done",
}

const WorldBuilderImageQueueModal = ({show,handleClose,}) => {

  const {user} = useUserState();
  const [inc,setInc] = useState(0);

  useEffect(() => {
    setInc(inc + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[show])

  const sideItems = [
    {display: "All", conditions:[
      {column:"userUid", table:"imageQueue", action: "=",value: user.uid,},
    ]},
    {display: "Waiting", conditions:[
      {column:"userUid", table:"imageQueue", action: "=",value: user.uid,},
      {column:"status", table:"imageQueue", action: "=",value: StatusEnum.Waiting,},
    ]},
    {display: "Processing", conditions:[
      {column:"userUid", table:"imageQueue", action: "=",value: user.uid,},
      {column:"status", table:"imageQueue", action: "=",value: StatusEnum.Processing,},
    ]},
    {display: "Cancelled", conditions:[
      {column:"userUid", table:"imageQueue", action: "=",value: user.uid,},
      {column:"status", table:"imageQueue", action: "=",value: StatusEnum.Cancelled,},
    ]},
    {display: "Done", conditions:[
      {column:"userUid", table:"imageQueue", action: "=",value: user.uid,},
      {column:"status", table:"imageQueue", action: "=",value: StatusEnum.Done,},
    ]},
  ];
  const columns = [
    // {table: "imageQueue",value: "uid", index:true, hide:true, },
    // {table: "imageQueue",value: "platformKey", hide: true, index:true, },
    {table: "imageQueue",value: "status", 
      custom: ({v,cv}) => {
        let val = parseInt(v[cv]);
        return(
          <div>{StatusIdEnum[val]}</div>
        )
      },  
    },
    // {table: "imageQueue",value: "LinkedUid", hide:true, },
    // {table: "imageQueue",value: "Table", hide:true },
    {table: "imageQueue",value: "name", },
    // {table: "imageQueue",value: "prompt", 
    //   custom: ({v,cv}) => {
    //     let val = v[cv]
    //     return(
    //       <div className='prompt-div'>{val}</div>
    //     )
    //   },  
    // },
    {table: "imageQueue",value: "ImageNum", display:"Images" },
    {table: "imageQueue",value: "orderDate", display:"Date",  type:"date", index:true, defaultOrder:true, defaultOrderDir:"DESC",},
    // {table: "imageQueue",value: "createdDate", hide:true,  type:"date", },
  ];
  const joinOptions = [
    // {joinType:"LEFT JOIN",leftTable:"characters",rightTable:"images",leftColumn:"uid",rightColumn:"linkedUid",},
  ];

  return (
    <Modal className={"world-builder-image-queue-modal"} show={show} handleClose={handleClose}>
      <div>
        <h4>Image Queue</h4>
        <Table database={"Worlds"}
          useSearchBar={false}
          displayName="ImageQueue" name="ImageQueueTable" limit={50}
          sideItems={sideItems}joinOptions={joinOptions} columns={columns}
          // rowOnClickFunc={(value) => {
          //   let newValues = clone(values);
          //   newValues[tables.religionGods] = value;
          //   setValues(newValues);
          // }}
          // returnResultsFunc={(results) => {
          //   let newActiveValue = !(tables.religionGods in values) && results.length >= 1?results[0]:null;
          //   let newValues = values;
          //   if(newActiveValue !== null){
          //     newValues = clone(values);
          //     newValues[tables.religionGods] = newActiveValue
          //   }
          //   setValues(newValues);
          // }}
          refreshInc={inc}
        />
      </div>
    </Modal>
  );
};

export default WorldBuilderImageQueueModal;