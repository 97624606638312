
const AccountStatusEnum = {
  Any: 0,
  EmailUnverified: 10,
  Banned: 20,
  Disabled: 30,
  Basic: 40,
  Lite: 50,
  Premium: 60,
  Pro: 70,
  Elite: 80,
  Staff: 90,
  Admin: 100,
  SuperAdmin: 110,
  System: 120,
}

export const AccountStatusIdEnum = {
  0:"Any",
  10:"EmailUnverified",
  20:"Banned",
  30:"Disabled",
  40:"Basic",
  50:"Lite",
  60:"Premium",
  70:"Pro",
  80:"Elite",
  90:"Staff",
  100:"Admin",
  110:"SuperAdmin",
  120:"System",
}

export default AccountStatusEnum;