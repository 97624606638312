const appName = "MythBlender";
const leadingTitle = "";
const trailingTitle = " | "+appName;

const Seo = {
  Home: {
    // Title: "AI Story Generator - MythBlender",
    Title: "AI Story Generator - AI Fanfiction Generator - MythBlender",
    // Desc: "Generate new original stories with AI instantly. Create your own fanfiction of other stories. ",
    // Desc: "Embark on a journey of imagination with our AI Story Generator - Generate new original stories with AI instantly. Create your own fanfiction of other stories",
    Desc: "Embark on a journey of imagination with our AI Story Generator - Generate new original stories with AI instantly.  Use our AI Fanfiction Generator that brings your favorite stories to life.",
    Keywords: "AI Story Generator, Free AI Story Generator, MythBlender",
  },
  Dash: {
    Title: leadingTitle+"Dashboard" + trailingTitle,
    Desc: "Access your MythBlender dashboard to navigate through AI Story Generator functionalities, and all of your created stories.",
    Keywords: "MythBlender Dashboard, AI Story Generator, MythBlender New Story",
  },
  HowToUse: {
    Title: leadingTitle+"How To Use MythBlender" + trailingTitle,
    Desc: "Learn how to use AI Story Generator effectively with MythBlender. Get detailed guidance on character creation, AI Images, and more.",
    Keywords: "MythBlender Usage, AI Story Generator, MythBlender, MythBlender Tutorials",
  },
  Pricing: {
    Title: "AI Story Generator Pricing, Plans - AI Story Generator Free - MythBlender",
    Desc: "MythBlender: AI Story Generator - Explore pricing and plans for the ultimate AI story creation tool. Enjoy a free plan to start crafting your tales, or upgrade for premium features",
    Keywords: "MythBlender Pricing, AI Story Generator Subscription, ",
  },
  Help: {
    Title: leadingTitle+"Help and Support" + trailingTitle,
    Desc: "Need assistance with MythBlender or AI Story Generation? Explore our help section for prompt support.",
    Keywords: "MythBlender Help, AI Story Generator Support, ",
  },
  Login: {
    Title: leadingTitle+"Login" + trailingTitle,
    Desc: appName + " Login. Access your account to explore AI Story Generation features, prompt generation, and more.",
    Keywords: appName + " Login, User Access, Account Management",
  },
  CreateAccount: {
    Title: leadingTitle+"Create Account" + trailingTitle,
    Desc: appName + " Create Account. Join MythBlender to start generating epic AI stories today.",
    Keywords: appName + " Create Account, Register, User Signup",
  },




  MyAccount: {
    Title: leadingTitle+"My Account" + trailingTitle,
    Desc: appName + " My Account. Manage your profile, preferences, and access personalized MythBlender features.",
    Keywords: appName + " My Account, User Profile, Account Settings",
  },
  ResetPassword: {
    Title: leadingTitle+"Reset Password" + trailingTitle,
    Desc: appName + " Reset Password. Forgot your password? No worries, we got you covered. Easily reset and recover your account access.",
    Keywords: appName + " Reset Password, Password Recovery, Account Security",
  },
  VerifyEmail: {
    Title: leadingTitle+"Verify Email" + trailingTitle,
    Desc: appName + " Verify Email. Need to verify your email? Here's where you want to be. Secure your account and stay updated.",
    Keywords: appName + " Verify Email, Email Confirmation, Account Security",
  },
  TermsOfUse: {
    Title: leadingTitle+"Terms of Use" + trailingTitle,
    Desc: appName + " Terms of Use. Understand the guidelines and rules for using Fjorney and Midjourney Bot services.",
    Keywords: appName + " Terms of Use, User Agreement, Legal Guidelines",
  },
  PrivacyPolicy: {
    Title: leadingTitle+"Privacy Policy" + trailingTitle,
    Desc: appName + " Privacy Policy. Your privacy matters. Learn how we handle and protect your personal data.",
    Keywords: appName + " Privacy Policy, Data Protection, User Privacy",
  },
  Feedback: {
    Title: leadingTitle+"Feedback" + trailingTitle,
    Desc: "Share your Feedback with " + appName + ". Your opinions and suggestions help us improve our Ai Story Generation services.",
    Keywords: appName + " Feedback, User Suggestions, Service Improvement",
  },
  Support: {
    Title: leadingTitle+"Support" + trailingTitle,
    Desc: "Get Support at " + appName + ". Find answers to your queries and technical assistance for MythBlender.",
    Keywords: appName + " Support, Technical Help, Customer Service",
  },

};

export default Seo;
