import React, { useState,useEffect } from 'react';
import './ask-question-modal.scss';
import Modal from '../../common/modal/modal';
import Button from '../../elements/button/button';
import ReactTextareaAutosize from 'react-textarea-autosize';
import { request, unSubRequest } from '../../../utils/request';
import { toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import ReactSelect from 'react-select';
import Config from '../../../config/config';
import * as ReactSelectUtil from '../../../utils/react-select';
import {clone} from '../../../utils/clone';

const AskQuestionModal = ({show,handleClose,initCourseUid,initSectionUid,initContentUid}) => {

  const [loading,setLoading] = useState(false);
  const [title,setTitle] = useState("");
  const [details,setDetails] = useState("");
  const [redirect,setRedirect] = useState(null);
  const [courses,setCourses] = useState([]);
  const [courseUid,setCourseUid] = useState(null);
  const [sectionUid,setSectionUid] = useState(null);
  const [contentUid,setContentUid] = useState(null);
  const [sections,setSections] = useState([]);

  useEffect(() => { setCourseUid(initCourseUid) },[initCourseUid])
  useEffect(() => { setSectionUid(initSectionUid) },[initSectionUid])
  useEffect(() => { setContentUid(initContentUid) },[initContentUid])

  useEffect(() => {
    fetchCourses();
    return () => {
      unSubRequest("post-question");
      unSubRequest("fetch-courses");
      unSubRequest("fetch-course");
    }
  },[])

  useEffect(() => {
    // console.log("courseUid",courseUid);
    if(courseUid){
      fetchCourse(courseUid);
    }
  },[courseUid])

  const fetchCourses = () => {
    setLoading(true);
    request("fetch-courses","/courses/","GET", {}, {
      then: function(res){
        setCourses(res.data.res.courses);
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false);}
    });
  }

  const fetchCourse = (uid) => {
    setLoading(true);
    request("fetch-course","/fetch-course/"+uid,"GET", {}, {
      then: function(res){
        let sections = res.data.res.sections;
        setSections(sections);
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false);}
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    postQuestion();
  }

  const postQuestion = () => {
    let body = {
      title,
      details,
      courseUid,
      sectionUid,
      contentUid,
    }
    setLoading(true);
    request("post-question","/community/question","POST", body, {
      then: function(res){
        let uid = res.data.res.uid;
        let str = "/question/"+uid;
        setRedirect(str);
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false);}
    });
  }

  let reactSelectCourseOptions = []
  for (let i = 0; i < courses.length; i++) {
    const course = courses[i];
    reactSelectCourseOptions.push({uid: course.uid,value: course.title, label: 
      <div className='course-option'>
        <div className='thumbnail'>
          <img src={Config.Common.ApiBaseUrl+"/f/"+(course.thumbnailUid?course.thumbnailUid:"")} alt="course thumbnail" />
        </div>
        <div>{course.title}</div>
      </div>
    })
  }

  let reactSelectSectionOptions = []
  let contents = [];
  for (let i = 0; i < sections.length; i++) {
    const section = sections[i];
    if(section.uid === sectionUid){
      contents = clone(section.contents);
    }
    reactSelectSectionOptions.push({uid: section.uid, value: section.title, label: 
      <div className='section-option'>
        <div className='title'>{section.title}</div>
        <div className='sub'>{section.desc}</div>
      </div>
    })
  }

  let reactSelectContentOptions = []
  for (let i = 0; i < contents.length; i++) {
    const content = contents[i];
    reactSelectContentOptions.push({uid: content.uid, value: content.title, label: 
      <div className='content-option'>
        <div className='title'>{content.title}</div>
        <div className='sub'>{content.type}</div>
      </div>
    })
  }

  if(redirect){
    return (
      <Redirect to={redirect} />
    )
  }

  return (
    <Modal className={"ask-question-modal"} show={show} handleClose={handleClose}>
      <div>
        <form onSubmit={handleSubmit}>
          <h3>Ask a Question </h3>
          <div className='related-content'>
            <label>Related Content</label>
            <p className='note'>Is this question about a video? You can select the course->section->content that you're asking a question about. </p>
            <div className='react-select-items'>
              <ReactSelect placeholder="Select Related Course"
                className="custom-react-select" 
                classNamePrefix="custom-react-select-inner"
                onChange={(e) => {
                  setSectionUid(null);
                  setContentUid(null);
                  setCourseUid(e.uid);
                }}
                value={(courseUid === null)?null:
                  courseUid?ReactSelectUtil.findObjectByValue(reactSelectCourseOptions,courseUid,"uid"):""
                }
                options={reactSelectCourseOptions}
                isDisabled={loading}
                />
              <ReactSelect placeholder="Select Related Section"
                className="custom-react-select" 
                classNamePrefix="custom-react-select-inner"
                onChange={(e) => {
                  setContentUid(null);
                  setSectionUid(e.uid);
                }}
                value={(sectionUid === null)?null:
                  sectionUid?ReactSelectUtil.findObjectByValue(reactSelectSectionOptions,sectionUid,"uid"):""
                }
                options={reactSelectSectionOptions}
                isDisabled={loading || !courseUid}
                />
              <ReactSelect placeholder="Select Related Content"
                className="custom-react-select" 
                classNamePrefix="custom-react-select-inner"
                onChange={(e) => {
                  setContentUid(e.uid);
                }}
                value={(contentUid === null)?null:
                  contentUid?ReactSelectUtil.findObjectByValue(reactSelectContentOptions,contentUid,"uid"):""
                }
                options={reactSelectContentOptions}
                isDisabled={loading || !courseUid || !sectionUid}
                />
            </div>
          </div>
          <div>
            <label>Title of your question</label>
            <input type="text" value={title} onChange={(e) => {setTitle(e.target.value)}}/>
          </div>
          <div className='details'>
            <label>Details</label>
            <ReactTextareaAutosize value={details} onChange={(e) => {setDetails(e.target.value)}}/>
          </div>

          <div className='buttons'>
            <div>
              <Button parentClassName="clear" type="button" onClick={handleClose}>Cancel</Button>
            </div>
            <div>
              <Button status={(loading)?"loading":""} type="submit" value={"Post Question"}/>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );

};

export default AskQuestionModal;