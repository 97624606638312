import React,{useState, useEffect} from 'react';
import './sns-auth.scss';
import * as BrowserUtil from '../../../../utils/browser';
import { request, unSubRequest } from '../../../../utils/request';
import useUserState from '../../../../store/user';
import { Redirect } from 'react-router';
import Loading from '../../../common/loading/loading';
import SnsTypeEnum from '../../../../enums/sns-type';
import {toast} from 'react-toastify';

const SnsAuth = ({snsType}) => {
  const queryCode = BrowserUtil.getQueryVariable("code");

  let headerName = "";
  let message = "";
  if(snsType === SnsTypeEnum.Google){
    headerName = "Google Auth";
    message = "Awating Google to authorize your account.";
  }else if(snsType === SnsTypeEnum.Facebook){
    headerName = "Facebook Auth";
    message = "Awating Facebook to authorize your account.";
  }else{
    toast.error("Error. SnsType not found")
  }

  const {user,loginUser} = useUserState();
  const [errorMessage,setErrorMessage] = useState("");
  const [redirect,setRedirect] = useState(false);
  const [loading,setLoading] = useState(true);
  const [code] = useState((queryCode !== null) ? queryCode : "");

  useEffect(() => {
    return () => {
      unSubRequest("sns-auth");
    }
  },[])

  useEffect(() => {
    if(code !== undefined && code !== null && code !== ""){

      let signPath = "";
      let linkPath = "";
      if(snsType === SnsTypeEnum.Google){
        signPath = "/google-sign";
        linkPath = "/google-link";
      }else if(snsType === SnsTypeEnum.Facebook){
        signPath = "/facebook-sign";
        linkPath = "/facebook-link";
      }

      let actionType = (user !== null)?"link":"sign";

      const data = {
        code: code,
        deviceToken: null,
      };

      let url = (actionType === "link")?linkPath:signPath;
      if(localStorage.getItem("device")){
        data.deviceToken = localStorage.getItem("device");
      }
      if(localStorage.getItem("referralCode")){
        data.referralCode = localStorage.referralCode;
      }

      setLoading(true);
      request("sns-auth",url,"POST", data, {
        then: function(res){
          let newRedirect = "/my-account";
          let results = res.data.res;
          // console.log("results",results);
          if(results.newDeviceToken) localStorage.setItem("device",results.newDeviceToken);
          if(results.totpRequired && !results.totpPassed){
            newRedirect = "/totp?token="+results.totpLoginToken;;
          }
          let userHold = user;
          if(user === null && results.user && results.auth){
            userHold = results.user;
            userHold.auth = results.auth;
          }
          loginUser(userHold);
          setRedirect(newRedirect);
        },
        catch: function(err){setErrorMessage(err.message);},
        finally: function(){setLoading(false);}
      });
    }
  },[code,loginUser,user,snsType]);
  
  if(redirect){
    let redirectPath = redirect;
    // if(localStorage.getItem("redirectPath") !== undefined && localStorage.getItem("redirectPath") !== null){
    //   redirectPath = localStorage.getItem("redirectPath");
    //   localStorage.removeItem("redirectPath");
    // }
    return (<Redirect to={redirectPath} />)
  }

  return (
    <div className="sns-auth">
      <h1>{headerName}</h1>
      <p>{message}</p>
      {(errorMessage !== "")?<ul className="errors"><li>{errorMessage}</li></ul>:""}
      {(loading) ? <Loading show={loading} size={"40px"} /> :""}
    </div>
  );
};

export default SnsAuth;