export const navi = {
  dashboard: {
    "en": "Dashboard",
    "ja": "ダッシュボード"
  },
  blog: {
    "en": "Blog",
    "ja": "ブログ"
  },
  roboRally: {
    "en": "Robo Rally",
    "ja": "ロボラリー"
  },
  storyBook: {
    "en": "Story Book",
    "ja": "ストーリーブック"
  },
  pricing: {
    "en": "Pricing",
    "ja": "価格"
  },
  library: {
    "en": "Library",
    "ja": "ライブラリ"
  },
  community: {
    "en": "Community",
    "ja": "コミュニティ"
  },
  help: {
    en: 'Help',
    ja: 'ヘルプ',
  },
  howToUse: {
    "en": "How to Use",
    "ja": "使い方"
  },
  login: {
    en: "Login",
    ja: "ログイン",
  },
  createAccount: {
    en: "Create Account",
    ja: "アカウントを作成",
  },
  myAccount: {
    en: "My Account",
    ja: "マイアカウント",
  },
  logout: {
    en: "Logout",
    ja: "ログアウト",
  },
  language: {
    en: "English",
    ja: "日本語"
  },
  changeLanguage:{
    en: "Change Language",
    ja: "言語の変更"
  }
}