import React, { useEffect, useState } from 'react';
import './dashboard.scss';

import Config from '../../../config/config';
import NewWorld from './new-world';
import Button from '../../elements/button/button';
import Modal from '../../common/modal/modal';
import Table from '../../elements/table/table';
import * as DateUtil from '../../../utils/date';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import useUserState from '../../../store/user';
import {ReactComponent as ImageSvg} from '../../../css/imgs/icon-image.svg';
import { Helmet } from "react-helmet";
import Seo from '../../../data/seo';

const Dashboard = () => {

  const [initLoading,setInitLoading] = useState(true);
  const [show,setShow] = useState(false);
  const [results,setResults] = useState(null);
  const [showNewModalView,setShowNewModalView] = useState(false);
  const [worldUid,setWorldUid] = useState(null);
  const {user} = useUserState();

  useEffect(() => {
    let newShowNewModalView = false;
    if(results !== null && results.length < 1){
      newShowNewModalView = true;
    }
    setShowNewModalView(newShowNewModalView);
  },[results]);


  const sideItems = [{display: "All", conditions:[{column:"userUid", table:"worlds", action: "=",value: user && user.uid?user.uid:"",}]},];
  const columns = [
    {table: "worlds",value: "uid", hide: true, index:true, },
    {table: "worlds",value: "userUid", hide:true, },
    {table: "worlds",value: "name", index:true,
      custom: ({v,cv}) => {
        let imageUrl = v["worldsImageUrl"] ?? "";
        if(imageUrl.includes("{{cdn}}")){
          imageUrl = imageUrl.replace("{{cdn}}",Config.Common.CdnUrl);
        }
        if(imageUrl.includes(".png")){
          imageUrl = imageUrl.replace(".png","-small.png");
        }
        return(
          <div className='world-name-div'>
            <div className='icon'>
              {(imageUrl === "")?
              <ImageSvg/>
              :<img src={imageUrl} alt={v[cv]}/>}
            </div>
            <div className='text'>{v[cv]}</div>
          </div>
        )
      },  
    },
    {table: "worlds",value: "updatedDate", display:"Date", index:true, type:"date", defaultOrder:"DESC",
      custom: ({v,cv}) => {
        return(
          <div className='world-date-div'>
            <div className='text'>{DateUtil.dateTimeSince(v[cv])}</div>
          </div>
        )
      }, 
    },
    {table: "worlds",value: "imageUrl", hide:true, },
    {table: "worlds",value: "createdDate", hide:true, index:true, type:"date", },
  ];

  const joinOptions = [
    // {joinType:"LEFT JOIN",leftTable:"users",rightTable:"publicProfile",leftColumn:"id",rightColumn:"userId",},
  ];

  if(worldUid){
    return <Redirect push to={"/world-builder/"+worldUid}/>
  }

  return (
    <div className="dashboard-comp">
      <Helmet>
        <title>{Seo.Dash.Title}</title>
        <meta name="description" content={Seo.Dash.Desc} />
        <meta name="keywords" content={Seo.Dash.Keywords} />
      </Helmet>

      <div className='buttons'>
        <div>
          <h3>Dashboard</h3>
        </div>
        <div>
          {(!showNewModalView)?
            <>
              <Button status="add" onClick={() =>{setShow(true)}}>New World</Button>
            </>
          :null}
        </div>
      </div>


      {(showNewModalView && initLoading === false)?
        <div>
          <div className='get-started-story'>Get Started making your first world!</div>
          <NewWorld show={true} />
        </div>
      :null}

      <div className={'world-list '+((results !== null && results.length === 0 && initLoading === false?"hide-opacity":""))}>
        <Table 
          database={"Worlds"}
          displayName="Worlds"
          name="WorldsTable"
          sideItems={sideItems}
          sideItemActiveClickFunc={(item) => {
            // console.log("item",item);
          }}
          joinOptions={joinOptions}
          rowOnClickFunc={(value) => {
            setWorldUid(value.worldsUid);
          }}
          // refreshInc={refreshInc}
          columns={columns}
          limit={20}
          useSearchBar={false}
          returnResultsFunc={(results,loading) => {
            // console.log("loading",loading);
            // console.log("results",results);
            setInitLoading(loading);
            setResults(results);
          }}
        />
      </div>
      
      <Modal className={"new-world-modal"} show={show} handleClose={() => {setShow(false)}}>
        <div>
          <NewWorld show={show} />
        </div>
      </Modal>
    </div>
  );

};

export default Dashboard;