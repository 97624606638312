import React,{useRef} from 'react';
import './coin-button.scss';
import Tooltip from '../../common/tooltip/tooltip';

import Loading from '../../common/loading/loading';
import {ReactComponent as CoinsSvg} from '../../../css/imgs/icon-coins.svg';

const CoinButton = ({type,text,loading,parentClassName,rightText,tipText,tipPosition,...rest}) => {
  tipPosition = tipPosition?tipPosition:"bottom";

  const buttonRef = useRef(null);

  let params = {...rest};
  let disable = false;
  if(loading || (params.disabled !== undefined && params.disabled === true)){
    disable = true;
  }
  let disabledClass = (disable) ? " disabled" : ""
  let parentClass = (parentClassName !== undefined) ? parentClassName : "";


  return (
    <div className={"coin-button-element"+disabledClass+" "+parentClass+" icon-right"}>
      <div className="background">
        <div className='button-text'>{text}</div>
        <div className="button-status-image">
          {(loading
          ?<Loading show="true" size="24px"/>
          :<CoinsSvg className="coin-element light"/>)}
        </div>
        <div className='button-right-text'>
        {(rightText)?
          <>{rightText}</>
        :null}
        </div>
      </div>
      
      <Tooltip 
        positionOpt={tipPosition}
        className="button-tooltip" 
        tip={<div>{tipText}</div>}
        >
        {(type === "submit") 
          ? <input type="submit" ref={buttonRef} value="" disabled={disable} {...rest} />
          : <button ref={buttonRef} type="button" disabled={disable} {...rest} />
        }    
      </Tooltip>  
    </div>
  );
};

export default CoinButton;