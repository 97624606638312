
const CourseStatusEnum = {
  Draft: 0,
  InReview: 1,
  Unlisted: 2,
  Listed: 3,
};

const CourseStatusIdEnum = {
  0: "Draft",
  1: "In Review",
  2: "Unlisted",
  3: "Listed",
};

// -- 0 draft, 1 in review, 2 unlisted but approved, 3 listed

module.exports = {CourseStatusEnum,CourseStatusIdEnum};