


import React, {useRef,} from 'react';
import './content-item.scss';
import Tooltip from '../../common/tooltip/tooltip';
import {ContentTypeEnum} from '../../../enums/content-type';

import {ReactComponent as VideoSvg} from '../../../css/imgs/icon-video.svg';
import {ReactComponent as CodeSvg} from '../../../css/imgs/icon-code.svg';
import {ReactComponent as QuizSvg} from '../../../css/imgs/icon-quiz.svg';


const ContentItem = ({active,content,goToContent,course, section}) => {

  const ref = useRef(null);

  return (
    <div className={"content-item-comp "+((active)?"active":"")} onClick={() => {
      goToContent({section,content});
    }}>
      <div className='right' ref={ref}>
        <div className='circular-button hlo' >
          <Tooltip positionOpt="bottom" parentBoundsRef={ref} tip={
                <div className='tooltip-div'>
                  <div className='top-text'>{content && content.title?content.title:""}</div>
                  <div className='bottom-text'>
                    {content && content.type === ContentTypeEnum.Challenge?"Challenge":""}
                    {content && content.type === ContentTypeEnum.Video?"Video":""}
                    {content && content.type === ContentTypeEnum.Quiz?"Quiz":""}
                  </div>
                </div>
              }
            >
            {content && content.type === ContentTypeEnum.Challenge?<CodeSvg/>:""}
            {content && content.type === ContentTypeEnum.Video?<VideoSvg/>:""}
            {content && content.type === ContentTypeEnum.Quiz?<QuizSvg/>:""}
          </Tooltip>
        </div>
      </div>
    </div>
  );

};

export default ContentItem;




