import React,{useEffect, useState, } from 'react';
import './test-socket.scss';
import TextareaAutoresize from 'react-textarea-autosize';
import SocketEnum from '../../../enums/socket';
import SocketEventEnum from '../../../enums/socket-event';

import useSocketState from '../../../store/socket';
import { clone } from '../../../utils/clone';
import ScrollToBottom from 'react-scroll-to-bottom';

const TestSocket = () => {

  const {socketClientId,socketMessages,sendSocketMessage,} = useSocketState();
  const [clientIds,setClientIds] = useState([]);
  const [messages,setMessages] = useState([]);
  const [value,setValue] = useState("");

  useEffect(() => {
    const data = {
      event: SocketEventEnum.Subscribe,
      channels: {
        "testSocket": SocketEnum.TestSocket,
      },
    }
    sendSocketMessage(data);
    return () => {
      data.event = SocketEventEnum.Unsubscribe
      sendSocketMessage(data);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    let newClientIds = clone(clientIds);
    let newMessage = clone(messages);

    if(socketMessages){
      console.log("socketMessages",socketMessages);

      if(SocketEnum.TestSocket in socketMessages){
        if(socketMessages[SocketEnum.TestSocket][SocketEnum.TestSocket].text){
          let data = socketMessages[SocketEnum.TestSocket][SocketEnum.TestSocket]
          newMessage.push(data);
        }
      }

      if(socketMessages['clientIds']){
        newClientIds = socketMessages['clientIds'];
      }
    }

    setClientIds(newClientIds);
    setMessages(newMessage)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[socketMessages,])

  return (
    <div className="test-socket">
      <h3>Test Socket Component</h3>

      <div>
        ClientId: {(socketClientId)?socketClientId:""}
      </div>
      <div className='chat-box'>
        <div className='messages'>
          <div className='label'>Messages</div>
          <ScrollToBottom className='messages-list'>
            {messages.map((v,i) => {
              let clientIdClass = v.clientId === socketClientId?"active-user":""
              return(
                <div className={"chat-bubble "+clientIdClass} key={i}>
                  <div className='text'>{v.text}</div>
                  <div className='client'>{v.clientId}</div>
                </div>
              )
            })}
          </ScrollToBottom>
          <div className='input-box'>
            <TextareaAutoresize value={value} onChange={(e) => {setValue(e.target.value)}}></TextareaAutoresize>
            <button type="button" className='button' onClick={() => {
              let data = {
                event: SocketEventEnum.Broadcast,
                channel: SocketEnum.TestSocket,
                room: SocketEnum.TestSocket,
                message: {
                  clientId: socketClientId,
                  text: value,
                }
              }
              console.log("data",data);
              sendSocketMessage(data);
              setValue("");
            }}>Send</button>
          </div>
        </div>
        {/* <div className='users'>
          <div className='label'>Local Clients ({clientIds.length})</div>
          <div className='users-list'>
            {clientIds.map((v,i) => {
              let activeClassname = (socketClientId && v === socketClientId)?"active":""
              return(<div className={activeClassname} key={i}>{v}</div>)
            })}
          </div>
        </div> */}
      </div>

    </div>
  );
};

export default TestSocket;