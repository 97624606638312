import React from 'react';
import './top-up-type.scss';

const TopUpType = ({topUpType,setTopUpType,topUpTypes}) => {
  return (
    <div className='top-up-type-comp hlo'>
      <label>Token Top Up Types</label>
      <div className='top-up-type-options'>
      {topUpTypes.map((v,i) => {
        let activeClass =  topUpType.id === v.id?"active":"";
        return(
          <div className={'top-up-type-option '+activeClass} key={i} onClick={() => {setTopUpType(v)}}>
            <div className='top-up-type-text'>
              {v.display} 
            </div>
          </div>
        )
      })}
      </div>
    </div>
  );

};

export default TopUpType;