
export const findObjectByValue = (object,value,label) => {
  label = label?label:"value";
  for (let index in object){
    let pos = object[index];
    if(pos[label] === value){
      return pos;
    }
  }
  return null;
}

// objects - [Object] : What you want to normalize
// value - String : What should be the value
// label - String : What the user should see
export const normalizeObjects = (objects,value,label) => {
  let newObjects = [];

  for (let index in objects){
    let object = objects[index];
    newObjects.push({
      "value": object[value],
      "label": object[label],
    })
  }

  return newObjects;
}
