import React,{useState,useEffect} from 'react';
import './article.scss';
import './article-item.scss';
import { request, unSubRequest } from '../../../utils/request';
import { toast } from 'react-toastify';
import Config from '../../../config/config';
import * as DateUtil from '../../../utils/date';

const Article = ({uid,path}) => {

  const [loading,setLoading] = useState(false);

  const [author,setAuthor] = useState("");
  const [publishedDate,setPublishedDate] = useState("");
  const [imageText,setImageText] = useState("");
  const [imageUrl,setImageUrl] = useState("");
  const [json,setJson] = useState("");
  const [title,setTitle] = useState("");
  const [metaDesc,setMetaDesc] = useState("");
  const [metaKeywords,setMetaKeywords] = useState("");

  useEffect(() =>{
    return () => {
      unSubRequest("fetch-article");
    }
  },[])

  useEffect(() => {
    fetchArticle(uid);
  },[uid]);

  const fetchArticle = (uid,) => {
    
    setLoading(true);
    request("fetch-article","/fetch-article/"+uid,"GET", {}, {
      then: function(res){
        console.log("res",res)

        let newAuthor = "";
        let newImageText = "";
        let newImageUrl = "";
        let newJson = "";
        let newMetaDesc = "";
        let newMetaKeywords = "";
        let newPubDate = "";
        let newTitle = "";

        if(res.data.res){
          let article = res.data.res;
          newAuthor = article.author ?? "";
          newImageText = article.imageText ?? "";
          newImageUrl = article.imageUrl ?? "";
          newJson = article.json ?? "";
          newMetaDesc = article.metaDesc ?? "";
          newMetaKeywords = article.metaKeywords ?? "";
          newPubDate = article.publishedDate ?? "";
          newTitle = article.title ?? "";
        }

        setAuthor(newAuthor);
        setPublishedDate(newPubDate);
        setImageText(newImageText);
        setImageUrl(newImageUrl);
        setJson(newJson);
        setTitle(newTitle);
        setMetaDesc(newMetaDesc);
        setMetaKeywords(newMetaKeywords);
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false);}
    });
  }


  const renderElementSections = (obj) => {
    const tagMap = {paragraph: 'p',header: 'h2',};
    if (Array.isArray(obj)) {
      return (
        <>{obj.map((item, index) => renderElementSections(item))}</>
      );
    } else if (obj !== null && typeof obj === 'object') {
      if (obj.hasOwnProperty('header') && obj.hasOwnProperty('content')) {
        return (
          <section key={obj.header}>
            <h2>{obj.header}</h2>
            {renderElementSections(obj.content)}
          </section>
        );
      } else if (obj.hasOwnProperty('type') && obj.hasOwnProperty('text')) {
        const Tag = tagMap[obj.type] || 'div'; 
        return <Tag key={obj.text}>{obj.text}</Tag>;
      }
    } else {
      return <div>1{obj}</div>;
    }
  };


  const renderArticle = ({title,desc,author,date,image,imageAlt,sections}) => {
    return(
      <div className='article-item'>
        <div className='article-top-div'>
          <div>Author: {author}</div>
          <div>Date: {DateUtil.dateTimeSince(date)}</div>
        </div>
        <div className='article-image-div'>
          <img src={image.replace("{{cdn}}",Config.Common.CdnUrl)} alt={imageAlt} />
        </div>
        <div className='article-title-div'>
          <h1>{title}</h1>
          <p>{desc}</p>
        </div>
        {renderElementSections(sections)}
      </div>
    )
  }  

  let parseJson = false;
  if(json !== ""){
    try{
      parseJson = JSON.parse(json);
    }catch(e){
      console.log("e",e);
    }
  }

  return (
    <div className="article-comp">
    {(parseJson)?
      <div>
        {renderArticle({
          title:title,
          desc:metaDesc,
          author:author,
          date:publishedDate,
          image:imageUrl,
          imageAlt:imageText,
          sections: parseJson.article.sections
        })}
      </div>:null}
    </div>
  );

};

export default Article;