

const worldOptions = {
  genre: [
    "Fantasy", "Sci-Fi", "Mystery", "Romance", "Adventure", "Horror", 
    "Historical Fiction", "Dystopian", "Thriller", "Comedy", "Urban Fantasy", 
    "Epic Fantasy", "Space Opera", "Cyberpunk", "Steampunk", "Paranormal"
  ],
  timePeriod: [
    "Ancient", "Medieval", "Renaissance", "Victorian", "Modern", "Future", 
    "1920s", "1950s", "1980s", "Post-Apocalyptic", "Timeless"
  ],
  worldLength: ["Short World", "Novella", "Full-Length Novel","Epic Series"],
  location: ["Earth", "Imaginary World", "Space Station", "Underwater City", "Alternate Universe"],
  environment: [
    "Urban Metropolis", "Rural Village", "Mystical Forest", "Desert Wasteland", 
    "Mountainous Terrain", "Secluded Islands", "Frozen Tundra"
  ],
  theme: [
    "Love", "Redemption", "Betrayal", "Survival", "Freedom", "Identity", 
    "Courage and Heroism", "Corruption", "Power and Ambition", "Fate vs. Free Will", 
    "Good vs. Evil", "Man vs. Nature", "Individual vs. Society"
  ],
  intendedAudience: ["Children", "Young Adult", "Adult", "All Ages"],
  toneAndMood: [
    "Light-hearted", "Dark", "Suspenseful", "Humorous", "Romantic", "Tragic", 
    "Inspirational", "Nostalgic", "Melancholic", "Eerie", "Whimsical"
  ],
};

const advancedWorldOptions = {
  plotStructure: [
    "Kishotenketsu",  // A traditional four-part East Asian narrative structure.
    "Freytag’s Pyramid",  // Classic five-part dramatic structure.
    "In Medias Res",  // Beginning the World in the middle of the action.
    "Circular Narrative",  // The World ends where it began, creating a circle.
    "Frame Narrative",  // A World within a World, within sometimes another World.
    "Three Act Structure",  // Dividing the World into three parts: Setup, Confrontation, and Resolution.
    "Hero’s Journey",  // A common template involving a hero who goes on an adventure, and in a decisive crisis wins a victory, and then comes home changed or transformed.
    "Nonlinear Narrative",  // The events are portrayed out of chronological order or in other ways where the narrative does not follow the direct causality pattern.
    "Episodic",  // Composed of a series of loosely connected parts or events.
    "Parallel Plots",  // Several equally important stories that run alongside each other.
    "Rashomon Effect",  // Providing different contradictory versions of the same event, where the truth is not clear.
    "Stream of Consciousness",  // Narrative mode depicting the continuous flow of thoughts and senses in the human mind.
    "Bildungsroman",  // A coming-of-age World focusing on the psychological and moral growth of the protagonist.
    "Picaresque",  // A World following a roguish but appealing hero, often in a satirical or humorous tone.
    "Reverse Chronology",  // The plot is revealed in reverse order, starting from the end.
    "Allegory",  // A symbolic narrative in which the surface details imply a secondary meaning.
    "Vignette",  // Short, impressionistic scenes that focus on one moment or give a particular insight into a character, idea, or setting.
    "Quest", 
    "Voyage and Return",
  ],
  conflictType: [
    "Person vs Person", "Person vs Self", "Person vs Society", "Person vs Nature", 
    "Person vs Technology", "Person vs Supernatural", "Person vs Fate/Destiny"
  ],
  narrativePov: [
    "First Person", "Third Person Omniscient", "Third Person Limited", "Second Person", 
    "Multiple POVs", "Unreliable Narrator", "Stream of Consciousness"
  ],
  mainCharacterArchetype: [
    "Hero", "Villain", "Mentor", "Sidekick", "Anti-hero", "Tragic Hero", "Fool"
  ],
  mainCharacterTrait: ["Brave", "Intelligent", "Mysterious", "Evil", "Compassionate", "Cunning", "Naive"],
  magicOrTechLevel: [
    "None", "Low", "Medium", "High", "Alchemy-Based", "Rune-Based", 
    "Cybernetic Enhancements", "Advanced AI Integration"
  ],
  culturalInfluence: [
      "Ancient Greek", "Medieval European", "Feudal Japanese", "Ancient Egyptian", 
      "Viking/Norse", "Imperial Chinese", "Indigenous American", "Mesoamerican", 
      "Renaissance Italian", "Victorian British", "Ancient Roman", "Ottoman Empire", 
      "Mongol Empire", "Sub-Saharan African", "Ancient Indian", "Medieval Arabic", 
      "Indigenous Australian", "Polynesian", "Southeast Asian", "Pre-Colonial African", 
      "Brazilian", "Caribbean", "Russian", "French Revolutionary", "Persian", "Celtic"
  ],
  specificElement: [
    "Dragons", "Spaceships", "Zombies", "Time Travel", "Vampires", "Aliens", 
    "Robots", "Magic Artifacts", "Ancient Prophecies", "Parallel Worlds", "Superpowers"
  ],
  languageStyle: [
    "Modern", "Period-Specific", "Formal", "Colloquial", "Old English", 
    "Shakespearean", "Futuristic Slang", "Poetic", "Minimalist", "Descriptive"
  ]
}


module.exports = {
  worldOptions,
  advancedWorldOptions,
};
