import React from 'react';
import './favicon.scss';

const Favicon = () => {

  return (
    <div className="favicon">
      <div className='row'>
        <div className="favicon-elm">
          <div>
            <span className="v">V</span>
            <span className="b">B</span>
          </div>
        </div>
        <div className="favicon-elm admin">
          <div>
            <span className="v">V</span>
            <span className="b">B</span>
            <span className="a">
              <span>A</span>
            </span>
          </div>
        </div>
        <div className="favicon-elm-mb">
          <div>
            <span className="m">M</span>
          </div>
          <div className='line one'></div>
          <div className='line two'></div>
          <div className='line three'></div>
        </div>
      </div>
      <div className='row'>
        <div className="favicon-elm-mb-block">
          <div className='inner'>
            <div className='leg left'></div>
            <div className='leg right'></div>
            <div className='arm left'></div>
            <div className='arm right'></div>
            <div className='line'></div>
          </div>
        </div>
        <div className="favicon-elm-mb-block white">
          <div className='inner'>
            <div className='leg left'></div>
            <div className='leg right'></div>
            <div className='arm left'></div>
            <div className='arm right'></div>
            <div className='line'></div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className="favicon-elm-mb-block-2">
          <div className='inner'>
            <div className='leg left'></div>
            <div className='leg right'></div>
            <div className='arm left'></div>
            <div className='arm right'></div>
            <div className='line'></div>
          </div>
        </div>
        <div className="favicon-elm-mb-block-2 white">
          <div className='inner'>
            <div className='leg left'></div>
            <div className='leg right'></div>
            <div className='arm left'></div>
            <div className='arm right'></div>
            <div className='line'></div>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className="favicon-elm-mb-block-3">
          <div className='inner'>
            <div className='leg left'></div>
            <div className='leg right'></div>
            <div className='arm left'></div>
            <div className='arm right'></div>
            <div className='line'></div>
          </div>
        </div>
        <div className="favicon-elm-mb-block-3 white">
          <div className='inner'>
            <div className='leg left'></div>
            <div className='leg right'></div>
            <div className='arm left'></div>
            <div className='arm right'></div>
            <div className='line'></div>
          </div>
        </div>
        <div className="favicon-elm-mb-block-3 white">
          <div className='inner'>
            <div className='leg left'></div>
            <div className='leg right'></div>
            <div className='arm left'></div>
            <div className='arm right'></div>
            <div className='line'></div>
          </div>
          <div className="a">
            <div>A</div>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className="favicon-elm-mb-block-4">
          <div className='circle'></div>
          <div className='inner'>
            <div className='leg left'></div>
            <div className='leg right'></div>
            <div className='arm left'></div>
            <div className='arm right'></div>
            <div className='line'></div>
          </div>
        </div>
        <div className="favicon-elm-mb-block-4 white">
          <div className='inner'>
            <div className='leg left'></div>
            <div className='leg right'></div>
            <div className='arm left'></div>
            <div className='arm right'></div>
            <div className='line'></div>
          </div>
        </div>
        <div className="favicon-elm-mb-block-4 white">
          <div className='inner'>
            <div className='leg left'></div>
            <div className='leg right'></div>
            <div className='arm left'></div>
            <div className='arm right'></div>
            <div className='line'></div>
          </div>
          <div className="a">
            <div>A</div>
          </div>
        </div>
      </div>
    </div>
  );

};

export default Favicon;