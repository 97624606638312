import React, { useState,useEffect } from 'react';
import './feedback.scss';
import {Link} from 'react-router-dom';
import {request,unSubRequest} from '../../../utils/request';
import {toast} from 'react-toastify';
import useUserState from '../../../store/user';
import Button from '../../elements/button/button';
import { Helmet } from "react-helmet";
import Seo from '../../../data/seo';

const Feedback = ({type}) => {

  const {user} = useUserState();
  const [loading,setLoading] = useState(false);
  const [text,setText] = useState("");

  useEffect(() => {
    return () => {
      unSubRequest("feedback");
    }
  },[]);

  const handleSubmit = (e) => {
    e.preventDefault();
    send();
  } 

  const send = () => {
    let data = {
      text,
      type:"feedback",
    }
    setLoading(true);
    request("feedback","/feedback","POST", data, {
      then: function(res){ 
        toast.success("Feedback Received! Thank you.")
        setText("");
      },
      catch: function(err){ toast.error(err.message);},
      finally: function(){ setLoading(false);}
    });
  }

  if(!user){
    return(
      <div className="feedback-comp">

        <Helmet>
          <title>{Seo.Feedback.Title}</title>
          <meta name="description" content={Seo.Feedback.Desc} />
          <meta name="keywords" content={Seo.Feedback.Keywords} />
        </Helmet>

        <p>Please <Link to="/login">Login</Link> before leaving feedback. </p>
        <p>If you're having trouble logging in you can <Link to="/contact">Contact Us</Link></p>
      </div>
    )
  } 

  return (
    <div className="feedback-comp">

      <Helmet>
        <title>{Seo.Feedback.Title}</title>
        <meta name="description" content={Seo.Feedback.Desc} />
        <meta name="keywords" content={Seo.Feedback.Keywords} />
      </Helmet>

      <h1>Feedback</h1>
      <p>Have an idea or suggestion?</p>
      <p>We'd love to hear about it.</p>
      <div className="feedback-content">
        <form onSubmit={handleSubmit}>
          <div>
            <label>Feedback</label>
            <textarea 
              disabled={loading}
              onChange={(e) => {
              setText(e.target.value)
            }} value={text}></textarea>
          </div>
          <Button type="submit" status={(loading)?"loading":""} value={"Send"}/>
        </form>
      </div>
    </div>
  );

};

export default Feedback;