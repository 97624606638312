import React, { useEffect,useState } from 'react';
import './how-to-use.scss';
import { Helmet } from "react-helmet";
import Seo from '../../../data/seo';
import Config from '../../../config/config';
import { Link } from 'react-router-dom';

let links = [
  {title:"What is MythBlender",id:"what-is-mythblender"},
  {title:"Creating New Worlds",id:"creating-new-worlds"},
  {title:"Creating New Items",id:"create-new-items"},
  {title:"Fill Characters",id:"fill-character"},
  {title:"Creating Images",id:"create-images"},
]

const HowToUse = () => {

  const [hash,setHash] = useState("");

  useEffect(() => {
    let newHash = "";
    if (window.location.hash) {
      newHash = window.location.hash
      newHash = newHash.replace('#', '');
    }
    setHash(newHash);
  }, []);

  useEffect(() => {
    if (hash !== "") {
      try{
        const element = document.getElementById(hash);
        if (element) {
          const offsetTop = element.getBoundingClientRect().top + window.pageYOffset - 60; // 60px padding
          window.scrollTo({ top: offsetTop, behavior: 'smooth' });
          // element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }catch(e){}
    }
  }, [hash]);

  return (
    <div className="how-to-use-comp">

      <Helmet>
        <title>{Seo.HowToUse.Title}</title>
        <meta name="description" content={Seo.HowToUse.Desc} />
        <meta name="keywords" content={Seo.HowToUse.Keywords} />
      </Helmet>

      <div className='header-div'>
        <h1>How to use {Config.Common.AppName}</h1>
        <p>Firstly, we've tried to make {Config.Common.AppName} as easy as possible to use. Iff you have any suggestions, please let us know via our <Link to="/feedback">Feedback</Link> page.</p>
        <p>If you get lost or need help, we're available via our <Link to="/help">Help</Link> page.</p>
      </div>
      <div className='content-div'>
        <div className='guide'>
          {links.map((v,i) => {
            let activeClass = v.id === hash?"active":"";
            return (
              <Link className={activeClass} key={i} to={"/how-to-use#"+v.id} onClick={() => {setHash(v.id)}}>{v.title}</Link>
            )
          })}
        </div>
        <div className='blocks'>
          <div className='block' id="what-is-mythblender">
            <h2>What is MythBlender</h2>
            <p>MythBlender combines text & image AI to allow you to weave together any story you want.</p>
            <p>Users may generate entirely random fictional worlds, or prompt AI to create a world that they specifically want.</p>
            <p>Users may even create their own fanfiction and build off of preexisting worlds.</p>
          </div>
          <div className='block' id="creating-new-worlds">
            <h2>Creating New Worlds</h2>
            <p>There's 4 different ways to Create a new world</p>
            <ol>
              <li>Templates: Chose a world that a another user has made, copy it and use it as your own. </li>
              <li>Generate: Set a few settings and generate an entirely new world from them.</li>
              <li>Prompt: Tell AI exactly what you want and it'll generate that world for you.</li>
              <li>Blank: Creates an empty world.</li>
            </ol>
          </div>
          <div className='block' id="create-new-items">
            <h2>Creating New Items</h2>
            <p>An item could be any of the following: Character, Location, Culture, Species, Conflict, Religion, Religion Diety, Faction</p>
            <p>There's 3 different ways to Create a new item</p>
            <ol>
              <li>List: Creates a list of items based on your prompt </li>
              <li>Detailed: Creates fully filled out items. Prompt is optional </li>
              <li>Blank: Creates an empty item</li>
            </ol>
          </div>
          <div className='block' id="fill-character">
            <h2>Fill</h2>
            <p>The Fill button means that it'll use AI to fill in the textboxes</p>
            <p>There's also an optional "Use Prompt" button. Here you can guide AI on what you want it to do. i.e. "Add more detail to appearance" or "base this character on X character from Y book"</p>
          </div>
          <div className='block' id="create-images">
            <h2>Creating Images</h2>
            <p>There's 3 different ways to Create a new item</p>
            <ol>
              <li>Details: Uses details from the item to generate an image. You may also use the "Image Scene" parameter to set the scene of the image.</li>
              <li>Prompt:  Create an image based on a prompt. There's 2 textboxes here. The top one will enhance simple text into a better prompt. The bottom textbox is the prompt you want to use to generate the image. </li>
              <li>Direct Link: Paste in a link of a url to an image you have.</li>
            </ol>
          </div>
          
        </div>
      </div>
    </div>
  );

};

export default HowToUse;